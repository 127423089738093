import React, { useEffect } from "react";
import { mixpanelToken } from "../settings";

// DEPENDENCIES
import mixpanel from "mixpanel-browser";

// COMPONENTS
import Syllabus from "../Syllabus/Syllabus";
import Navigator from "../Navigation/Navigator";
import imgIcon from "../Static/img/logo-icon.png";

// STYLES
import styled from "styled-components";
import { fontFamily, fontWeight } from "@bit/medzcool.cytoplasm.typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

// Apollo, GraphQL
import { gql, useQuery, useMutation } from "@apollo/client";

mixpanel.init(mixpanelToken);

const GET_LOCATION = gql`
  query getLocation($userId: Int!, $course: String!) {
    getLocation(userId: $userId, course: $course) {
      id
      user {
        id
      }
      data
      course
      module
      section
      chapter
    }
  }
`;

export default function Hompage(props) {
  const { history, user, newUserContentMap, setNewUserContentMap } = props;

  useEffect(() => {
    mixpanel.identify(user.id);
  }, []);

  const { data: locationData, loading: locationLoading } = useQuery(
    GET_LOCATION,
    {
      variables: {
        userId: user.id,
        course: "kardiograph",
      },
    }
  );

  if (locationLoading) return null;

  return (
    <HomepageContainer>
      <Navigator />

      {locationData.getLocation.data ? (
        <div>
          <ResumeCard
            onClick={() => {
              mixpanel.track("Navigation", {
                Type: "Resume Card",
                Action: "Click",
                Location: "Homepage",
                App: "Kardiograph",
              });

              history.push(locationData.getLocation.data);
            }}>
            <div>
              <img src={imgIcon} alt="continue" />
            </div>
            <div className="resume-text-container">
              <div className="module-chapter-container">
                <div
                  className="progress-module-title"
                  style={{ marginBottom: 0, color: "#999999" }}>
                  {locationData.getLocation.module}
                </div>

                <h1 style={{ marginTop: 0 }}>
                  {locationData.getLocation.chapter}
                </h1>
              </div>

              <div className="resume-label">CONTINUE READING</div>
            </div>

            <div className="resume-icon">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </ResumeCard>
          <StartHeader isResume>
            <div>
              <h4 style={{ marginBottom: 0, color: "#999999" }}>
                {locationData.getLocation.module}
              </h4>
              {/* <div className="section">{locationData.getLocation.section}</div> */}
              <h1 style={{ marginTop: 0, marginBottom: 25 }}>
                {locationData.getLocation.chapter}
              </h1>

              <button
                className="button"
                onClick={() => {
                  mixpanel.track("Navigation", {
                    Type: "Resume Header",
                    Location: "Homepage",
                    Action: "Click",
                    App: "Kardiograph",
                  });
                  history.push(locationData.getLocation.data);
                }}>
                Resume
              </button>
            </div>
          </StartHeader>
        </div>
      ) : (
        <StartHeader>
          <div>
            <h1>Welcome to Kardiograph</h1>
            <p>Learn Electrocardiograms</p>

            <button
              className="button"
              onClick={() => {
                mixpanel.track("Start Kardiograph", {
                  Action: "Click",
                  App: "Kardiograph",
                });
                history.push(
                  "/module/physiology/electrophysiology/electrocardiogram"
                );
              }}>
              Start
            </button>
          </div>
        </StartHeader>
      )}

      <div className="home-body">
        <Syllabus
          {...props}
          history={history}
          user={user}
          locationData={locationData}
          locationLoading={locationLoading}
          newUserContentMap={newUserContentMap}
          setNewUserContentMap={setNewUserContentMap}
        />
      </div>
    </HomepageContainer>
  );
}
const HomepageContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  font-size: 16px;
  button {
    font-family: ${fontFamily.sansSerif};
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f0445d;
    padding: 15px 45px;
    border-radius: 5px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    &:hover {
      background-color: #d23d56;
    }
  }
  .home-body {
    padding: 8px;
  }
  h1 {
    font-size: 2em;
    margin-bottom: 0;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.2em;
  }

  p {
    font-size: 1.3em;
    margin-top: 0;
  }
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;
const StartHeader = styled.div`
  min-height: 15rem;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  padding: 50px 15px;
  padding-top: 75px;
  @media (max-width: 450px) {
    margin: 10px;
    margin-top: 10px;
    padding: 15px;
    margin-top: 50px;
    /* border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border: 1px solid lightgray; */
    text-align: left;
    background-color: white;
    button {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 5px;
    }
    display: ${(props) => props.isResume && "none"};
  }
`;
const ResumeCard = styled.div`
  display: none;
  cursor: pointer;
  margin: 10px;
  margin-top: 10px;
  margin-top: 10px;
  padding: 0 15px 0 0;
  margin-top: 65px;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border: 1px solid lightgray;
  font-size: 8px;
  position: relative;
  background: #f2f2f2;
  img {
    width: 100px;
    display: block;
    border-radius: 10px 0 0 10px;
  }
  .progress-module-title {
    font-size: 1.5em;
    font-weight: ${fontWeight.heavy};
  }
  .resume-text-container {
    .module-chapter-container {
      margin-top: 8px;
    }
    h1 {
      line-height: 110%;
    }
    .resume-label {
      color: #f0445d;
      font-weight: ${fontWeight.heavy};
      font-size: 1.4em;
      position: absolute;
      bottom: 8px;
    }
  }

  .resume-icon {
    font-size: 2em;
    color: #f0445d;
    align-self: center;
  }
  @media (max-width: 450px) {
    display: grid;
    grid-template-columns: min-content auto min-content;
    grid-gap: 10px;
  }
`;
