import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function JunctionalEscapeBeat(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Junctional-Escape-beat_LXwNkoN.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A transient Sinus Block can prevent the SA node from discharging one
        pacemaking stimulus, producing a pause in cardiac electrical activity.
        If an atrial automaticity focus fails to emit an electrical stimulus,
        then the next automaticity focus will also escape overdrive suppression.
        In this case, the next automaticity focus after the atrial focus is a
        Junctional automaticity focus, resulting in a junctional escape beat.
      </p>
      <p>
        Depolarization emitted by the Junctional focus passes down the
        ventricular conduction system to depolarize the ventricles in a normal
        fashion, so you will see a normal QRS complex on an EKG, but you won't
        see P-waves.
      </p>
      <p>
        When the SA Node quickly resumes pacing, the junctional focus is
        overdrive-suppressed again, and normal sinus rhythm continues.
      </p>
    </ContentTemplate>
  );
}
