import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function PrematureAtrialBeats(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-atrial-beat-0.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-atrial-beat-aberrant-ventricular-conduction.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-atrial-beat-without-ventricular-conduction.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/atrial-bigeminy.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/atrial-trigeminy.png",
    },
    {
      id: 5,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-atrial-beat-1.png",
    },
    {
      id: 6,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-atrial-beat-2.png",
    },
    {
      id: 7,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-atrial-beat-3.png",
    },
    {
      id: 8,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-atrial-beat-4.png",
    },
    {
      id: 9,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-atrial-beat-5.png",
    },
    {
      id: 10,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-atrial-beat-6.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Premature Atrial Beat
        </TextMark>{" "}
        originates in an irritable atrial automaticity focus that spontaneously
        fires a depolarization stimulus earlier than the normal P wave on EKG.
        This results in a{" "}
        <TextMark
          activeMark={activeMark == 5 && true}
          onClick={() => clickTextMark(5)}>
          P' wave
        </TextMark>{" "}
        on the EKG, which can conduct to the AV node and ventricles resulting in
        the P' wave being followed by a normal-appearing QRS complex and
        subsequent T-wave. If this irritable focus produces only a single
        stimulus, the SA node will resume normal sinus rhythm after this
        premature beat.
      </p>
      <p>
        Because of this interruption in normal sinus rhythm, there is this
        temporary{" "}
        <TextMark
          activeMark={activeMark == 6 && true}
          onClick={() => clickTextMark(6)}>
          irregularity
        </TextMark>{" "}
        in the cardiac cycle as the SA node attempts to reset its pacemaking
        rhythm. This resetting occurs when the SA node is depolarized by a
        premature beat, resulting in this brief pause, during which the SA node
        repolarizes. As a result, the SA node's pacemaking activity{" "}
        <TextMark
          activeMark={activeMark == 7 && true}
          onClick={() => clickTextMark(7)}>
          resets
        </TextMark>{" "}
        in step with the premature stimulus so that the next pacing stimulus
        that it generates is one cycle length from the premature beat.
      </p>
      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        Premature Atrial Beat - with{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          aberrant ventricular conduction
        </TextMark>
      </strong>
      <p>
        The ventricular conduction system is usually receptive to being
        depolarized by a{" "}
        <TextMark
          activeMark={activeMark == 8 && true}
          onClick={() => clickTextMark(8)}>
          Premature Atrial Beat.
        </TextMark>{" "}
        So when a Premature Atrial Beat is conducted to the ventricles, the
        ventricles are depolarized earlier than usual. However, one Bundle
        Branch may still be in a refractory period and may not have completely
        repolarized when the other branch is receptive. This difference in the
        timing of electrical conduction leads to depolarization of one ventricle
        occurring immediately, while the other ventricle is slightly delayed.
        This is called aberrant ventricular conduction.
      </p>
      <p>
        The non-simultaneous depolarization of the ventricles records as a
        slightly{" "}
        <TextMark
          activeMark={activeMark == 9 && true}
          onClick={() => clickTextMark(9)}>
          widened QRS
        </TextMark>{" "}
        complex after the P' on the EKG. Normal ventricular conduction resumes
        with normal cycles.
      </p>
      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        Premature Atrial Beat - without ventricular conduction
      </strong>
      <p>
        aka{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          non-conducted premature atrial beat
        </TextMark>
      </p>
      <p>
        Sometimes, the AV Node is unreceptive to a premature atrial beat because
        it reaches the AV Node prematurely, while the AV Node is still in the
        refractory period of its repolarization. This results in a
        "non-conducted" Premature Atrial Beat. This records as a P' wave that
        has no ventricular (QRS-T) response on an EKG. In this case, even though
        the "non-conducted" premature atrial beat does not depolarize the
        ventricles, it does depolarize the SA Node, which{" "}
        <TextMark
          activeMark={activeMark == 10 && true}
          onClick={() => clickTextMark(10)}>
          resets
        </TextMark>{" "}
        its pacemaking activity one cycle length after the premature stimulus.
      </p>
      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        Bigeminy and Trigeminy
      </strong>
      <p>
        Occasionally, an irritable automaticity focus fires a Premature Atrial
        Beat that couples to the end of a normal cycle and repeats this process
        by coupling a PAB to the end of each successive normal cycle. This is{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          Atrial Bigeminy.
        </TextMark>
      </p>
      <p>
        The cycle containing the premature beat together with the cycle or
        cycles to which it couples is called a "couplet."
      </p>
      <p>
        Sometimes, an irritable atrial focus may prematurely fire after two
        normal cycles. When this couplet repeats continuously, it is a run of{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          Atrial Trigeminy.
        </TextMark>
      </p>
      <p>
        With both Atrial Bigeminy and Atrial Trigeminy, each premature stimulus
        (from the irritable atrial focus) depolarizes the SA Node and resets it,
        producing a span of clear baseline between the couplets.
      </p>
    </ContentTemplate>
  );
}
