import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function LeftRightAxis(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Determining-Axis-right-left-intro.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Determining-Axis-right-left-sphere_ZdUCcfM.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Determining-Axis-right-left-positive.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/Determining-Axis-right-left-negative.png",
    },
    {
      id: 4,
      img:
        "https://medzcool-images.s3.amazonaws.com/Determining-Axis-right-left-summary.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        The normal Mean QRS Vector should point downward and to the patient's
        left in the 0° to +90° range.
      </p>
      <p>
        To determine the direction of the heart's Mean Vector,{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          visualize a sphere
        </TextMark>{" "}
        surrounding the heart, with the AV Node at the center of the sphere.
      </p>
      <p>
        Using our limb leads, consider Lead I to orient yourself. In the case of
        our sphere, Lead I passes through the center of our sphere (and the AV
        node).
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          Lead I
        </TextMark>{" "}
        uses the right and left arms for recording, where the left arm is
        positive, and the right arm is negative. Similarly, we can translate
        this onto our sphere, making the left half of the sphere positive and
        the right half of the sphere being negative.
      </p>
      <p>
        Now we can use lead I on an EKG to determine which direction, left or
        right, the Mean QRS Vector is pointing.
      </p>
      <p>
        If the QRS in Lead I is mainly{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          upright,
        </TextMark>{" "}
        this suggests a positive wave of depolarization towards Lead I. This
        also indicates that the Mean QRS Vector points positively or towards the
        left of our sphere.
      </p>
      <p>
        If the QRS complex is mainly{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          downward
        </TextMark>{" "}
        in Lead I, it suggests that the depolarization wave moves away from Lead
        I and results in a negative (downward) deflection. This means that the
        Mean QRS Vector also points negatively in our sphere or towards the
        right. In this case, the mean QRS vector, or overall direction of
        depolarization, is towards the person's right side. This is what we call
        Right Axis Deviation.
      </p>
      <p>
        Therefore{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          using Lead I,
        </TextMark>{" "}
        you can determine whether the mean QRS Vector points to a person's left
        or right side.
      </p>
    </ContentTemplate>
  );
}
