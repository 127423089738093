import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function ParoxysmalVentricularTachycardia(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Paroxysmal-ventricular-Tachycardia-heart.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Paroxysmal-ventricular-Tachycardia-strip.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Paroxysmal-ventricular-Tachycardia-strip-atrial-depol.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/Ventricular-Tachycardia-fusion.png",
    },
    {
      id: 4,
      img:
        "https://medzcool-images.s3.amazonaws.com/Ventricular-Tachycardia-capture.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Paroxysmal Ventricular Tachycardia, or just Ventricular Tachycardia
        (VT), originates suddenly in a very irritable{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          ventricular automaticity focus,
        </TextMark>{" "}
        producing a ventricular rate of 150 - 250.
      </p>
      <p>
        On an EKG, sudden runs of{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          Ventricular Tachycardia
        </TextMark>{" "}
        resemble a rapid series of Premature Ventricular Contractions.
      </p>
      <p>
        During Ventricular Tachycardia, the SA Node still paces the atria, but
        the large ventricular complexes hide the individual{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          P waves
        </TextMark>{" "}
        that can occasionally be seen. So, there is independent pacing of the
        atria and the ventricles, a type of AV dissociation. Sometimes, one
        regular (Sinus-paced) atrial depolarization finds the AV node receptive
        to depolarization. That stimulus passes through the AV Node to
        depolarize the ventricles via the ventricular conduction system
        resulting in a normal-appearing QRS complex, aka a{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          capture beat.
        </TextMark>
      </p>
      <p>
        Alternatively, if an atrial depolarization finds a receptive AV Node and
        passes through to the ventricles, it may be met by another ventricular
        stimulus from a ventricular focus. The atrial and ectopic ventricular
        stimuli collide in this case, producing a{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          fusion beat.
        </TextMark>{" "}
        This fusion beat is a hybrid complex created when a supraventricular and
        a ventricular impulse coincide.
      </p>
      <p>
        A rapid (Junctional or atrial) Supraventricular Tachycardia with
        aberrant conduction can produce a tachycardia with widened QRS's that
        mimics VT. Also, pre-existing Bundle Branch Block with SVT will widen
        the QRS complexes to give the same impression. The QRS in SVT, even if
        widened by aberrant ventricular conduction, is usually 0.14 seconds or
        less in duration. However, the ventricular complexes in VT are very
        wide, 0.14 seconds or greater.
      </p>
      {/* <p>
        Signs of AV dissociation (e.g., presence of fusions or captures, or
        Extreme R.A.D. (-90° to -180°) are characteristic of VT.
      </p> */}
    </ContentTemplate>
  );
}
