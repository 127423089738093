import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function NormalSinusRhythm(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/SA-Node_OdJkK0m.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Normal-Sinus-Rhythm-without.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Normal-Sinus-Rhythmp-p.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/Normal-Sinus-Rhythm-r-r.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The Sino-Atrial Node (SA node aka sinus node) generates a regular Sinus
        Rhythm that paces the heart. Each pacemaker impulse from the SA Node
        spreads through both atria as an advancing wave of depolarization. It is
        the automaticity of the Sinus Node (SA Node) that generates the regular
        cadence of depolarization stimuli for pacemaking activity.
      </p>
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          SA Node
        </TextMark>{" "}
        generates pacing impulses at a constant and uniform rate (60 to 100 per
        minute), producing cycles of equal duration. Because the sequence of
        depolarization is the same in each repeating cycle, there is a
        predictable regularity of all similar waves. This is what we call{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          Normal Sinus Rhythm
        </TextMark>{" "}
        and is representative of the normal electrical conduction of the heart.
        For example, the distance from each{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          p-p wave
        </TextMark>{" "}
        will always be the same, just like the distance between each{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          QRS complex
        </TextMark>{" "}
        will be regular and the same. Therefore, any deviations from this
        regular cardiac cycle will be very obvious to spot and is one way you
        can start to identify cardiac arrhythmias.
      </p>
      <p>
        You can visually scan an EKG and appreciate the repetitive continuity of
        a regular rhythm. But breaks in that continuity, such as a pause, the
        presence of too-early (premature) beats, or sudden, dramatic rate
        change, immediately catch our attention, warning us of a rhythm
        disturbance.
      </p>
      <p>
        Keep in mind that there are minimal variations in the SA Node's pacing
        rate associated with the phases of respiration. Sinus variations are
        expected, resulting in minimal increases in heart rate during
        inspiration and an extremely minimal decrease in heart rate during
        expiration. The slight increase in the heart rate is due to
        inspiration-activated sympathetic stimulation of the SA Node. The slight
        decrease in pacing rate is due to expiration-activated parasympathetic
        inhibition of the SA Node.
      </p>
    </ContentTemplate>
  );
}
