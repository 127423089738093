// Dependencies
import { Route, Switch } from "react-router-dom";

// Components
import VectorDepolarization from "./Chapters/1-VectorDepolarization";
import VectorDisplacement from "./Chapters/2-VectorDisplacement";
import LeftRightAxis from "./Chapters/3-LeftRightAxis";
import UpDown from "./Chapters/4-UpDown";
import NormalAxis from "./Chapters/5-NormalAxis";
import DeviationsFromNormal from "./Chapters/6-DeviationsFromNormal";
import LeftAxisDeviation from "./Chapters/7-LeftAxisDeviation";
import RightAxisDeviation from "./Chapters/8-RightAxisDeviation";
import DegreeAxisDeviation from "./Chapters/9-DegreeAxisDeviation";
import Practice from "./Chapters/10-Practice";
import AtrialAxis from "./Chapters/11-AtrialAxis";
import HorizontalPlane from "./Chapters/12-HorizontalPlane";
import AxisRotation from "./Chapters/13-AxisRotation";

// Styles
export default function AxisModule(props) {
  return (
    <Switch>
      <Route
        path="/module/axis/mean-qrs-vector/vector-of-depolarization"
        render={(props) => <VectorDepolarization {...props} />}
      />
      <Route
        path="/module/axis/mean-qrs-vector/vector-displacement"
        render={(props) => <VectorDisplacement {...props} />}
      />
      <Route
        path="/module/axis/determining-axis/left-right-axis"
        render={(props) => <LeftRightAxis {...props} />}
      />
      <Route
        path="/module/axis/determining-axis/up-down"
        render={(props) => <UpDown {...props} />}
      />
      <Route
        path="/module/axis/determining-axis/normal-axis"
        render={(props) => <NormalAxis {...props} />}
      />
      <Route
        path="/module/axis/determining-axis/deviations-from-normal"
        render={(props) => <DeviationsFromNormal {...props} />}
      />
      <Route
        path="/module/axis/determining-axis/left-axis-deviation"
        render={(props) => <LeftAxisDeviation {...props} />}
      />
      <Route
        path="/module/axis/determining-axis/right-axis-deviation"
        render={(props) => <RightAxisDeviation {...props} />}
      />
      <Route
        path="/module/axis/determining-axis/degree-of-axis-deviation"
        render={(props) => <DegreeAxisDeviation {...props} />}
      />
      <Route
        path="/module/axis/determining-axis/practice"
        render={(props) => <Practice {...props} />}
      />
      <Route
        path="/module/axis/determining-axis/atrial-axis"
        render={(props) => <AtrialAxis {...props} />}
      />
      <Route
        path="/module/axis/determining-axis/horizontal-plane"
        render={(props) => <HorizontalPlane {...props} />}
      />
      <Route
        path="/module/axis/determining-axis/axis-rotation"
        render={(props) => <AxisRotation {...props} />}
      />
    </Switch>
  );
}
