import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function MultifocalAtrialTachycardia(props) {
  const contextValue = useContext(StageContext);
  const { setActiveMark, setActiveMarkData, activeMark, clickTextMark } =
    contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/MAT-rhythm-strip.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/MAT-rhythm-strip-P-prime.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/MAT-irregular.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/Multifocal-Atrial-Tachycardia.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Like in cases of wandering atrial pacemakers, in multifocal atrial
        tachycardia (MAT), there are{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          P' waves
        </TextMark>{" "}
        with varying morphologies suggesting ectopic atrial foci resulting in
        this arrhythmia. This occurs because these ectopic atrial foci have
        developed entrance blocks, developing resistance to overdrive
        suppression from the SA node. As a result, they depolarize independently
        from another. This is why no single focus achieves pacemaking dominance,
        so these ectopic atrial foci all pace together.
      </p>
      <p>
        Because of the multifocal origin of MAT, each individual atrial focus
        paces at its own inherent rate, but the total, combined pacing of
        multiple unsuppressed foci produces an{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          irregular rhythm,
        </TextMark>{" "}
        which is fast (&gt;100 bpm).
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          MAT
        </TextMark>{" "}
        is an arrhythmia that is often associated with patient's who have a
        medical history of Chronic Obstructive Pulmonary Disease (COPD).
      </p>
      {/* <p>
        In these cases, atrial automaticity foci also become ill and pathologic
        developing entrance blocks.
      </p> */}
    </ContentTemplate>
  );
}
