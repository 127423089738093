import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function DegreeAxisDeviation(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Degree-of-Axis.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Degree-of-Axis-isoelectric.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Degree-of-Axis-left-normal.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/Degree-of-Axis-right.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        It's possible to more precisely locate the position of the Vector (axis)
        in the frontal plane. This precision is measured in degrees.
      </p>
      <p>
        Remember that the{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          normal axis
        </TextMark>{" "}
        of the heart points downward and to the patient's left. This range is
        between 0 and +90 degrees.
      </p>
      <p>
        The remainder of the frontal plane can be divided into degrees seen
        here:
      </p>
      <ul>
        <li>
          <TextMark
            activeMark={activeMark == 2 && true}
            onClick={() => clickTextMark(2)}>
            Left Axis Deviation
          </TextMark>
        </li>
        <li>
          <TextMark
            activeMark={activeMark == 3 && true}
            onClick={() => clickTextMark(3)}>
            Right Axis Deviation
          </TextMark>
        </li>
      </ul>
      <p>To determine the axis with more precision:</p>
      <ol>
        <li>Locate the axis quadrant, then</li>
        <li>
          Note the limb lead in which the QRS is the most{" "}
          <TextMark
            activeMark={activeMark == 1 && true}
            onClick={() => clickTextMark(1)}>
            isoelectric.
          </TextMark>
        </li>
      </ol>
      <p>
        The word "isoelectric" literally means "same voltage," so it is used
        when the positive and the negative portions of the QRS complex are about
        equal. In other words, depolarization that moves perpendicular to the
        orientation of a lead is recorded as a deflection that is as much
        negative as it is positive and is called isoelectric.
      </p>
    </ContentTemplate>
  );
}
