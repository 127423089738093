import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function AtrialConduction(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/atrial-conduction-internodal_Geelp2s.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/atrial-conduction-bachmann.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/p-wave.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        In its simplest form, the atrial conduction system consists of three{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          internodal tracts
        </TextMark>{" "}
        that course from the SA node to the AV node and a bundle of nerves that
        run from the SA node to the left atrium{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          (Bachmann's Bundle).
        </TextMark>
      </p>
      <p>
        Depolarization passing rapidly through this atrial conduction system
        does not record on the EKG. However, depolarization of the atrial
        myocardium, which is innervated by this conduction system, results in a{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          P wave
        </TextMark>{" "}
        on the EKG.
      </p>
      <p>
        This P wave is the first wave you see on a rhythm strip, representing
        atrial depolarization.
      </p>
    </ContentTemplate>
  );
}
