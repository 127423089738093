import React, { useEffect, useState } from "react";
import { mixpanelToken } from "../../settings";

// DEPENDENCIES
import mixpanel from "mixpanel-browser";
import { Route, Switch } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import lowResImg from "../../Static/img/white-low-res.png";

// COMPONENTS
import LeftStage from "./LeftStage";
import PhysiologyModule from "../../Content/Physiology/PhysiologyModule";
import RecordingModule from "../../Content/Recording/RecordingModule";
import RateModule from "../../Content/Rate/RateModule";
import RhythmModule from "../../Content/Rhythm/RhythmModule";
import AxisModule from "../../Content/Axis/AxisModule";
import HypertrophyModule from "../../Content/Hypertrophy/HypertrophyModule";
import InfarctionModule from "../../Content/Infarction/InfarctionModule";

// STYLES
import styled from "styled-components";
import { grayscale, green, red } from "@bit/medzcool.cytoplasm.colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEye,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { fontFamily, fontWeight } from "@bit/medzcool.cytoplasm.typography";

// CONTEXT
export const StageContext = React.createContext();

mixpanel.init(mixpanelToken);

export default function ModuleStage(props) {
  const {
    history,
    moduleData,
    contentMap,
    setActiveStageMenu,
    activeStageMenu,
  } = props;

  const [stageImg, setStageImg] = useState();
  const [activeMarkData, setActiveMarkData] = useState();
  const [activeMark, setActiveMark] = useState();

  function clickTextMark(num) {
    const loadMark = activeMarkData.find((mark) => mark.id == num);
    setStageImg(loadMark.img);
    setActiveMark(num);
    mixpanel.track("TextMark", {
      Action: "Click",
      App: "Kardiograph",
    });
  }

  // const [activeStageMenu, setActiveStageMenu] = useState(false);
  // useEffect(() => {
  //   setActiveStageMenu(false);
  // }, [props.location.pathname]);

  const data = {
    setStageImg: setStageImg,
    activeMarkData: activeMarkData,
    setActiveMarkData: setActiveMarkData,
    setActiveMark: setActiveMark,
    activeMark: activeMark,
    clickTextMark: clickTextMark,
  };

  const [moduleProgress, setModuleProgress] = useState();
  useEffect(() => {
    let flatChapters = [];
    const moduleLength = moduleData.moduleTitle.sections.map(
      (section, index) => {
        section.chapters.map((chapter) => {
          flatChapters.push(chapter.chapterParam);
          return;
        });
        return;
      }
    );

    const currentPosition = flatChapters.indexOf(
      moduleData.chapterTitle.chapterParam
    );
    setModuleProgress((currentPosition + 1) / flatChapters.length);
  }, [props.location.pathname]);

  if (!contentMap) return null;

  return (
    <StageContext.Provider value={data}>
      <StageContainer>
        <StageGrid className="stage-grid">
          <div className="mobile-image-stage">
            <div className="dummy-img">
              <img src={lowResImg} alt="placeholder" />
            </div>
            <div className="real-img">
              <ProgressBar percent={moduleProgress * 100} />
              {stageImg && (
                <LazyLoadImage alt="kardiograph" effect="blur" src={stageImg} />
              )}
            </div>
          </div>
          <div className="left-stage">
            {activeStageMenu && (
              <div className="left-stage-menu">
                <div className="module-title">
                  <span>{moduleData.moduleTitle.module}</span>

                  <div
                    className="menu-icon"
                    onClick={() => setActiveStageMenu(!activeStageMenu)}>
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                  </div>
                </div>

                <div className="left-stage-menu-body">
                  {contentMap
                    .find((module) => {
                      const foundModule =
                        module.module === moduleData.moduleTitle.module;
                      return foundModule;
                    })
                    .sections.map((section) => {
                      return (
                        <div key={section.section}>
                          <div className="section-title">{section.section}</div>
                          {section.chapters.map((chapter) => {
                            return (
                              <div
                                className="chapter-box"
                                key={chapter.chapter}
                                onClick={() => {
                                  mixpanel.track("Navigation", {
                                    Action: chapter.chapter,
                                    Location: "Stage Menu",
                                    App: "Kardiograph",
                                  });
                                  history.push(
                                    `/module/${moduleData.moduleTitle.moduleParam}/${section.sectionParam}/${chapter.chapterParam}`
                                  );
                                  setActiveStageMenu(!activeStageMenu);
                                }}>
                                <div
                                  className={
                                    chapter.chapterParam ==
                                    moduleData.chapterTitle.chapterParam
                                      ? "chapter-title active"
                                      : "chapter-title"
                                  }>
                                  {chapter.chapterParam ==
                                    moduleData.chapterTitle.chapterParam && (
                                    <div className="read-icon">
                                      <FontAwesomeIcon icon={faEye} size="lg" />
                                    </div>
                                  )}
                                  {chapter.completed &&
                                    chapter.chapterParam !==
                                      moduleData.chapterTitle.chapterParam && (
                                      <div className="check-icon">
                                        <FontAwesomeIcon
                                          icon={faCheckCircle}
                                          size="lg"
                                        />
                                      </div>
                                    )}
                                  {chapter.chapter}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            <LeftStage
              {...props}
              moduleProgress={moduleProgress}
              setActiveStageMenu={setActiveStageMenu}
              activeStageMenu={activeStageMenu}>
              <Switch>
                <Route
                  path="/module/physiology/:section/:chapter?"
                  render={(props) => <PhysiologyModule {...props} />}
                />
                <Route
                  path="/module/recording-the-ekg/:section/:chapter?"
                  render={(props) => <RecordingModule {...props} />}
                />
                <Route
                  path="/module/rate/:section/:chapter?"
                  render={(props) => <RateModule {...props} />}
                />
                <Route
                  path="/module/rhythm/:section/:chapter?"
                  render={(props) => <RhythmModule {...props} />}
                />
                <Route
                  path="/module/axis/:section/:chapter?"
                  render={(props) => <AxisModule {...props} />}
                />
                <Route
                  path="/module/hypertrophy/:section/:chapter?"
                  render={(props) => <HypertrophyModule {...props} />}
                />
                <Route
                  path="/module/infarction/:section/:chapter?"
                  render={(props) => <InfarctionModule {...props} />}
                />
              </Switch>
            </LeftStage>
          </div>
          {/* {console.log("remounted stage")} */}
          <div className="right-stage">
            <div className="right-stage-inner">
              {stageImg && (
                <LazyLoadImage alt="kardiograph" effect="blur" src={stageImg} />
              )}
            </div>
          </div>
        </StageGrid>
      </StageContainer>
    </StageContext.Provider>
  );
}
const ProgressBar = styled.div`
  background: black;
  border-bottom: 6px solid black;
  position: absolute;
  top: 0;
  z-index: 10;
  width: ${(props) => props.percent}%;
`;

const StageContainer = styled.main`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  /* height: 100%; */
`;

const StageGrid = styled.div`
  position: relative;
  flex: 1 0 auto;
  min-width: 960px;
  /* background-color: #15141f; */
  height: 100%;
  .left-stage-menu {
    background: ${grayscale.offWhite};
    height: 100%;
    z-index: 99;
    position: relative;
    font-family: ${fontFamily.sansSerif};
    .module-title {
      position: absolute;
      top: 0;
      padding: 15px 25px;
      background-color: lightgray;
      width: 100%;
      left: 0;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 1fr auto;
      .menu-icon {
        color: gray;
        cursor: pointer;
      }
    }
    .left-stage-menu-body {
      margin-top: 50px;
      position: relative;
      height: 100%;
      overflow: hidden;
      overflow-y: scroll;
      box-sizing: border-box;
      padding-bottom: 100px;
    }
    .section-title {
      background-color: ${grayscale.lightGray};
      padding: 15px 10px;
      padding-left: 40px;
      font-size: 14px;
      text-transform: uppercase;
    }
    .chapter-title {
      cursor: pointer;
      background-color: ${grayscale.offWhite};
      padding: 15px 10px;
      padding-left: 50px;
      border: 1px solid white;
      position: relative;
      font-size: 14px;
      color: ${grayscale.lightDark};
      &.active {
        background-color: black;
        color: white;
        border-left: 5px solid ${red.bright};
        font-weight: ${fontWeight.bold};
      }
      &:hover {
        color: black;
      }
      .check-icon {
        display: inline-block;
        margin-right: 5px;
        color: ${green.bright};
        position: absolute;
        right: 10px;
      }
      .read-icon {
        display: inline-block;
        margin-right: 5px;
        color: ${grayscale.lightDark};
        position: absolute;
        right: 10px;
      }
      &:hover {
        background-color: white;
      }
    }
  }
  .left-stage {
    position: absolute;
    inset: 0 70% 0% 0%;
  }
  .right-stage {
    position: absolute;
    inset: 0% 0% 0% 30%;
    .right-stage-inner {
      padding: 25px;
      display: flex;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-width: 1920px;
        align-self: center;
        justify-self: center;
      }
    }
  }
  .mobile-image-stage {
    display: none;
  }
  @media (max-width: 430px) {
    min-width: 100%;
    .right-stage {
      display: none;
    }
    .mobile-image-stage {
      display: block;
      width: 100vw;
      position: relative;
      z-index: 10;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        align-self: center;
        justify-self: center;
        border-bottom: 1px solid lightgray;
        background: white;
      }
      .real-img {
        position: fixed;
        top: 0;
      }
    }
    .left-stage {
      position: relative;
      .section-title {
        margin-top: 15px;
      }
      .left-stage-menu {
        position: fixed;
        top: 0;
        width: 100%;
      }
    }
  }
`;
