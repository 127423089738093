import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function AVBlocks(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/AV-Blocks-Intro.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Atrio-Ventricular blocks (aka AV blocks) either slow or eliminate
        conduction from the atria to the ventricles.
      </p>
      <p>
        Minor AV blocks lengthen the brief pause between atrial depolarization
        and ventricular depolarization.
      </p>
      <p>
        Most AV blocks completely block some (or all) supraventricular impulses
        from reaching the ventricles.
      </p>
    </ContentTemplate>
  );
}
