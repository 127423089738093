import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function VentricularFibrillation(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/ventricular-fibrillation.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Ventricular Fibrillation (VF) is caused by numerous parasystolic
        ventricular foci pacing rapidly, producing an erratic, rapid twitching
        of the ventricles.
      </p>
      <p>
        Because there are so many ventricular foci firing rapidly, each one
        repeatedly depolarizes only a small area of the ventricle. This results
        in a rapid, ineffective twitching of the ventricles. Think of a bag of
        worms and imaging how that would look with the worms moving about around
        the bag. This is what the ventricular twitching would look like.
      </p>
      <p>
        On an EKG, the rhythm is erratic, without identifiable waves and no
        predictable patterns. As a result, there is no coordinated propagation
        of depolarization, and therefore the ventricles fail to contact,
        resulting in little to no cardiac output.
      </p>
      <p>
        Ventricular fibrillation is a type of cardiac arrest. There is no
        effective cardiac output because the ventricles are only twitching
        erratically. There is no ventricular pumping, so there is no circulation
        of blood to perfuse the body's organs. If left untreated, ventricular
        fibrillation will lead to death.
      </p>
      {/* <p>[This would be a good area/time to plug the planned "Crash Cart" app - ACLS review]</p> */}
    </ContentTemplate>
  );
}
