import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function ParoxysmalAtrialTachycardia(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Paroxysmal-atrial-Tachycardia-heart.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Paroxysmal-atrial-Tachycardia.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Paroxysmal Atrial Tachycardia (PAT) has a rapid heart rate that
        originates suddenly in a very{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          irritable focus
        </TextMark>{" "}
        in one of the atria. The rate range is usually 150 to 250 per minute, so
        it overdrive suppresses the SA Node and all other automaticity foci.
      </p>
      <p>
        We label the atrial depolarization seen in PAT{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          P' waves
        </TextMark>{" "}
        on an EKG because they originate in an atrial focus that is not from the
        SA node.
      </p>
      <p>
        Each depolarization impulse from the rapidly-pacing, irritable atrial
        focus depolarizes the atria and then is conducted down the ventricular
        conduction system to the ventricles, resulting in P'-QRS-T cycles.
      </p>
      {/* <strong>PAT with AV Block</strong>
      <p>
        Paroxysmal Atrial Tachycardia with AV block is a tachyarrhythmia that
        has has two P' waves for each QRS response on EKG, because the AV node
        blocks the conduction of every other atrial stimulus.
      </p>
      <p>
        This can be seen in digitalis toxicity. Excess digitalis can provoke an
        atrial focus into such an irritable state that it suddenly paces
        rapidly. At the same time, digitalis markedly inhibits the AV Node, so
        that only every second stimulus conducts to the ventricles ( every-other
        atrial stimulus is blocked in the digitalis-inhibited AV Node).
      </p> */}
    </ContentTemplate>
  );
}
