import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function SinusBlock(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Sinus-Block_LfPcxBq.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Sinus-Block-p-wave.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      {/* <p>*[refer to the sinus arrest and block video]*</p> */}
      <p>
        Sinus blocks occur when the{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          SA Node stops
        </TextMark>{" "}
        its pacing activity for at least one complete cycle, so the block is
        usually transient.
      </p>
      <p>
        After the pause of Sinus Block, pacing resumes at the same rate (and
        timing) as before the block. The SA Node resumes its pacing
        responsibility in step with its previous rhythm. However, the pause may
        induce an escape beat from an automaticity focus.
      </p>
      {/* <p>[see escape beats section]</p> */}
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          P waves
        </TextMark>{" "}
        before and after the pause are identical since they originate in the SA
        Node. The SA Node generates atrial depolarizations with the same timing
        as before the block.
      </p>
    </ContentTemplate>
  );
}
