// Dependencies
import { Route, Switch } from "react-router-dom";

// Components
import Divisions from "./Chapters/1-Divisions";
import HorizontalAxis from "./Chapters/2-HorizontalAxis";
import Deflections from "./Chapters/3-Deflections";
import Amplitude from "./Chapters/4-Amplitude";
import ElectrodesLeads from "./Chapters/5-ElectrodesLeads";
import BipolarLeads from "./Chapters/6-BipolarLeads";
import AugmentedLeads from "./Chapters/7-AugmentedLeads";
import CombinedLimbLeads from "./Chapters/8-CombinedLimbLeads";
import ChestLeads from "./Chapters/9-ChestLeads";
import TwelveLeads from "./Chapters/10-TwelveLeads";
import ModifiedPlacement from "./Chapters/11-ModifiedPlacement";

// Styles
export default function RecordingModule(props) {
  return (
    <Switch>
      <Route
        path="/module/recording-the-ekg/graph-paper/divisions"
        render={(props) => <Divisions {...props} />}
      />
      <Route
        path="/module/recording-the-ekg/graph-paper/horizontal-axis"
        render={(props) => <HorizontalAxis {...props} />}
      />
      <Route
        path="/module/recording-the-ekg/cardiac-electrical-recording/deflections"
        render={(props) => <Deflections {...props} />}
      />
      <Route
        path="/module/recording-the-ekg/cardiac-electrical-recording/amplitude-voltage"
        render={(props) => <Amplitude {...props} />}
      />
      <Route
        path="/module/recording-the-ekg/cardiac-electrical-recording/electrodes-and-leads"
        render={(props) => <ElectrodesLeads {...props} />}
      />
      <Route
        path="/module/recording-the-ekg/cardiac-electrical-recording/bipolar-leads"
        render={(props) => <BipolarLeads {...props} />}
      />
      <Route
        path="/module/recording-the-ekg/cardiac-electrical-recording/augmented-leads"
        render={(props) => <AugmentedLeads {...props} />}
      />
      <Route
        path="/module/recording-the-ekg/cardiac-electrical-recording/combined-limb-leads"
        render={(props) => <CombinedLimbLeads {...props} />}
      />
      <Route
        path="/module/recording-the-ekg/cardiac-electrical-recording/chest-leads"
        render={(props) => <ChestLeads {...props} />}
      />
      <Route
        path="/module/recording-the-ekg/cardiac-electrical-recording/12-leads"
        render={(props) => <TwelveLeads {...props} />}
      />
      <Route
        path="/module/recording-the-ekg/cardiac-electrical-recording/modified-lead-placement"
        render={(props) => <ModifiedPlacement {...props} />}
      />
    </Switch>
  );
}
