import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function AtrialHypertrophy(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Atrial-Hypertrophy-v1.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Atrial-Hypertrophy-biphasic.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        The depolarization of both atria causes their simultaneous contraction,
        recorded on an EKG as a P wave. So by examining the P-wave on an EKG, we
        can determine if there are signs of atrial enlargement.
      </p>
      <p>
        Although the designation "atrial hypertrophy" is commonly used,
        enlargement of an atrium is usually due to dilation of the atrium as
        opposed to myocardial hypertrophy. Therefore, the general term atrial
        enlargement is preferred since it includes both dilation and
        hypertrophy.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Lead V1
        </TextMark>{" "}
        is directly over the atria, so the P wave in V1 is usually our best
        source for providing the most accurate information about atrial
        enlargement, which is often represented as a biphasic P-wave in V1.
      </p>
      <p>
        This is a{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          biphasic wave.
        </TextMark>
      </p>
      <p>
        Biphasic waves have both an upward deflection (positive) and downward
        deflection (negative), from an isoelectric baseline.
      </p>
      <p>
        In cases of atrial enlargement, the initial portion of the biphasic wave
        is usually positive, and the terminal component of a biphasic P wave in
        lead V1 is usually negative.
      </p>
    </ContentTemplate>
  );
}
