import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function LevelsOfAutomaticity(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/automaticity-foci.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/automaticity-foci-atria.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/automaticity-foci-junctional.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/automaticity-foci-ventricular_P41ZsR2.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Automaticity or ectopic foci can arise almost anywhere along the heart's
        electrical conduction system. For example, the atria have automaticity
        foci of potential pacemakers within the atrial conduction system, called{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          atrial automaticity foci.
        </TextMark>
      </p>
      <p>
        The proximal end of the AV Node has no automaticity foci. However, the
        middle and distal regions of the AV Node, an area known as the AV
        Junction, have automaticity foci called{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          Junctional Automaticity Foci.
        </TextMark>
      </p>
      <p>
        Purkinje fibers have automaticity foci, so there are foci of these
        potential pacemakers in the Bundle of His along with the Bundle Branches
        and their subdivisions. These foci are called{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          Ventricular Automaticity Foci.
        </TextMark>
      </p>
      <p>
        These automaticity (ectopic) foci each have a broad range of pacemaking
        rates, each having its precise inherent rate at which it paces. Think of
        these as different automaticity "levels."
      </p>
    </ContentTemplate>
  );
}
