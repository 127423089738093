import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function LGL(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/12-leads.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        In patients with LGL syndrome, the AV Node is bypassed by an extension
        of the Anterior Intemodal Tract. Therefore electrical conduction that
        bypasses the AV node is not slowed or delayed prior to being conducted
        to the ventricles.
      </p>
      <p>
        With this syndrome, the AV Node is bypassed by the James tract, so there
        is no significant PR interval delay. The p waves are adjacent to their
        QRS complex on the EKG, resulting a in short PR interval.
      </p>
      <p>
        Normally, the AV Node filters rapid supraventricular rates, in order to
        transmit depolarization to the ventricles at a physiologically
        reasonable rate.
      </p>
      <p>
        In cases of LGL, rapid atrial impulses can bypass the "filter" of the AV
        node resulting a 1:1 atrial to ventricular conduction, resulting in an
        equally rapid ventricular rate.
      </p>
    </ContentTemplate>
  );
}
