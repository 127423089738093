import { useState, Fragment } from "react";

// Dependencies
import { Route, Switch } from "react-router-dom";

// Components
import Module from "./Module/Module";
import Homepage from "./Homepage/Homepage";
import ChecklistSummary from "./ChecklistSummary";
import NoMatch from "./NoMatch";

// Apollo, GraphQL
import { gql, useQuery } from "@apollo/client";

const GET_USER = gql`
  query GET_USER {
    user {
      id
      username
      isSuperuser
      profile {
        id
        subscribedApps {
          id
          title
          description
          url
          logo
        }
      }
    }
  }
`;

export default function App(props) {
  const [newUserContentMap, setNewUserContentMap] = useState();

  const { loading, error, data } = useQuery(GET_USER);
  if (loading) return null;
  if (error) return <h1>Server Error</h1>;
  const user = data.user;
  let userHasApp;

  if (user) {
    userHasApp = user.profile.subscribedApps.find((app) => {
      return app.title === "Kardiograph";
    });
  }

  if (userHasApp) {
    return (
      <Fragment>
        <Switch>
          <Route
            exact
            path="/checklist"
            render={(props) => <ChecklistSummary {...props} user={user} />}
          />
          <Route
            exact
            path="/:syllabus?/:title?"
            render={(props) => (
              <Homepage
                {...props}
                user={user}
                newUserContentMap={newUserContentMap}
                setNewUserContentMap={setNewUserContentMap}
              />
            )}
          />

          <Route
            path="/module/:module/:section/:chapter?"
            render={(props) => (
              <Module
                {...props}
                user={user}
                newUserContentMap={newUserContentMap}
                setNewUserContentMap={setNewUserContentMap}
              />
            )}
          />
          <NoMatch />
        </Switch>
      </Fragment>
    );
  } else {
    window.location = "https://medzcool.com/kardiograph";
    return null;
  }
}
