import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function ElectrodesLeads(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Normal-EKG.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The standard EKG is composed of 12 separate leads, consisting of six
        limb leads recorded using arm and leg electrodes and six chest leads
        obtained by placing an electrode at six different positions on the
        chest.
      </p>
      <p>
        Electrodes are specific anatomical sites on the body, whereas leads
        represent the recording of electricity from a particular electrode or
        pair of electrodes.
      </p>
    </ContentTemplate>
  );
}
