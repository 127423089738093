import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function RightAtrialEnlargement(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/RAE-chart.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/RAE-inferior-leads.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/RAE-V1.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Right atrial enlargement
        </TextMark>{" "}
        produces large and often peaked P waves (P pulmonale) in V1 and the limb
        leads (typically in II, III, and aVF). Additionally, this pattern of
        hypertrophy often causes the initial portion of the biphasic P wave in
        V1 to be larger of the two deflections.
      </p>
      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        Criteria
      </strong>
      <p>Right atrial enlargment produces peaked P waves with heights:</p>
      <ul>
        <li>
          greater than{" "}
          <TextMark
            activeMark={activeMark == 1 && true}
            onClick={() => clickTextMark(1)}>
            2.5 mm
          </TextMark>{" "}
          in the inferior leads (II, III and aVF)
        </li>
        <li>
          greater than{" "}
          <TextMark
            activeMark={activeMark == 2 && true}
            onClick={() => clickTextMark(2)}>
            1.5 mm
          </TextMark>{" "}
          in V1 and V2
        </li>
      </ul>

      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        Causes
      </strong>
      <p>The principal cause is pulmonary hypertension due to:</p>
      <ul>
        <li>Chronic lung disease (cor pulmonale)</li>
        <li>Tricuspid stenosis</li>
        <li>
          Congenital heart disease (pulmonary stenosis, Tetralogy of Fallot)
        </li>
        <li>Tricuspid stenosis</li>
      </ul>
    </ContentTemplate>
  );
}
