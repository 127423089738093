import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function VentricularEscapeBeat(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Ventricular-Escape-beat.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A ventricular automaticity focus can escape overdrive suppression when
        it is not stimulated by pacemaking activity from above for at least one
        to two cycles. This can be seen when the parasympathetic nervous system
        depresses the activity of the SA node, atrial and junctional focus,
        producing a long enough pause allowing the ventricular automaticity foci
        to escape overdrive suppression resulting in a single emitted stimulus.
      </p>
      <p>
        These ventricular escape beats are seen on an EKG as these wide complex
        RS complexes, characteristic of depolarization originating from a
        ventricular focus.
      </p>
    </ContentTemplate>
  );
}
