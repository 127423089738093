import React, { useEffect, useState } from "react";
import { mixpanelToken } from "../settings";

// Dependencies
import mixpanel from "mixpanel-browser";

//Components
import { ContentMap } from "../Content/ContentMap";
// Styles
import styled from "styled-components";
import { grayscale, green } from "@bit/medzcool.cytoplasm.colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { fontFamily } from "@bit/medzcool.cytoplasm.typography";

// Apollo, GraphQL
import { gql, useQuery, useMutation } from "@apollo/client";

const GET_CONTENTMAP = gql`
  query getContentmap($userId: Int!, $course: String!, $data: String) {
    getContentmap(userId: $userId, course: $course, data: $data) {
      id
      user {
        id
      }
      data
      course
    }
  }
`;

const CREATE_CONTENTMAP = gql`
  mutation CreateContentmap($userId: Int!, $course: String!, $data: String!) {
    createContentmap(userId: $userId, course: $course, data: $data) {
      contentmap {
        id
        data
        course
      }
    }
  }
`;

export default function Syllabus(props) {
  const {
    history,
    user,
    newUserContentMap,
    setNewUserContentMap,
    locationData,
    locationLoading,
  } = props;

  const [filteredSyllabus, setFilteredSyllabus] = useState();
  useEffect(() => {
    if (props.match.params.syllabus && newUserContentMap) {
      const filteredContentMap = newUserContentMap.filter((module) => {
        return module.moduleParam == props.match.params.title;
      });
      setFilteredSyllabus(filteredContentMap);
    }
  }, [props.match.params.title, newUserContentMap]);

  const [createContentmap] = useMutation(CREATE_CONTENTMAP, {
    onCompleted: (data) => {},
  });

  // COMPARING USER's vs COURSE CONTENT MAP
  const {
    loading: contentLoading,
    error: contentError,
    data: contentData,
  } = useQuery(GET_CONTENTMAP, {
    variables: {
      userId: user.id,
      course: "kardiograph",
      data: JSON.stringify(ContentMap.data),
    },
    onCompleted: (data) => {
      const existingContentMap = JSON.parse(data.getContentmap.data);

      new Promise((resolve, reject) => {
        let userFlatChapterArray = [];
        const flatArray = existingContentMap.map((module) => {
          module.sections.map((section) => {
            section.chapters.map((chapter) => {
              userFlatChapterArray.push(chapter);
              return chapter;
            });
          });
        });
        resolve(userFlatChapterArray);
      }).then((userFlatChapterArray) => {
        const comparedContentMap = ContentMap.data.map((module) => {
          const newSections = module.sections.map((section) => {
            const newChapters = section.chapters.map((chapter) => {
              const moduleTitle = module.module;
              const sectionTitle = section.section;

              const match = userFlatChapterArray.find(
                (flatChapter) => flatChapter.id == chapter.id
              );

              if (match && match.completed == true) {
                chapter = { ...chapter, completed: true };
              }
              return chapter;
            });
            section = {
              ...section,
              chapters: newChapters,
            };
            return section;
          });
          module = {
            ...module,
            sections: newSections,
          };
          return module;
        });
        setNewUserContentMap(comparedContentMap);
        createContentmap({
          variables: {
            userId: user.id,
            course: "kardiograph",
            data: JSON.stringify(comparedContentMap),
          },
        });
      });
    },
  });

  if (contentLoading) return null;
  if (contentError) return <h1>Server Error</h1>;

  if (!newUserContentMap) return null;
  if (props.match.params.syllabus && !filteredSyllabus) return null;

  return (
    <div>
      {locationData.getLocation.data && (
        <SyllabusNav>
          <NavTitle
            activeNav={!props.match.params.syllabus}
            onClick={() => history.push("/")}>
            All Modules
          </NavTitle>
          <span>&#183;</span>
          <NavTitle
            activeNav={props.match.params.title == "physiology"}
            onClick={() => history.push("/syllabus/physiology")}>
            Physiology
          </NavTitle>
          <span>&#183;</span>
          <NavTitle
            activeNav={props.match.params.title == "recording-the-ekg"}
            onClick={() => history.push("/syllabus/recording-the-ekg")}>
            Recording
          </NavTitle>
          <span>&#183;</span>
          <NavTitle
            activeNav={props.match.params.title == "rate"}
            onClick={() => history.push("/syllabus/rate")}>
            Rate
          </NavTitle>
          <span>&#183;</span>
          <NavTitle
            activeNav={props.match.params.title == "rhythm"}
            onClick={() => history.push("/syllabus/rhythm")}>
            Rhythm
          </NavTitle>
          <span>&#183;</span>
          <NavTitle
            activeNav={props.match.params.title == "axis"}
            onClick={() => history.push("/syllabus/axis")}>
            Axis
          </NavTitle>
          <span>&#183;</span>
          <NavTitle
            activeNav={props.match.params.title == "hypertrophy"}
            onClick={() => history.push("/syllabus/hypertrophy")}>
            Hypertrophy
          </NavTitle>
          <span>&#183;</span>
          <NavTitle
            activeNav={props.match.params.title == "infarction"}
            onClick={() => history.push("/syllabus/infarction")}>
            Infarction
          </NavTitle>
        </SyllabusNav>
      )}
      {props.match.params.syllabus ? (
        <SyllabusContainer>
          {filteredSyllabus.map((module, index) => {
            return (
              <div className="content-container" key={module.module}>
                <div
                  className={
                    locationData.getLocation.data
                      ? "module-container"
                      : "module-container new-user"
                  }>
                  <h2 className="module-title syllabus-title">
                    <div className="module-label">Module</div>
                    <div>{module.module}</div>
                  </h2>
                  {locationData.getLocation.data &&
                    module.sections.map((section) => {
                      return (
                        <div key={section.section}>
                          <h3 className="section-title syllabus-title">
                            {section.section}
                          </h3>
                          {section.chapters.map((chapter) => {
                            return (
                              <div
                                className="chapter-box"
                                key={chapter.chapter}
                                onClick={() => {
                                  mixpanel.track("Syllabus", {
                                    Action: "Clicked Chapter",
                                    Chapter: chapter.chapter,
                                    App: "Kardiograph",
                                  });
                                  history.push(
                                    `/module/${module.moduleParam}/${section.sectionParam}/${chapter.chapterParam}`
                                  );
                                }}>
                                <div className="chapter-title syllabus-title">
                                  <div className="external-link-icon">
                                    <FontAwesomeIcon
                                      icon={faExternalLink}
                                      size="sm"
                                    />
                                  </div>
                                  {chapter.completed && (
                                    <div className="check-icon">
                                      <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        size="lg"
                                      />
                                    </div>
                                  )}
                                  {chapter.chapter}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </SyllabusContainer>
      ) : (
        <SyllabusContainer>
          {!locationData.getLocation.data && (
            <div className="course-description">
              <h2>Course Description</h2>
              <p>
                Kardiograph is an interactive course designed to teach an easy,
                stepwise approach to interpreting electrocardiograms. You’ll
                learn how cardiac electrophysiology translates to the lines and
                waves seen on an electrocardiogram and how to analyze and
                interpret cardiac rhythms using a step-by-step approach.
              </p>
            </div>
          )}

          <div className="syllabus-container">
            <h2 className={locationData.getLocation.data && "syllabus-label"}>
              Syllabus
            </h2>
            {newUserContentMap.map((module, index) => {
              return (
                <div className="content-container" key={module.module}>
                  <div
                    className={
                      locationData.getLocation.data
                        ? "module-container"
                        : "module-container new-user"
                    }>
                    <h2 className="module-title syllabus-title">
                      <div className="module-label">Module {index + 1}</div>
                      <div>{module.module}</div>
                    </h2>
                    {locationData.getLocation.data &&
                      module.sections.map((section) => {
                        return (
                          <div key={section.section}>
                            <h3 className="section-title syllabus-title">
                              {section.section}
                            </h3>
                            {section.chapters.map((chapter) => {
                              return (
                                <div
                                  className="chapter-box"
                                  key={chapter.chapter}
                                  onClick={() => {
                                    mixpanel.track("Syllabus", {
                                      Action: "Clicked Chapter",
                                      Chapter: chapter.chapter,
                                      App: "Kardiograph",
                                    });
                                    history.push(
                                      `/module/${module.moduleParam}/${section.sectionParam}/${chapter.chapterParam}`
                                    );
                                  }}>
                                  <div className="chapter-title syllabus-title">
                                    <div className="external-link-icon">
                                      <FontAwesomeIcon
                                        icon={faExternalLink}
                                        size="sm"
                                      />
                                    </div>
                                    {chapter.completed && (
                                      <div className="check-icon">
                                        <FontAwesomeIcon
                                          icon={faCheckCircle}
                                          size="lg"
                                        />
                                      </div>
                                    )}
                                    {chapter.chapter}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        </SyllabusContainer>
      )}
    </div>
  );
}
const SyllabusNav = styled.div`
  padding: 15px;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: fit-content;
  border: 1px solid lightgray;
  border-radius: 50px;
  span {
    margin-left: 15px;
    margin-right: 15px;
  }
  @media (max-width: 450px) {
    display: none;
  }
`;
const NavTitle = styled.div`
  display: inline-block;
  cursor: pointer;
  color: ${(props) => props.activeNav && "#F0445D"};
  font-weight: ${(props) => props.activeNav && "bold"};
`;
const SyllabusContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  max-width: 800px;
  margin: auto;
  margin-bottom: 100px;
  box-sizing: border-box;
  hr {
    margin-bottom: 50px;
  }
  .content-container {
    max-width: 800px;
    margin: auto;
  }
  .course-description {
    margin-bottom: 100px;
    margin-top: 100px;
    p {
      font-size: 1.3em;
    }
  }
  .syllabus-container {
    margin-top: 100px;
  }
  .resume-container {
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid ${grayscale.gray};
    border-radius: 5px;
    margin-bottom: 50px;
    margin-top: 50px;
    padding: 15px;
    .module {
      text-transform: uppercase;
      color: gray;
      font-size: 14px;
      margin-bottom: 15px;
    }
    .section {
      text-transform: uppercase;
      color: gray;
      font-size: 14px;
    }
    .chapter {
      margin-top: 0;
    }
  }
  .module-container {
    margin-bottom: 50px;
    border: 1px solid ${grayscale.lightGray};
    &.new-user {
      margin-bottom: 0;
    }
  }
  .syllabus-title {
    margin: 0;
    &.module-title {
      .module-label {
        font-size: 12px;
        text-transform: uppercase;
      }
      background-color: ${grayscale.darkDark};
      color: white;
      padding: 15px;
    }
    &.section-title {
      background-color: ${grayscale.gray};
      padding: 15px 10px;
      padding-left: 40px;
    }
    &.chapter-title {
      background-color: ${grayscale.offWhite};
      padding: 15px 10px;
      padding-left: 40px;
      border: 1px solid white;
      position: relative;
      .external-link-icon {
        margin-right: 15px;
        display: inline-block;
        color: lightgray;
      }
      .check-icon {
        display: inline-block;
        margin-right: 5px;
        color: ${green.bright};
        position: absolute;
        right: 15px;
      }
      &:hover {
        background-color: ${grayscale.lightGray};
      }
    }
  }
  .chapter-box {
    cursor: pointer;
  }
  @media (max-width: 450px) {
    .syllabus-label {
      display: none;
    }
    .course-description {
      margin-top: 50px;
      p {
        font-size: 1.2em;
      }
    }
    .syllabus-container {
      margin-top: 0px;
    }
    .syllabus-title {
      &.section-title {
        padding-left: 15px;
      }
      &.chapter-title {
        padding-left: 15px;
      }
    }
  }
`;
