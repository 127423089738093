import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function ParoxysmalAtrialTachycardia(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Paroxysmal-junctional-Tachycardia-heart.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Paroxysmal-junctional-Tachycardia-strip.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Paroxysmal Junctional Tachycardia (PJT) is caused by the sudden rapid
        pacing of a very irritable automaticity focus in the{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          AV Junction
        </TextMark>{" "}
        that paces at a rate of 150 to 250 beats per minute.
      </p>
      <p>
        Because the automaticity focus originates at the AV junction, you will{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          not see P waves or P' waves.
        </TextMark>
      </p>
      <p>
        A rapidly pacing (irritable) Junctional focus may also depolarize the
        atria from below in retrograde fashion to record:
      </p>
      <ul>
        <li>an inverted P' immediately before each upright QRS, or</li>
        <li>an inverted P' after each upright QRS, or</li>
        <li>an inverted P' buried within each QRS (difficult to detect).</li>
      </ul>
      <p>
        Each stimulus from a rapidly pacing Junctional focus may occur when the
        Left Bundle Branch has fully repolarized, but the Right Bundle Branch is
        still refractory, or vice versa. As a result, this aberrant ventricular
        conduction depolarizes the left ventricle before the right to produce a
        widened QRS complex.
      </p>
    </ContentTemplate>
  );
}
