import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function HorizontalAxis(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/deflections.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/positive-deflections.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/negative-deflections.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/positive-electrode.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The height or depth of waves is measured in millimeters from a baseline
        and is a measure of voltage.
      </p>
      <p>
        The direction in which an EKG projects from the baseline is called the
        deflection of a wave. Waves pointing upwards from baseline are referred
        to as an "upward deflection," and similarly, waves pointing down are
        referred to as a "downward deflection" of a wave.
      </p>
      <p>
        Alternatively, upward deflections are called{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          positive deflections.
        </TextMark>{" "}
        Downward deflections are called{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          negative deflections.
        </TextMark>
      </p>
      <p>
        These positive and negative deflections result from cardiac electrical
        activity measured by electrodes on a person's body. For example, when
        cardiac electrical activity advances toward a{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          positive skin electrode,
        </TextMark>{" "}
        this produces a positive (upward) deflection on EKG.
      </p>
      <p>
        Remember that when a cardiac myocyte depolarizes, it becomes more
        positive.
      </p>
      <p>
        So with depolarization, the advancing wave of positive intracellular
        charges produces a positive deflection on EKG as this wave moves toward
        a positive electrode. More on this later.
      </p>
    </ContentTemplate>
  );
}
