import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function BundleBranchBlocks(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Bundle-Branch-Blocks-intro.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Bundle-Branch-Blocks-out-of-phase.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Bundle-Branch-Blocks-wide-qrs.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/Bundle-branch-blocks-right-vs-left.png",
    },
    {
      id: 4,
      img:
        "https://medzcool-images.s3.amazonaws.com/Bundle-Branch-Blocks-chest-leads.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Bundle Branch Block (BBB) is caused by a conduction block in the Right
        or the Left Bundle Branch, resulting in a delayed depolarization to the
        ventricles.
      </p>
      <p>
        Typically, both ventricles are depolarized simultaneously. But with a
        Bundle Branch Block, the unblocked Bundle Branch conducts normally,
        while depolarization in the blocked Bundle Branch occurs slowly through
        the surrounding muscle to stimulate the Bundle Branch below the block.
        After the delay, depolarization proceeds rapidly again below the block.
      </p>
      <p>
        Individual depolarization of the right ventricle and left ventricle are
        still of normal duration. But because the ventricles do not depolarize
        simultaneously, this produces a{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          "widened QRS"
        </TextMark>{" "}
        appearance that we see on the EKG. The two "out-of-sync" QRSs are
        superimposed on one another, and the machine records this combined
        electrical activity as a widened QRS with two peaks.
      </p>
      <p>
        In summary, in Bundle Branch Blocks, one ventricle depolarizes slightly
        later than the other, causing two "joined QRS complexes" to appear on
        EKG.
      </p>
      <p>
        Because the "widened QRS" represents the non-simultaneous depolarization
        of both ventricles, we can sometimes see two R waves named in sequential
        order: R and R'. The R' (pronounced "R-prime") represents{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          delayed depolarization
        </TextMark>{" "}
        of the blocked ventricle. Remember that with normally conducting bundle
        branches, which simultaneously depolarize, the duration of the QRS
        complexes is less than 120ms. In bundle branch blocks, this widened QRS
        complex has an increased duration of{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          greater than or equal to 120ms
        </TextMark>{" "}
        or more than or equal to 3 small squares on the EKG graph paper.
      </p>
      <p>
        When the QRS complex is wide enough (0.12 sec. or more) to diagnose a
        BBB, we immediately look at the right and left{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          chest leads
        </TextMark>{" "}
        for the R, R'. That's because the diagnosis and differentiation between
        LBBB and RBBB are often made by the QRS complex morphologies alone. The
        right chest leads are V1 and V2, and the left chest leads are V5 and V6.
      </p>
      {/* <p>
        Make a mental note of the typical QRS pattern (i.e., shape) of RBBB and
        LBBB, because differentiating between these two, is often made by
        appearance of the QRS morphologies in the limb leads alone.
      </p>
      <p>
        You can't determine the mean QRS vector to determine the heart axis in
        the setting of a BBB. Similarly You cannot determine the presence of
        ventricular hypertrophy in the setting of a BBB. [p 202 - rhythm 2]
      </p> */}
      <p>In summary:</p>
      <ol>
        <li>
          QRS complexes must be at least 120ms or greater.
          <ul>
            <li>
              Occasionally you will see an R, R' in a QRS of normal duration.
              This is called "incomplete" BBB.
            </li>
          </ul>
        </li>
        <li>Check for R, R'</li>
        <ul>
          <li>The R, R' pattern may occur in only one chest lead.</li>
          <li>V1 or V2 for right chest leads</li>
          <li>V5 or V6 for left chest leads</li>
        </ul>
      </ol>
    </ContentTemplate>
  );
}
