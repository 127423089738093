import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function ParoxysmalTachycardia(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Paroxysmal-Tachycardia.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The rate range of the paroxysmal tachycardias is generally 150 to 250
        per minute. However, you may group any sudden rhythms with rates less
        than 150 but greater than 125 into this category as well.
      </p>
      <p>
        Paroxysmal tachycardia arises suddenly from a very irritable
        automaticity focus. This is in contrast to sinus tachycardia, which is
        typically the result of the SA Node's gradual response to exercise,
        excitement, etc. Although the SA Node's pacing rate may eventually
        become rapid, Sinus tachycardia is typically neither sudden nor does it
        originate in an automaticity focus.
      </p>
    </ContentTemplate>
  );
}
