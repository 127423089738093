import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function LeftAtrialEnlargement(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/LAE-chart_kNFfOBU.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/LAE-V1-width.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/LAE-V1-depth.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/LAE-II-peaks.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/LAE-II-total.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Left atrial enlargement
        </TextMark>{" "}
        causes the terminal portion of the biphasic P wave in V1 to be large and
        wide and causes a broad and bifid P wave in lead II (P mitrale). This is
        common when left atrial pressures or volumes are increased.
      </p>
      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        Criteria
      </strong>
      <p>In V1</p>
      <ul>
        <li>
          Biphasic P wave with terminal negative portion{" "}
          <TextMark
            activeMark={activeMark == 1 && true}
            onClick={() => clickTextMark(1)}>
            &gt; 40 ms duration
          </TextMark>
        </li>
        <li>
          Biphasic P wave with terminal negative portion{" "}
          <TextMark
            activeMark={activeMark == 2 && true}
            onClick={() => clickTextMark(2)}>
            &gt; 1mm deep
          </TextMark>
        </li>
      </ul>
      <p>In lead II</p>
      <ul>
        <li>
          Bifid P wave with{" "}
          <TextMark
            activeMark={activeMark == 3 && true}
            onClick={() => clickTextMark(3)}>
            &gt; 40 ms
          </TextMark>{" "}
          between the two peaks
        </li>
        <li>
          Total P wave duration{" "}
          <TextMark
            activeMark={activeMark == 4 && true}
            onClick={() => clickTextMark(4)}>
            &gt; 110 ms
          </TextMark>
        </li>
      </ul>

      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        Causes
      </strong>
      <p>
        In isolation, left atrial enlargment can be seen with mitral stenosis.
      </p>
      <p>In association with left ventricular hypertrophy:</p>
      <ul>
        <li>Hypertension</li>
        <li>Aortic stenosis</li>
        <li>Mitral Regurgitation</li>
        <li>Hypertrophic cardiomyopathy</li>
      </ul>
    </ContentTemplate>
  );
}
