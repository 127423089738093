import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function WPW(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/12-leads.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        WPW is caused by an accessory pathway for electrical depolarization to
        bypass the AV node and straight down to the ventricles.
      </p>
      <p>
        The accessory Bundle of Kent causes ventricular pre-excitation in
        Wolff-Parkinson-White (WPW) syndrome.
      </p>
      <p>
        The delta wave creates the illusion of a "shortened" PR interval and
        "lengthened" QRS. The delta wave actually records the depolarization of
        an area of ventricular pre-excitation.
      </p>
      <p>
        WPW can lead to a paroxysmal tachycardia by three possible mechanisms:
      </p>
      <ul>
        <li>
          rapid conduction - supraventricular tachycardia (including atrial
          flutter or atrial fibrillation) may be rapidly conducted l: 1 through
          this accessory pathway producing dangerously high ventricular rates.
        </li>
        <li>
          Some Kent Bundles have been found to contain automaticity foci that
          can initiate a paroxysmal tachycardia.
        </li>
        <li>
          re-entry - ventricular depolarization may immediately restimulate the
          atria in a retrograde fashion via the accessory pathway causing a
          theoretical circus re-entry loop.
        </li>
      </ul>
    </ContentTemplate>
  );
}
