import { mixpanelToken } from "../../settings";

// Dependencies
import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";

//Components
// Styles
import styled from "styled-components";
import { fontFamily, fontWeight } from "@bit/medzcool.cytoplasm.typography";

export default function ModuleHeader(props) {
  return (
    <Header>
      <div
        className="header-item"
        onClick={() => {
          mixpanel.track("Navigation", {
            Action: "Syllabus",
            Location: "Homepage",
            App: "Kardiograph",
          });
        }}>
        <Link to="/">SYLLABUS</Link>
      </div>
    </Header>
  );
}

const Header = styled.header`
  position: relative;
  height: 4rem;
  z-index: 14;
  background-color: rgb(21, 20, 31);
  font-family: ${fontFamily.sansSerif};
  display: grid;
  align-items: center;
  padding: 0 25px;
  .header-item {
    a {
      text-decoration: none;
      color: white;
    }
  }
  @media (max-width: 430px) {
    display: none;
  }
`;
