import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function IdentifyingInfarcts(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Identifying-Infarcts-summary.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        When looking at an EKG tracing for infarction, first note which leads
        have significant Q waves (except aVR). The diagnosis of infarction is
        usually based on the presence of significant Q waves resulting from a
        necrotic myocardium. Remember, however, that the presence of Q waves
        tells us nothing about the acuity of the infarct.
      </p>
      <p>
        We ignore lead aVR when looking for significant Q waves since this lead
        is positioned in such a way that data regarding Q waves are unreliable.
        Lead aVR is like an upside-down lead II, so the large Q waves commonly
        seen in lead aVR are really the upside-down R waves from lead II.
      </p>
      <p>
        Also, when looking for signs of infarction on an EKG, make a note of any
        leads where you see inverted T-waves suggesting ischemia and leads where
        ST segment elevations or depressions are present, suggesting an acute
        myocardial infarction.
      </p>
      <p>
        Finally, keep in mind the leads that make up the lateral, inferior, and
        chest lead designations, as this will allow us to localize the infarcts
        anatomically.
      </p>
    </ContentTemplate>
  );
}
