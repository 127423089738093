import { useEffect, useContext } from "react";

// Dependencies
// Components

// Context
import { StageContext } from "../Module/Stage/ModuleStage";

// Styles
import styled from "styled-components";

export default function ContentTemplate(props) {
  const contextValue = useContext(StageContext);
  const { setStageImg, activeMarkData } = contextValue;

  useEffect(() => {
    if (activeMarkData) {
      const loadMark = activeMarkData.find((mark) => mark.id == 0);
      setStageImg(loadMark.img);
    }
  }, [activeMarkData]);

  return <ContentTemplateContainer>{props.children}</ContentTemplateContainer>;
}

const ContentTemplateContainer = styled.div`
  sup {
    font-size: 12px;
  }
`;
