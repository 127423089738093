import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import ClickReveal from "../../../Styles/ClickReveal";

export default function Practice4(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/practice-4.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Clinical scenario: a patient reports sudden onset palpitations. What is
        the likely rhythm?
      </p>
      <ClickReveal>
        <p>
          First, we recognize a rapid cardiac rate of &gt;150 beats per minute,
          making this a tachycardia.
        </p>
        <p>
          Given the report by the patient that this sensation was sudden, we can
          determine this to be a paroxysmal tachycardic rhythm.
        </p>
        <p>
          Notice the narrow and normal-appearing QRS complexes. This should
          allow you to eliminate a ventricular focus, so we are left with a
          supraventricular tachycardic source.
        </p>
        <p>
          We notice upright P-waves, so this is probably the result of an
          automaticity focus in the atria. It's unlikely that these P-waves are
          the result of SA node depolarization because the heart rate being
          &gt;150 bpm would be atypical for the SA node in that it's too fast to
          be a sinus tachycardia. So these P-waves on this rhythm are actually
          P' waves.
        </p>
        <p>
          These P' waves have a similar morphology suggesting they originate
          from a single irritable atrial focus.
        </p>
        <p>
          Although junctional tachycardias can also result in P' waves, remember
          that the P' waves caused by a junctional focus are recorded from
          retrograde depolarizations from the AV junction back towards the
          atria, producing inverted P' waves, which are located almost
          immediately adjacent to the QRS complexes that cause them.
        </p>
        <p>
          Therefore this is a paroxysmal atrial tachycardia (PAT), and because
          each P' wave produces a QRS complex, it cannot be PAT with block.
        </p>
      </ClickReveal>
    </ContentTemplate>
  );
}
