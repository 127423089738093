import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function RateTheory(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/rate-regular_QJNl1Du.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/rate-fast_bqFTFJ9.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/rate-slow_OQaG7mF.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        You can determine the heart rate on an EKG based on the amount of time
        between each cycle.
      </p>
      <p>
        In other words, you can count the number of small or large squares on
        the EKG graph paper between each cardiac cycle or between consecutive R
        waves.
      </p>
      <RateBlock>
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          <strong>Regular Rhythms</strong>
        </TextMark>
        <ul>
          <li>
            Rate = 300 / number of LARGE squares between consecutive R waves.
          </li>
        </ul>
      </RateBlock>
      <RateBlock>
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          <strong>Very Fast Rhythms</strong>
        </TextMark>
        <ul>
          <li>
            Rate = 1500 / number of SMALL squares between consecutive R waves.
          </li>
        </ul>
      </RateBlock>
      <RateBlock>
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          <strong>Slow or Irregular Rhythms</strong>
        </TextMark>
        <ul>
          <li>Rate = Number of R waves x 6</li>
          <li>
            The number of complexes (count R waves) on the rhythm strip gives
            the average rate over a ten-second period. This is multiplied by 6
            (10 seconds x 6 = 1 minute) to give the average Beats per minute
            (bpm).
          </li>
        </ul>
      </RateBlock>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
