import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function Introduction(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Introduction.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/introduction-vector.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        We can attempt to localize areas of infarction in the heart by using the
        anatomical location of the EKG leads. Let's first revisit how
        depolarization moves through the ventricles to understand this better.
      </p>
      <p>
        Remember that{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          vectors
        </TextMark>{" "}
        describe the path of myocardial conduction. In the ventricles,
        depolarization proceeds from the inner endocardial lining, through the
        full thickness of the ventricular wall to the outer epicardial surface.
        This results in opposing vectors of depolarization given the
        circumferential nature of the ventricular chambers.
      </p>
      <p>
        For instance, left ventricular depolarization moves in opposite
        directions in opposing walls simultaneously. Depolarization of the
        lateral wall moves toward the patient's left, while depolarization of
        the medial (septal) wall moves toward the right. Additionally,
        depolarization of the anterior ventricular wall moves anteriorly, while
        simultaneously, depolarization of the posterior ventricular wall moves
        in a posterior direction. This is important to understand because these
        opposing vectors of depolarization typically cancel each other out.
      </p>
      <p>
        In the setting of an infarct, these muscles cannot depolarize and have
        no vectors. Remember that depolarization moving away from a positive
        electrode records a negative wave on an EKG.
      </p>
      <p>
        So, the positive electrode nearest to the infarct will detect{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          no depolarization
        </TextMark>{" "}
        vectors going towards it. Instead, it sees only the vectors from the
        opposite wall (through the necrotic void), moving away from this
        electrode. Therefore, a Q wave is recorded on EKG in the leads, which
        uses that positive electrode for recording.
      </p>
      <p>Using left ventricular depolarization as our vantage point:</p>
      <ul>
        <li>
          With an anterior infarct, the positive (chest) electrode detects only
          the initial "away" vectors from the opposite side, so a Q is inscribed
          on EKG in leads V1 - V4, which use that positive electrode for
          recording.
        </li>
        <li>
          With a lateral infarct, the positive left arm electrode detects only
          the initial "away" vectors from the opposite side, so a Q is inscribed
          on EKG in leads I and AVL, which use that positive electrode for
          recording.
        </li>
        <li>
          With an inferior infarct, the positive left foot electrode detects
          only the initial " away" vectors from the opposite side, so a Q is
          recorded on EKG in leads II, III, and aVF, which use that positive
          electrode for recording.
        </li>
      </ul>
    </ContentTemplate>
  );
}
