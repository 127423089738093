import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function TorsadesdePointes(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Torsades-rhythm-strip.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Torsades-twisting.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/Torsades-R-T.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/Torsades-QTc.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/Torsades-multi-foci.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Torsades de Pointes is a dangerous type of cardiac dysrhythmia, which
        has a characteristic{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          appearance
        </TextMark>{" "}
        on an EKG. It is often described as having the appearance of QRS
        complexes that seem to oscillate or{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          "twist"
        </TextMark>{" "}
        around an isoelectric line, hence the name Torsades de pointes which
        loosely translates to "twisting of points" in English.
      </p>
      <p>
        The dysrhythmia seen in Torsades is initiated when a Premature
        Ventricular Complex occurs during the preceding T wave, known as an{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          "R on T"
        </TextMark>{" "}
        phenomenon, as in a qRs complex occurring on the preceding T wave. This
        can happen because an irritable ventricle produces a PVC which occurs at
        the same time as the prior T-wave, as you can see in cases that lead to
        a prolonged{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          QT interval
        </TextMark>{" "}
        (e.g., congenital abnormalities, medication-induced, electrolyte
        disorders, etc.).
      </p>
      <p>
        It is thought to arise from multiple ectopic ventricular foci, which is
        why some people classify Torsades as a type of{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          Polymorphic
        </TextMark>{" "}
        Ventricular Tachycardia, a form of ventricular tachycardia in which
        there are numerous ventricular foci with the resultant QRS complex
        varying in amplitude, axis, and duration.
      </p>
      <p>
        On an EKG, the rate is rapid, at almost a rate of 300 beats per minute,
        and there are no preceding p-waves, suggesting a non-sinus tachycardic
        rhythm. Furthermore, each of these complexes is wide, suggesting they
        are likely from a ventricular source. Now notice how these wide
        complexes have varying shapes and amplitudes. This suggests
        depolarization from not just one but multiple ectopic ventricular foci.
      </p>
    </ContentTemplate>
  );
}
