import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function AnteriorInfarction(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Anterior-Infarction-1.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Anterior-Infarction-2.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/Anterior-Infarction-3.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/Anterior-Infarction-4.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        The presence of{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Q waves
        </TextMark>{" "}
        in V1, V2 , V3, or V4 would indicate an infarction in the anterior wall
        of the left ventricle. Remember that the{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          chest leads
        </TextMark>{" "}
        are mainly placed anteriorly, which is an excellent way to remember the
        leads for anterior infarction.
      </p>
      <p>
        Remember that the anterior portion of the left ventricle includes part
        of the interventricular septum. So when isolated Q waves appear in V1
        and V2, the infarction includes the septum. As a result, this type of
        infarction is sometimes referred to as an antero-septal infarction.
      </p>
      {/* <p>
        [show picture of V1 and V2 in cross section in relation to anatomical
        location of heart, label left ventricle and Interventricular septum]
      </p> */}
      {/* <p>
        [show picture of v3 and v4 in cross section with labels of the heart
        chambers].
      </p> */}
      <p>
        Similarly, isolated Q waves in V3 and V4, which are laterally located
        chest leads, represent an antero-lateral infarction. Remember that
        (insignificant) q waves are seen normally in V5 and V6.
      </p>
      <p>
        Remember that Q waves only tell us that an infarction is present but
        does not tell us if this is an acute infarction or not. With acute
        anterior infarcts, you'll also see{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          ST elevations
        </TextMark>{" "}
        in the above-mentioned anterior leads (V1-V4). ST elevations present in
        V1 and V2 would similarly signify an acute infarction in the
        antero-septal regions of the heart.
      </p>

      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        AFFECTED CORONARY ARTERY
      </strong>

      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          Anterior Descending
        </TextMark>{" "}
        branch of the Left Coronary Artery supplies blood to the anterior
        portion of the left ventricle. So, an anterior infarction is due to an
        occlusion of the Anterior Descending branch of the Left Coronary Artery.
      </p>
    </ContentTemplate>
  );
}
