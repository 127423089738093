import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function ModifiedPlacement(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/12-leads.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The six limb leads can also be recorded by using carefully positioned
        electrodes on the patient's trunk. In this way, a standard twelve lead
        EKG can be recorded using trunk electrodes.
      </p>
      <p>
        You'll often encounter this modified placement in the hospital in
        patients requiring telemetry or cardiac monitoring. You might also see
        this modified lead placement in the field, as it is a popular placement
        convention for many Paramedics and Emergency Medical Technicians.
      </p>
    </ContentTemplate>
  );
}
