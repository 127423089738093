import { useEffect, useContext } from "react";
// Dependencies

// Components
import ContentTemplate from "../../ContentTemplate";

// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function GraphPaper(props) {
  const contextValue = useContext(StageContext);
  const { setActiveMarkData, activeMark, clickTextMark } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/1-Graph-Paper.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/2-Graph-Paper.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/3-Graph-Paper.png",
    },
  ];
  useEffect(() => {
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The EKG is recorded on ruled graph paper. This is achieved by recording
        cardiac electrical activity as a single strip or recording many
        different leads simultaneously.
      </p>
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          smallest divisions
        </TextMark>{" "}
        are one millimeter long and one millimeter high.
      </p>
      <p>
        These smaller divisions are grouped into{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          larger squares,
        </TextMark>{" "}
        five small squares long by five small squares wide. Each large square is
        formed by the heavier lines on the graph paper.
      </p>
    </ContentTemplate>
  );
}
