import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function VentricularConduction(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/ventricular-conduction-system.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/QRS-complex.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/T-wave.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/U-wave.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        After passing through the AV Node, depolarization proceeds rapidly
        through the{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          ventricular conduction system.
        </TextMark>
      </p>
      <p>
        The ventricular conduction system starts at the Bundle of His and splits
        into the right and left bundle branches, which terminate at Purkinje
        filaments to distribute depolarization to the endocardial surface of the
        ventricles. This depolarization of the ventricles produces a{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          QRS complex
        </TextMark>{" "}
        on an EKG and initiates ventricular contraction.
      </p>
      <p>
        Once depolarization of the ventricles is completed, the process of
        repolarization of these cells occurs, which is represented as a{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          T-wave
        </TextMark>{" "}
        on the EKG. But the timing of repolarization between these types of
        specialized cells slightly differs.
      </p>
      <p>
        Repolarization of the Purkinje fibers takes longer than ventricular
        repolarization. That is, the end of the T wave marks the end of
        ventricular repolarization; however, repolarization of the Purkinje
        fibers terminates a little later - beyond the end of the T wave. In some
        cases, you may see a small bump after the T wave, labeled here as a{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          U-wave,
        </TextMark>{" "}
        which some experts have theorized represents Purkinje fiber
        repolarization.
      </p>
      <p>
        Similar to the AV junction, the Purkinje fibers of the ventricular
        conduction system also contain automaticity foci which can be activated
        when they are not suppressed by a faster site of automaticity coming
        from above. In other words, if all preceding pacemakers and automaticity
        foci before the ventricles fail, the Purkinje fibers are the last source
        of electric automaticity for the heart.
      </p>
    </ContentTemplate>
  );
}
