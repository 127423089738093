import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function HeartBlock(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Heart-Blocks-Intro.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Blocks (aka heart blocks) prevent the conduction of depolarization. They
        may develop in any of these areas: the SA node, the AV node, the bundle
        of His, the Bundle Branches, or in either of the two subdivisions of the
        Left Bundle Branch (Hemiblock).
      </p>
    </ContentTemplate>
  );
}
