import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function LeftAxisDeviation(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Left-Axis-Deviation.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        If the QRS in lead I is upright, the Vector points to the patient's
        left.
      </p>
      <p>
        If the Vector is pointing upward, then the QRS in lead aVF is mainly
        below the baseline.
      </p>
      <p>
        And when the Vector points upward, and to the patient's left, this is
        Left Axis Deviation.
      </p>
      <p>
        In summary, if the QRS is positive in lead I and negative in aVF, that
        places the Vector in the upper left quadrant.
      </p>
    </ContentTemplate>
  );
}
