import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import ClickReveal from "../../../Styles/ClickReveal";

export default function Practice2(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Practice-2.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>What is the likely rhythm?</p>
      <ClickReveal>
        <p>
          This tracing is from a medical student who had a few cups of coffee in
          order to study late. She went to the Emergency Room because her pulse
          seemed irregular. The intern on duty thought that the tracing showed
          "intermittent complete AV Block" and was about to call the attending
          physician (at 4:00 am) to schedule an emergency artificial pacemaker
          implantation. Explain the EKG strip to the intern, using only what you
          have learned so far (before he wakes the attending physician and
          discovers the real meaning of "irritable").
        </p>
      </ClickReveal>
      {/* <p>What is the likely rhythm?</p>
      <p>
        This transmitted telemetry tracing is from a known drug abuser who took
        a large quantity of amphetamines before his emergency ride to the
        hospital. Someone in the ambulance suggested what sounded like "Winky
        bok block," when the telemetry was transmitted. Utilizing only what you
        have read and understand so far in this book, you will recognize things
        that you have just learned. Notice that in each grouping only two of the
        P waves are identical. Carefully analyze what you see, so you can
        explain it to others.
      </p> */}
    </ContentTemplate>
  );
}
