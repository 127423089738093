import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function JunctionalEscapeRhythm(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Junctional-Escape_5nIAqSN.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Junctional-Escape-before.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Junctional-Escape-after_QxWaknc.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A Junctional focus escapes the influence of overdrive suppression if
        there is a Sinus Arrest. A junctional focus may also "escape" when the
        atrial foci also fail to function correctly or if there is a complete
        conduction block in the proximal end of the AV Node. In either case, the
        Junctional focus is not regularly stimulated by pacing depolarizations
        from above.
      </p>
      <p>
        When a Junctional focus is not overdrive-suppressed, it actively paces,
        producing a{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Junctional Escape Rhythm.
        </TextMark>{" "}
        It becomes the dominant pacemaker of the ventricles at a rate ranging
        from 40 to 60 beats per minute - this is also called an idiojunctional
        rhythm.
      </p>
      <p>
        A Junctional Escape Rhythm usually conducts mainly to the ventricles,
        producing a series of lone QRS complexes, typically, without proceeding
        p-waves.
      </p>
      <p>
        With a Junctional Escape Rhythm, every paced stimulus will depolarize
        the ventricles. Still, the pacing may also depolarize the atria from
        below in a retrograde fashion, producing inverted P' waves in EKG leads
        with an upright QRS.
      </p>
      <p>
        The AV Node conducts very slowly, so depolarization from a Junctional
        focus may delay either ventricular depolarization or retrograde atrial
        depolarization (if present). As a result, if there is retrograde atrial
        depolarization from a Junctional focus, it may record on EKG with one of
        these three patterns:
      </p>
      <ul>
        <li>
          retrograde (inverted){" "}
          <TextMark
            activeMark={activeMark == 1 && true}
            onClick={() => clickTextMark(1)}>
            P' wave
          </TextMark>{" "}
          immediately before each QRS
        </li>
        <li>
          retrograde (inverted){" "}
          <TextMark
            activeMark={activeMark == 2 && true}
            onClick={() => clickTextMark(2)}>
            P' wave
          </TextMark>{" "}
          after each QRS
        </li>
        <li>
          retrograde (inverted) P' wave buried within each QRS (not shown)
        </li>
      </ul>
    </ContentTemplate>
  );
}
