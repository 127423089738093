import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import ClickReveal from "../../../Styles/ClickReveal";

export default function Practice5(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/practice-5.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>What type of block do you see?</p>
      <ClickReveal>
        <p>
          First, looking at the PR intervals, you should notice that the third
          cycle has a PR interval that is &gt;200ms. At this point, you should
          be thinking about some type of AV block.
        </p>
        <p>
          Next, evaluating each P wave, we notice that the last P-wave on this
          rhythm is not followed by a QRS complex, suggesting a failure of this
          atrial impulse to conduct to the ventricles. At this point, we should
          be thinking of a second-degree heart block.
        </p>
        <p>
          Finally, notice the progressively prolonging PR intervals on each
          successive cardiac cycle.
        </p>
        <p>This is a Wenckebach (Type 1) Second degree AV block.</p>
      </ClickReveal>
    </ContentTemplate>
  );
}
