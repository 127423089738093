import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function CombinedLimbLeads(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/chest-plane-limb-leads.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Normal-EKG.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/lateral-leads.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/inferior-leads.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/right-sided-leads.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The combination of the augmented leads and the bipolar leads form the
        six limb leads, which intersect in a{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          flat plane on the patient's chest.
        </TextMark>
      </p>
      <p>The flat plane of the limb leads is called the frontal plane</p>
      <p>
        Remember that these limb leads all have a positive and negative
        direction, where the positive end measures cardiac depolarization
        towards it.
      </p>
      <p>
        You can visualize this as each standard limb lead representing a
        different viewpoint of the heart in regards to electrical activity.
      </p>
      <p>
        Each camera position represents the positive electrode of a standard
        limb lead. Each limb lead (I, II, III, aVR, aVL, and aVF) records from a
        different angle, or viewpoint, to provide a different view of the same
        cardiac activity.
      </p>
      <p>
        When you{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          record an EKG,
        </TextMark>{" "}
        you record the same cardiac electrical activity in each lead. But you'll
        notice that the waves look different in various leads because the
        heart's electrical activity is recorded from a different angle for each
        lead.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          Leads I and aVL
        </TextMark>{" "}
        are called the lateral leads (left lateral understood) because each has
        a positive electrode positioned laterally on the left arm.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          Leads II, III, and aVF
        </TextMark>{" "}
        are called the inferior leads because each of these leads has a positive
        electrode positioned inferiorly on the left foot.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          aVR
        </TextMark>{" "}
        is often an overlooked lead but is oriented to provide information about
        the right upper side of the heart, such as the right ventricle outflow
        tract and basal part of the septum.
      </p>
      <p>
        This grouping into lateral and inferior leads is critical to understand
        because it also allows you to group the leads into similarly named
        anatomical locations of the heart. For example, the inferior leads tell
        us information about the electrical activity of the inferior portion of
        the heart. In contrast, the lateral leads tell us about the heart's left
        side or lateral portion.
      </p>
    </ContentTemplate>
  );
}
