import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function ThirdDegreeBlock(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/third-degree-av-block.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/third-degree-av-block-junctional.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/third-degree-av-block-ventricular.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/third-degree-av-block-schematic.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          third-degree
        </TextMark>{" "}
        block is a complete block that prevents sinus-paced atrial
        depolarizations from reaching the ventricles. This results in a complete
        dissociation between the atria and ventricles. And so, the ventricles
        appear to depolarize independently from the atria. In other words, the
        length between QRS-QRS is always the same, and similarly, the length
        between P-P is always the same, but there is no relation between P waves
        and QRS complexes.
      </p>
      <p>
        This atrioventricular dissociation results from a{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          "complete" block
        </TextMark>{" "}
        that prevents atrial depolarization from reaching the ventricular
        conduction system. This absence in paced depolarizations from above the
        AV node leads to an automaticity focus below the complete block to pace
        the ventricles at its inherent rate.
      </p>
      <p>
        If a complete block is high in the AV Node, a Junctional focus (the
        fastest-pacing focus below the block) escapes to pace the ventricles at
        its inherent rate. If a complete block destroys the entire AV Node or is
        below the AV Node (for instance, in the Bundle of His), that leaves only
        ventricular foci to assume pacing responsibility, so a ventricular focus
        escapes to pace at its inherent rate.
      </p>
      <p>
        With a complete AV block, if the QRS complex appears normal in duration,
        we know that a{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          Junctional focus
        </TextMark>{" "}
        must be pacing the ventricles. This is also apparent when the
        ventricular rate, in the setting of a complete AV block, is between 40 -
        60 beats per minute. That's because this is the inherent rate a
        junctional focus will pace at.
      </p>
      <p>
        When we see large, wide, PVC-like complexes, we know that the ventricles
        are probably being paced by a{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          ventricular focus.
        </TextMark>{" "}
        We also see that the ventricular rate is within the inherent rate range
        (20 to 40 / min.) of a ventricular focus.
      </p>
    </ContentTemplate>
  );
}
