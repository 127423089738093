import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function HorizontalAxis(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/1-Horizontal-Axis_zrpDHb6.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/2-Horizontal-Axis.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/3-Horizontal-Axis.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/4-Horizontal-Axis.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The horizontal axis on this graph paper represents time and moves left
        to right.
      </p>
      <p>
        So timed events on EKG are measured left to right, and similarly,
        cardiac monitors display a time axis that reads from left to right.
      </p>
      <p>Here are some important times to remember:</p>
      <ul>
        <li>
          Between the heavy lines there are{" "}
          <TextMark
            activeMark={activeMark == 1 && true}
            onClick={() => clickTextMark(1)}>
            five small squares.
          </TextMark>
        </li>
        <li>
          The amount of time represented by the distance between two heavy lines
          is{" "}
          <TextMark
            activeMark={activeMark == 2 && true}
            onClick={() => clickTextMark(2)}>
            0.2 of a second.
          </TextMark>
        </li>
        <li>
          Each small division (measured horizontally between two fine lines)
          represents{" "}
          <TextMark
            activeMark={activeMark == 3 && true}
            onClick={() => clickTextMark(3)}>
            0.04 of a second.
          </TextMark>
        </li>
      </ul>
      <p>
        With this information, we can measure the duration of any part of a
        cardiac cycle.
      </p>
    </ContentTemplate>
  );
}
