import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function AtrialDepolarization(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/cardiac-conduction-sequence.gif",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/p-wave.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        As the SA node{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          emits an electrical stimulus,
        </TextMark>{" "}
        it spreads to the atria to depolarize other atrial myocytes, eventually
        leading to contraction.
      </p>
      <p>
        Atrial depolarization is recorded and visualized on the EKG as a{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          P wave.
        </TextMark>
      </p>
      <p>
        Depolarization from the atrial conduction system eventually propagates
        to the following site in the heart, carrying specialized cells used in
        electrical conduction - the AV node.
      </p>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
