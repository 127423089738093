import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function Amplitude(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/amplitude-all.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/amplitude-1.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/amplitude-2.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/amplitude-3.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/amplitude-4.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The amplitude of a wave is the magnitude, in millimeters, of upward
        deflection or downward deflection.
      </p>
      <p>The height or depth of a wave is a measure of voltage.</p>
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          first wave
        </TextMark>{" "}
        in the image has an upward deflection of 4 mm in amplitude. The{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          second wave
        </TextMark>{" "}
        has a downward deflection of 5 millimeters.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          Ten millimeters
        </TextMark>{" "}
        vertically represents one millivolt (mV).
      </p>
      <p>
        You can also measure the elevation or depression of{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          segments
        </TextMark>{" "}
        from the baseline. This deviation of segments from the baseline is also
        measured vertically in millimeters. (More on this later).
      </p>
    </ContentTemplate>
  );
}
