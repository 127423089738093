import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function AtrialFlutter(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Atrial-Flutter.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Atrial-Flutter-sawtooth.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Atrial-Flutter-revealed.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/Atrial-Flutter-3-to-1.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Atrial Flutter originates in an irritable atrial automaticity focus that
        fires impulses at a rate of{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          250 to 350 beats per minute,
        </TextMark>{" "}
        producing a rapid series of atrial depolarizations.
      </p>
      <p>
        On EKG, Atrial Flutter is characterized by consecutive, identical
        "flutter" waves in rapid back-to-back succession, sometimes described as
        having the appearance of the teeth of a saw or a{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          "saw-tooth" baseline.
        </TextMark>
      </p>
      {/* <p>[How is this different than PAT with AV block]</p> */}
      <p>
        The AV Node has a long refractory period, so only one in a series of
        flutter waves conducts to the ventricles. Therefore, this very rapid
        series of atrial depolarizations cannot drive the ventricles at the same
        excessive rate. The number of flutter waves to QRS complex is typically
        constant and can be used to describe the pattern of Atrial Flutter. For
        example,{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          3:1 Atrial Flutter
        </TextMark>{" "}
        means that for every 3 flutter waves, 1 QRS complex is conducted.
      </p>
      <p>
        With Atrial Flutter, there may be a rapid QRS response rate,
        particularly in 2:1 ratios (flutter waves: QRS response), masking the
        flutter waves. In these cases, it might be challenging to determine if
        your rhythm is an Atrial Flutter because of how fast the ventricular
        rate is. For example, in cases of SVT vs. atrial fibrillation w/ Rapid
        Ventricular Rate (RVR) vs. Atrial Flutter w/ RVR.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          Vagal maneuvers
        </TextMark>{" "}
        increase AV Node refractoriness, allowing fewer flutter waves to be
        conducted to the ventricles. This produces a longer series of flutter
        waves that are easier to identify.
      </p>
    </ContentTemplate>
  );
}
