import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function Electrodes(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/electodes-3-leads-female_8iWmUCS.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/cardiac-myocyte-depolarization-nolabel.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/positive-electrode.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The heart's electrical activity can be detected and recorded from the
        skin's surface using medical equipment such as the EKG and cardiac
        monitors. This is achieved by placing sensors on the skin called
        electrodes.
      </p>
      <p>
        When a wave of{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          depolarization
        </TextMark>{" "}
        moves through the heart and advances through the myocardium, remember
        that this is a movement of positive charges into the cell. As this
        electrical charge moves from cell to cell and moves towards a positive
        electrode, this can be recorded as a{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          positive, upward deflection on the EKG.
        </TextMark>
      </p>
      <p>
        In general, an upward deflection on the EKG represents depolarization
        moving toward an electrode and a downward deflection represents
        depolarization moving away from an electrode (more on this later).
      </p>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
