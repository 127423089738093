import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function InfarctionLBBB(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Normal-EKG.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        In Left Bundle Branch Block, the left ventricle (generally, the main
        chamber to suffer infarction) depolarizes after the right ventricle
        depolarizes. So any Q wave originating in the left ventricle could not
        appear at the beginning of the QRS complex, in the setting of a LBBB.
        Instead the Q wave, would fall somewhere in the middle of the QRS
        complex and in this instance, would be "buried" within the wide complex
        LBBB morphology and difficult to find on an EKG.
      </p>
      {/* Discuss Sgarboss Criteria */}
    </ContentTemplate>
  );
}
