import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function PaperSpeed(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/paper-speed.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/paper-speed-graph-paper.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Paper speed is the{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          paper output speed
        </TextMark>{" "}
        is the rate at which the EKG machine produces a trace. Some things to
        keep in mind are:
      </p>
      <ul>
        <li>The standard output is 25mm per second.</li>
        <li>The standard 12-lead ECG is a 10-second strip.</li>
      </ul>
      <p>
        Remember that the EKG is recorded on graph paper. The{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          divisions
        </TextMark>{" "}
        and spaces between the lines on the graph paper are also a measure of
        time:
      </p>
      <ul>
        <li>1 Small square = 0.04 seconds</li>
        <li>5 Small squares = 1 Large square = 0.2 seconds</li>
        <li>5 Large squares = 1 second</li>
      </ul>
    </ContentTemplate>
  );
}
