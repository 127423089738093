export const ContentMap = {
  data: [
    {
      module: "Basic Physiology",
      moduleParam: "physiology",
      sections: [
        {
          section: "Electrophysiology",
          sectionParam: "electrophysiology",
          chapters: [
            {
              id: 1001,
              chapter: "What is an Electrocardiogram?",
              chapterParam: "electrocardiogram",
              completed: false,
            },
            {
              id: 1002,
              chapter: "Depolarization",
              chapterParam: "depolarization",
              completed: false,
            },
            {
              id: 1003,
              chapter: "Electrodes",
              chapterParam: "electrodes",
              completed: false,
            },
          ],
        },
        {
          section: "Waves, Complexes and Intervals",
          sectionParam: "waves-complexes-intervals",
          chapters: [
            {
              id: 1004,
              chapter: "The Pacemaker",
              chapterParam: "pacemaker",
              completed: false,
            },
            {
              id: 1005,
              chapter: "Atrial Depolarization",
              chapterParam: "atrial-depolarization",
              completed: false,
            },
            {
              id: 1006,
              chapter: "AV Node Conduction",
              chapterParam: "av-node-conduction",
              completed: false,
            },
            {
              id: 1007,
              chapter: "Ventricular Depolarization",
              chapterParam: "ventricular-depolarization",
              completed: false,
            },
            {
              id: 1008,
              chapter: "Ventricular Repolarization",
              chapterParam: "ventricular-repolarization",
              completed: false,
            },
            {
              id: 1009,
              chapter: "The Cardiac Cycle (P-QRS-T)",
              chapterParam: "cardiac-cycle",
              completed: false,
            },
          ],
        },
      ],
    },
    {
      module: "Recording The EKG",
      moduleParam: "recording-the-ekg",
      sections: [
        {
          section: "The Graph Paper",
          sectionParam: "graph-paper",
          chapters: [
            {
              id: 1,
              chapter: "Divisions",
              chapterParam: "divisions",
              completed: false,
            },
            {
              id: 2,
              chapter: "Horizontal Axis and Time",
              chapterParam: "horizontal-axis",
              completed: false,
            },
          ],
        },
        {
          section: "Cardiac Electrical Recording",
          sectionParam: "cardiac-electrical-recording",
          chapters: [
            {
              id: 3,
              chapter: "Deflections",
              chapterParam: "deflections",
              completed: false,
            },
            {
              id: 4,
              chapter: "Amplitude and Voltage",
              chapterParam: "amplitude-voltage",
              completed: false,
            },
            {
              id: 5,
              chapter: "Electrodes and Leads",
              chapterParam: "electrodes-and-leads",
              completed: false,
            },
            {
              id: 6,
              chapter: "The Bipolar Leads",
              chapterParam: "bipolar-leads",
              completed: false,
            },
            {
              id: 7,
              chapter: "The Augmented Leads",
              chapterParam: "augmented-leads",
              completed: false,
            },
            {
              id: 8,
              chapter: "Combined Limb Leads",
              chapterParam: "combined-limb-leads",
              completed: false,
            },
            {
              id: 9,
              chapter: "The Chest Leads",
              chapterParam: "chest-leads",
              completed: false,
            },
            {
              id: 10,
              chapter: "The 12 Leads",
              chapterParam: "12-leads",
              completed: false,
            },
            {
              id: 11,
              chapter: "Modified Lead Placement",
              chapterParam: "modified-lead-placement",
              completed: false,
            },
          ],
        },
      ],
    },
    {
      module: "Rate",
      moduleParam: "rate",
      sections: [
        {
          section: "Sinus Rhythm",
          sectionParam: "sinus-rhythm",
          chapters: [
            {
              id: 12,
              chapter: "Normal Sinus Rate",
              chapterParam: "normal-sinus-rate",
              completed: false,
            },
            {
              id: 13,
              chapter: "Sinus Bradycardia",
              chapterParam: "sinus-bradycardia",
              completed: false,
            },
            {
              id: 14,
              chapter: "Sinus Tachycardia",
              chapterParam: "sinus-tachycardia",
              completed: false,
            },
          ],
        },
        {
          section: "Automaticity Foci",
          sectionParam: "automaticity-foci",
          chapters: [
            {
              id: 15,
              chapter: "Potential Pacemakers",
              chapterParam: "potential-pacemakers",
              completed: false,
            },
            {
              id: 16,
              chapter: "Levels Of Automaticity",
              chapterParam: "levels-of-automaticity",
              completed: false,
            },
            {
              id: 17,
              chapter: "Inherent Rate",
              chapterParam: "inherent-rate",
              completed: false,
            },
            {
              id: 18,
              chapter: "Overdrive Suppression",
              chapterParam: "overdrive-suppression",
              completed: false,
            },
            {
              id: 19,
              chapter: "Atrial Automaticity Foci",
              chapterParam: "atrial-automaticity-foci",
              completed: false,
            },
            {
              id: 20,
              chapter: "Junctional Automaticity Foci",
              chapterParam: "junctional-automaticity-foci",
              completed: false,
            },
            {
              id: 21,
              chapter: "Ventricular Automaticity Foci",
              chapterParam: "ventricular-automaticity-foci",
              completed: false,
            },
          ],
        },
        {
          section: "Determining Heart Rate",
          sectionParam: "determining-heart-rate",
          chapters: [
            {
              id: 22,
              chapter: "Paper Speed",
              chapterParam: "paper-speed",
              completed: false,
            },
            {
              id: 23,
              chapter: "Rate Theory",
              chapterParam: "rate-theory",
              completed: false,
            },
            {
              id: 24,
              chapter: "The Triplet Trick",
              chapterParam: "triplet-trick",
              completed: false,
            },
            {
              id: 25,
              chapter: "Practice",
              chapterParam: "practice",
              completed: false,
            },
            {
              id: 26,
              chapter: "Practice #2",
              chapterParam: "practice-2",
              completed: false,
            },
            {
              id: 27,
              chapter: "The Cardiac Strip",
              chapterParam: "cardiac-strip",
              completed: false,
            },
          ],
        },
      ],
    },
    {
      module: "Rhythm",
      moduleParam: "rhythm",
      sections: [
        {
          section: "The Conduction System",
          sectionParam: "conduction-system",
          chapters: [
            {
              id: 28,
              chapter: "What is an Arrhythmia?",
              chapterParam: "arrhythmias",
              completed: false,
            },
            {
              id: 29,
              chapter: "Normal Sinus Rhythm",
              chapterParam: "normal-sinus-rhythm",
              completed: false,
            },
            {
              id: 30,
              chapter: "Atrial Conduction",
              chapterParam: "atrial-conduction",
              completed: false,
            },
            {
              id: 31,
              chapter: "The AV Node",
              chapterParam: "av-node",
              completed: false,
            },
            {
              id: 32,
              chapter: "Ventricular Conduction",
              chapterParam: "ventricular-conduction",
              completed: false,
            },
            {
              id: 33,
              chapter: "Overdrive Suppression",
              chapterParam: "overdrive-suppression",
              completed: false,
            },
          ],
        },
        {
          section: "Irregular Arrhythmias",
          sectionParam: "irregular-arrhythmias",
          chapters: [
            {
              id: 34,
              chapter: "Introduction",
              chapterParam: "irregular-rhythms",
              completed: false,
            },
            {
              id: 35,
              chapter: "Wandering Atrial Pacemaker",
              chapterParam: "wandering-atrial-pacemaker",
              completed: false,
            },
            {
              id: 36,
              chapter: "Multifocal Atrial Tachycardia",
              chapterParam: "multifocal-atrial-tachycardia",
              completed: false,
            },
            {
              id: 37,
              chapter: "Atrial Fibrillation",
              chapterParam: "atrial-fibrillation",
              completed: false,
            },
            {
              id: 38,
              chapter: "Practice",
              chapterParam: "practice-1",
              completed: false,
            },
          ],
        },
        {
          section: "Escape Rhythms",
          sectionParam: "escape-rhythms",
          chapters: [
            {
              id: 39,
              chapter: "Introduction",
              chapterParam: "escape-rhythms",
              completed: false,
            },
            {
              id: 40,
              chapter: "Sinus Arrest & Block",
              chapterParam: "sinus-arrest-block",
              completed: false,
            },
            {
              id: 41,
              chapter: "Atrial Escape Rhythm",
              chapterParam: "atrial-escape-rhythm",
              completed: false,
            },
            {
              id: 42,
              chapter: "Junction Escape Rhythm",
              chapterParam: "junctional-escape-rhythm",
              completed: false,
            },
            {
              id: 43,
              chapter: "Ventricular Escape Rhythm",
              chapterParam: "ventricular-escape-rhythm",
              completed: false,
            },
          ],
        },
        {
          section: "Escape Beats",
          sectionParam: "escape-beats",
          chapters: [
            {
              id: 44,
              chapter: "Escape Beats",
              chapterParam: "escape-beats",
              completed: false,
            },
            {
              id: 45,
              chapter: "Atrial Escape Beat",
              chapterParam: "atrial-escape-beat",
              completed: false,
            },
            {
              id: 46,
              chapter: "Junctional Escape Beat",
              chapterParam: "junctional-escape-beat",
              completed: false,
            },
            {
              id: 47,
              chapter: "Ventricular Escape Beat",
              chapterParam: "ventricular-escape-beat",
              completed: false,
            },
          ],
        },
        {
          section: "Premature Beats",
          sectionParam: "premature-beats",
          chapters: [
            {
              id: 48,
              chapter: "Premature Beats",
              chapterParam: "premature-beats",
              completed: false,
            },
            {
              id: 49,
              chapter: "Premature Atrial Beats",
              chapterParam: "premature-atrial-beats",
              completed: false,
            },
            // {
            //   id: 50,
            //   chapter: "Practice",
            //   chapterParam: "practice-2",
            //   completed: false,
            // },
            {
              id: 51,
              chapter: "Premature Junctional Beats",
              chapterParam: "premature-junctional-beats",
              completed: false,
            },
            {
              id: 52,
              chapter: "Premature Ventricular Contraction",
              chapterParam: "premature-ventricular-contraction",
              completed: false,
            },
            {
              id: 53,
              chapter: "Practice",
              chapterParam: "practice-3",
              completed: false,
            },
          ],
        },
        {
          section: "Tachyarrhythmias",
          sectionParam: "tachyarrhythmias",
          chapters: [
            {
              id: 54,
              chapter: "Introduction",
              chapterParam: "tachyarrhythmias",
              completed: false,
            },
            {
              id: 55,
              chapter: "Paroxysmal Tachycardia",
              chapterParam: "paroxysmal-tachycardia",
              completed: false,
            },
            {
              id: 56,
              chapter: "Paroxysmal Atrial Tachycardia",
              chapterParam: "paroxysmal-atrial-tachycardia",
              completed: false,
            },
            {
              id: 57,
              chapter: "Paroxysmal Junctional Tachycardia",
              chapterParam: "paroxysmal-junctional-tachycardia",
              completed: false,
            },
            {
              id: 58,
              chapter: "Supraventricular Tachycardia",
              chapterParam: "supraventricular-tachycardia",
              completed: false,
            },
            {
              id: 59,
              chapter: "Paroxysmal Ventricular Tachycardia",
              chapterParam: "paroxysmal-ventricular-tachycardia",
              completed: false,
            },
            {
              id: 60,
              chapter: "Torsade de Pointes",
              chapterParam: "torsade-de-pointes",
              completed: false,
            },
            {
              id: 61,
              chapter: "Atrial Flutter",
              chapterParam: "atrial-flutter",
              completed: false,
            },
            {
              id: 62,
              chapter: "Ventricular Flutter",
              chapterParam: "ventricular-flutter",
              completed: false,
            },
            {
              id: 63,
              chapter: "Practice",
              chapterParam: "practice-4",
              completed: false,
            },
            {
              id: 64,
              chapter: "Fibrillation",
              chapterParam: "fibrillation",
              completed: false,
            },
            {
              id: 65,
              chapter: "Atrial Fibrillation",
              chapterParam: "atrial-fibrillation",
              completed: false,
            },
            {
              id: 66,
              chapter: "Ventricular Fibrillation",
              chapterParam: "ventricular-fibrillation",
              completed: false,
            },
          ],
        },
        {
          section: "Blocks",
          sectionParam: "blocks",
          chapters: [
            {
              id: 67,
              chapter: "What Is Heart Block?",
              chapterParam: "heart-block",
              completed: false,
            },
            {
              id: 68,
              chapter: "Sinus Block",
              chapterParam: "sinus-block",
              completed: false,
            },
            {
              id: 69,
              chapter: "Sick Sinus Syndrome",
              chapterParam: "sick-sinus-syndrome",
              completed: false,
            },
            {
              id: 70,
              chapter: "Atrio-Ventricular Blocks",
              chapterParam: "av-blocks",
              completed: false,
            },
            {
              id: 71,
              chapter: "First Degree AV Block",
              chapterParam: "first-degree-block",
              completed: false,
            },
            {
              id: 72,
              chapter: "Second Degree AV Block",
              chapterParam: "second-degree-block",
              completed: false,
            },
            {
              id: 73,
              chapter: "Practice",
              chapterParam: "practice-5",
              completed: false,
            },
            {
              id: 74,
              chapter: "Third Degree AV Block",
              chapterParam: "third-degree-block",
              completed: false,
            },
            {
              id: 75,
              chapter: "Bundle Branch Blocks",
              chapterParam: "bundle-branch-blocks",
              completed: false,
            },
            {
              id: 76,
              chapter: "Right Bundle Branch Block",
              chapterParam: "right-bundle-branch-block",
              completed: false,
            },
            {
              id: 77,
              chapter: "Left Bundle Branch Block",
              chapterParam: "left-bundle-branch-block",
              completed: false,
            },
          ],
        },
        // {
        //   section: "Other Arrhythmias",
        //   sectionParam: "other-arrhythmias",
        //   chapters: [
        //     {
        //       id: 78,
        //       chapter: "Wolff-Parkinson-White",
        //       chapterParam: "wolff-parkinson-white",
        //       completed: false,
        //     },
        //     {
        //       id: 79,
        //       chapter: "Lown-Ganong-Levine Syndrome",
        //       chapterParam: "lown-ganong-levine",
        //       completed: false,
        //     },
        //   ],
        // },
      ],
    },
    {
      module: "Axis",
      moduleParam: "axis",
      sections: [
        {
          section: "The Mean QRS Vector",
          sectionParam: "mean-qrs-vector",
          chapters: [
            {
              id: 80,
              chapter: "Vector of Depolarization",
              chapterParam: "vector-of-depolarization",
              completed: false,
            },
            {
              id: 81,
              chapter: "Vector Displacement",
              chapterParam: "vector-displacement",
              completed: false,
            },
          ],
        },
        {
          section: "Determining Axis",
          sectionParam: "determining-axis",
          chapters: [
            {
              id: 82,
              chapter: "Left Axis, Right Axis",
              chapterParam: "left-right-axis",
              completed: false,
            },
            {
              id: 83,
              chapter: "Up-Down",
              chapterParam: "up-down",
              completed: false,
            },
            {
              id: 84,
              chapter: "Normal Axis",
              chapterParam: "normal-axis",
              completed: false,
            },
            {
              id: 85,
              chapter: "Deviations From Normal",
              chapterParam: "deviations-from-normal",
              completed: false,
            },
            {
              id: 86,
              chapter: "Left Axis Deviation",
              chapterParam: "left-axis-deviation",
              completed: false,
            },
            {
              id: 87,
              chapter: "Right Axis Deviation",
              chapterParam: "right-axis-deviation",
              completed: false,
            },
            {
              id: 88,
              chapter: "Degree of Axis Deviation",
              chapterParam: "degree-of-axis-deviation",
              completed: false,
            },
            // {
            //   id: 89,
            //   chapter: "Practice",
            //   chapterParam: "practice",
            //   completed: false,
            // },
            {
              id: 90,
              chapter: "Atrial Axis",
              chapterParam: "atrial-axis",
              completed: false,
            },
            {
              id: 91,
              chapter: "Horizontal Plane",
              chapterParam: "horizontal-plane",
              completed: false,
            },
            {
              id: 92,
              chapter: "Axis Rotation",
              chapterParam: "axis-rotation",
              completed: false,
            },
          ],
        },
      ],
    },
    {
      module: "Hypertrophy",
      moduleParam: "hypertrophy",
      sections: [
        {
          section: "Atrial Hypertrophy",
          sectionParam: "atrial-hypertrophy",
          chapters: [
            {
              id: 93,
              chapter: "What is Hypertrophy?",
              chapterParam: "what-is-hypertrophy",
              completed: false,
            },
            {
              id: 94,
              chapter: "Atrial Hypertrophy",
              chapterParam: "atrial-hypertrophy",
              completed: false,
            },
            {
              id: 95,
              chapter: "Right Atrial Enlargement",
              chapterParam: "right-atrial-enlargement",
              completed: false,
            },
            {
              id: 96,
              chapter: "Left Atrial Enlargement",
              chapterParam: "left-atrial-enlargement",
              completed: false,
            },
          ],
        },
        {
          section: "Ventricular Hypertrophy",
          sectionParam: "ventricular-hypertrophy",
          chapters: [
            {
              id: 97,
              chapter: "Ventricular Hypertrophy",
              chapterParam: "ventricular-hypertrophy",
              completed: false,
            },
            {
              id: 98,
              chapter: "Right Ventricular Hypertrophy",
              chapterParam: "right-ventricular-hypertrophy",
              completed: false,
            },
            {
              id: 99,
              chapter: "Left Ventricular Hypertrophy",
              chapterParam: "left-ventricular-hypertrophy",
              completed: false,
            },
            {
              id: 100,
              chapter: "Strain Patterns",
              chapterParam: "strain-patterns",
              completed: false,
            },
            {
              id: 101,
              chapter: "Summary",
              chapterParam: "summary",
              completed: false,
            },
          ],
        },
      ],
    },
    {
      module: "Infarction",
      moduleParam: "infarction",
      sections: [
        {
          section: "Pathophysiology",
          sectionParam: "pathophysiology",
          chapters: [
            {
              id: 102,
              chapter: "What is an infarction?",
              chapterParam: "what-is-an-infarction",
              completed: false,
            },
            {
              id: 103,
              chapter: "Coronary Arteries",
              chapterParam: "coronary-arteries",
              completed: false,
            },
          ],
        },
        {
          section: "The Infarction Triad",
          sectionParam: "infarction-triad",
          chapters: [
            {
              id: 104,
              chapter: "Introduction",
              chapterParam: "introduction",
              completed: false,
            },
            {
              id: 105,
              chapter: "Ischemia",
              chapterParam: "ischemia",
              completed: false,
            },
            {
              id: 106,
              chapter: "Injury",
              chapterParam: "injury",
              completed: false,
            },
            {
              id: 107,
              chapter: "Necrosis",
              chapterParam: "necrosis",
              completed: false,
            },
            {
              id: 108,
              chapter: "Identifying Infarcts",
              chapterParam: "identifying-infarcts",
              completed: false,
            },
          ],
        },
        {
          section: "Localizing Infarctions",
          sectionParam: "localizing-infarctions",
          chapters: [
            {
              id: 109,
              chapter: "Introduction",
              chapterParam: "introduction",
              completed: false,
            },
            {
              id: 110,
              chapter: "Anterior Infarction",
              chapterParam: "anterior-infarction",
              completed: false,
            },
            {
              id: 111,
              chapter: "Lateral Infarction",
              chapterParam: "lateral-infarction",
              completed: false,
            },
            {
              id: 112,
              chapter: "Inferior Infarction",
              chapterParam: "inferior-infarction",
              completed: false,
            },
            {
              id: 113,
              chapter: "Posterior Infarction",
              chapterParam: "posterior-infarction",
              completed: false,
            },
            // {
            //   id: 114,
            //   chapter: "Infarction with LBBB",
            //   chapterParam: "infarction-with-lbbb",
            //   completed: false,
            // },
            {
              id: 115,
              chapter: "Infarction Localization Summary",
              chapterParam: "infarction-localization-summary",
              completed: false,
            },
          ],
        },
      ],
    },
  ],
};
