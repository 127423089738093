import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function WanderingAtrialPacemaker(props) {
  const contextValue = useContext(StageContext);
  const { setActiveMark, setActiveMarkData, activeMark, clickTextMark } =
    contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Wandering-Atrial-Pacemaker-rhythm-strip.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Wandering-Atrial-Pacemaker-wandering-pacemakers.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/Wandering-Atrial-Pacemaker-cycle-variation.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/WAP-p-wave-morphology.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/WAP-P-prime_y5Miala.png",
    },
    {
      id: 5,
      img: "https://medzcool-images.s3.amazonaws.com/Wandering-Atrial-Pacemaker.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Wandering Pacemaker is an irregular rhythm produced by the pacemaker
        activity{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          wandering
        </TextMark>{" "}
        from the SA Node to nearby atrial automaticity foci. This produces{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          variations
        </TextMark>{" "}
        between each cardiac cycle seen on an EKG as an irregular rhythm. There
        is also variation in the shape of the{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          p-wave,
        </TextMark>{" "}
        reflecting the multiple atrial foci contributing to this arrhythmia.
        However, the overall rate is normal (&lt;100 bpm).
      </p>
      <p>
        The "p-waves" produced in these cases are not true p-waves as they do
        not originate from the SA node. So we denote them as{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          P' waves
        </TextMark>{" "}
        (pronounced "P prime"). These P' waves represent atrial depolarization
        from an automaticity focus, as opposed to normal Sinus-paced P waves
        that you see in Normal Sinus Rhythm. Each of these automaticity foci has
        a specific inherent rate at which it paces. In a given lead, each atrial
        automaticity focus produces a unique morphological shape related to the
        anatomical location of that focus within the atria.
      </p>
      <p>
        These{" "}
        <TextMark
          activeMark={activeMark == 5 && true}
          onClick={() => clickTextMark(5)}>
          wandering pacemakers
        </TextMark>{" "}
        lead to an irregular rhythm as the pacemaking activity wanders from the
        SA node to other atrial foci. So the cycle length varies, and P' wave
        morphology varies as the pacemaking center moves.
      </p>
      <p>
        When the heart rate increases greater than 100 beats per minute, this
        arrhythmia becomes a Multifocal Atrial tachycaria.
      </p>
    </ContentTemplate>
  );
}
