import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function OverdriveSuppression(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/overdrive-suppression_HEknBh2.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        There are three levels of automaticity foci (atrial, Junctional, and
        ventricular) that can provide backup pacemaker responsibility if pacing
        activity fails. The foci of each level have a characteristic inherent
        rate range, giving the SA Node a failsafe hierarchy of three levels of
        backup pacing - this is the general concept of overdrive suppression.
      </p>
      <p>
        Overdrive suppression allows the automaticity center with the fastest
        rate to be the dominant pacemaker. For example, if the SA node fails to
        pace the heart, an automaticity focus from the next highest level
        escapes overdrive suppression to pace the heart at its inherent rate.
        This new focus of automaticity then becomes the dominant pacemaker by
        overdrive-suppressing all automaticity foci below it.
      </p>
      <p>
        An automaticity focus is overdrive-suppressed if it is regularly
        depolarized by a pacing rate faster than its inherent pacing rate. But
        if an automaticity focus is not overdrive-suppressed, it escapes,
        initiating its own pacemaking activity.
      </p>
    </ContentTemplate>
  );
}
