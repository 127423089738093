// Styles
import styled from "styled-components";

export default function ClinicPromoContent(props) {
  return (
    <NoMatchContainer>
      <h1>404</h1>
      <p>This page does not exist</p>
    </NoMatchContainer>
  );
}

const NoMatchContainer = styled.div`
  margin: auto;
  padding: 15px;
  margin-top: 50px;
  max-width: 800px;
  text-align: center;
  h1 {
    margin-bottom: 5px;
  }
  p {
    margin-top: 5px;
  }
`;
