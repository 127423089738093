import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function CardiacStrip(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/cardiac-strip-rate.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        On the top margin of every EKG strip, small marks identify the
        "three-second" intervals. Within two of these marks is a three-second
        interval.
      </p>
      <p>
        Remember that the three-second marker may differ in shape or symbol
        between different machines; it may be a circle, triangle, line, etc.
      </p>
      <p>
        Taking two of the three-second intervals, we have a 6-second strip. This
        6-second strip represents the amount of paper used by the machine in six
        seconds (one-tenth of a minute).
      </p>
      <p>
        Count the number of complete (R wave to R wave) cycles in this 6-second
        strip.
      </p>
      <p>
        Find the rate by multiplying the number of cycles in the 6-second strip
        by ten (10).
      </p>
      <p>Ten of the 6-second strips equals one minute (time) of EKG tracing.</p>

      {/* <strong>Practice</strong>
      <p>What is the heart rate for each of these strips?</p>
      <p>No. 1: 20 per minute</p>
      <p>No. 2: about 45 per minute</p>
      <p>No. 3: 50 per minute</p> */}
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
