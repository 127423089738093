import React, { Fragment } from "react";
// DEPENDENCIES
// COMPONENTS
import Navigator from "./Navigation/Navigator";
// STYLES
import styled from "styled-components";
import { fontFamily } from "@bit/medzcool.cytoplasm.typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-light-svg-icons";

export default function ChecklistSummary(props) {
  return (
    <Fragment>
      <Navigator />
      <ChecklistContainer>
        <div className="sub-heading">
          <h1>EKG Checklist</h1>
          <p>A checklist to guide you through the components of EKG analysis</p>
        </div>
        <div className="body">
          <section>
            <h2>Rate</h2>
            <ul>
              <li>Calculate a heart rate</li>
              <li>
                Determine if the EKG has a normal rate, is bradycardic or
                tachycardic
              </li>
            </ul>
          </section>

          <section>
            <h2>Rhythm</h2>
            <ul>
              <li>Identify a basic rhythm</li>
              <ul className="inner-checklist">
                <li>Check for P Waves, QRS Complexes, and T waves</li>
                <li>Check Interval Duration</li>
                <ul>
                  <li>PR Interval</li>
                  <li>QRS Interval</li>
                  <li>QT Interval</li>
                </ul>
              </ul>
              <li>Scan for premature beats, irregularity, abnormal waves</li>
            </ul>
          </section>

          <section>
            <h2>Axis</h2>
            <ul>
              <li>Determine Axis Deviation</li>
              <ul className="inner-checklist">
                <li>Is QRS positive or negative in leads I and aVF?</li>
              </ul>
              <li>
                Determine Axis Rotation
                <ul className="inner-checklist">
                  <li>Find the isoelectric QRS in the precordial leads</li>
                </ul>
              </li>
            </ul>
          </section>

          <section>
            <h2>Hypertrophy</h2>
            <ul>
              <li>Look at V1 for:</li>
              <ul className="inner-checklist">
                <li>P waves suggesting Atrial Enlargement</li>
                <li>R wave suggesting Right Ventricular Hypertrophy</li>
              </ul>
              <li>
                Left Ventricular Hypertrophy:
                <ul className="inner-checklist">
                  <li>S wave in V1 + R wave in V5 &gt; 35mm</li>
                </ul>
              </li>
            </ul>
          </section>

          <section>
            <h2>Infarction</h2>
            <ul>
              <li>Look in all leads for:</li>
              <ul className="inner-checklist">
                <li>Q waves - (Necrosis)</li>
                <li>T wave Inversions - (Ischemia)</li>
                <li>
                  ST Elevations or Depressions - (acute or recent infarction)
                </li>
              </ul>
              <li>Determine the anatomical distribution of infarction</li>
            </ul>
          </section>
        </div>
      </ChecklistContainer>
    </Fragment>
  );
}
const ChecklistContainer = styled.div`
  line-height: 130%;
  padding: 10px;
  font-family: ${fontFamily.sansSerif};
  max-width: 800px;
  margin: auto;
  margin-bottom: 100px;
  box-sizing: border-box;
  font-size: 1.2em;
  padding-top: 75px;
  .sub-heading {
    background: lightgray;
    padding: 15px 25px;
    /* color: ; */
  }
  .body {
    padding: 15px;
  }
  h2 {
    font-size: 1.3em;
  }
  hr {
    color: lightgray;
  }
  section {
    margin-top: 50px;
    margin-bottom: 50px;
    ul {
      margin-bottom: 15px;
      padding-left: 25px;
      /* list-style-type: none; */
      li {
        line-height: 175%;
      }
      /* li:before {
        content: "\f0c8";
        color: gray;
        margin-left: -25px;
        padding-right: 10px;
      } */
    }
  }
  .checkbox-container {
    color: gray;
    margin-right: 15px;
  }
  .inner-checklist {
    color: gray;
    .checkbox-container {
      color: lightgray;
    }
  }
  @media (max-width: 450px) {
    font-size: 1em;
  }
`;
