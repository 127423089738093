import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function VectorDisplacement(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/vector-displacement.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/vector-displacement-hypertrophy.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/vector-displacement-infarction.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The heart's axis can be displaced from its normal position. For example,
        if the heart is rotated toward the patient's right side, then the mean
        QRS Vector moves towards the right as well. In obese people, the
        diaphragm is pushed up along with the heart, so the mean QRS vector can
        deviate towards the person's left. Regardless of the cause of direction
        of the deviation, the tail of this vector is always located at the AV
        node, and the sum of all individual vectors determines the direction of
        the mean QRS vector.
      </p>
      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        Hypertrophy
      </strong>
      <p>
        A{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          hypertrophied (enlarged)
        </TextMark>{" "}
        ventricle has more (and larger) vectors, which draw the Mean QRS Vector
        in that direction. As a result, with hypertrophy of one ventricle, there
        is increased depolarization activity of the hypertrophied side,
        displacing the Mean QRS Vector towards the hypertrophied side.
      </p>
      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        Infarction
      </strong>
      <p>
        During myocardial{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          infarctions,
        </TextMark>{" "}
        cardiac tissue becomes "electrically dead" and cannot depolarize and,
        therefore, does not contribute to the overall depolarization vector.
        Since there is no depolarization (and no vectors) in the infarcted area,
        the vectors of the unaffected areas of the heart are unopposed, so the
        Mean QRS Vector tends to point away from the infarct.
      </p>
      <p>
        In summary, the Mean QRS Vector information gives us valuable
        information about the position of the heart and insight into ventricular
        hypertrophy, and helpful information in the setting of infarction.
      </p>
    </ContentTemplate>
  );
}
