import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function SinusArrestBlock(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Sinus-Arrest.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Sinus-Block.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/Sinus-Arrest-Block.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Sinus Arrest
        </TextMark>{" "}
        occurs when the SA Node ceases pacemaking completely. This allows an
        automaticity focus, with the next fastest inherent pacing rate, to take
        over to become the heart's pacemaker. And since this new pacemaker has
        the fastest inherent rate, it overdrive-suppresses all other
        automaticity foci below to become the dominant pacemaker.
      </p>
      <p>
        With a Sinus Arrest, the SA Node ceases pacing, so an automaticity focus
        escapes producing an Escape Rhythm.
      </p>
      <p>
        However with{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          Sinus Block,
        </TextMark>{" "}
        the SA Node misses one pacing cycle, producing only a transient pause.
        And just before the activation of an automaticity focus, the SA node
        resumes its pacing activity, thereby overdriving suppressing slower
        automaticity foci.
      </p>
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          difference between
        </TextMark>{" "}
        the sinus arrest and sinus block is thought to occur within the cells of
        the SA node. Where the failure of the SA node to produce an electrical
        impulse results in sinus arrest, and failure of the SA node to transmit
        an electrical impulse results in sinus block. These differences can be
        seen on an EKG or rhythm strip by noticing an escape beat that is not
        sinus after the pause in sinus arrest. And in sinus block, you'll see SA
        node resuming as evident in the normal P-QRS-T complex following the
        pause.
      </p>
    </ContentTemplate>
  );
}
