import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function WhatIsInfarction(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/What-is-an-Infarction-cannot.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/What-is-an-Infarction-plaque-progression.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      {/* <p>
        [picture of heart with coronary arteries and arrow pointing to a
        narrowing at a specific point resulting in downstream infarct]
      </p> */}
      <p>
        Myocardial infarction (MI) is the{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          death
        </TextMark>{" "}
        of cardiac cells, resulting in their inability to depolarize and
        contract, leading to deadly arrhythmias.
      </p>
      <p>
        This occurs when an oxygenated blood supply is cut-off to the
        myocardium. Although the heart's chambers are filled with blood, the
        myocardium's own blood supply is provided exclusively by the coronary
        arteries.
      </p>
      <p>
        These coronary arteries can be gradually narrowed by{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          lipid deposits
        </TextMark>{" "}
        that become atherosclerotic plaques. These plaques can rupture,
        triggering thrombus formation and the already narrowed coronary artery,
        plus this new thrombus can result in complete occlusion, depriving the
        myocardium of a blood supply. As a result, the affected area becomes
        necrotic and can no longer depolarize or contract, limiting cardiac
        output or even generating fatal ventricular arrhythmias.
      </p>
      <p>
        Thankfully, myocardial infarctions can be diagnosed on an EKG. Not only
        can we diagnose an MI on an EKG, but the electrocardiogram can also
        provide information into which coronary artery or branch is likely
        occluded. Furthermore, the EKG can provide information on various heart
        blocks or arrhythmias that had resulted from the infarction and, if
        diagnosed early enough, can lead to life-saving interventions.
      </p>
    </ContentTemplate>
  );
}
