import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function SecondDegreeBlock(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/second-degree-AV-Block-Intro.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/second-degree-AV-Block-type-1.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/second-degree-AV-Block-type-1-labeled.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/second-degree-AV-Block-type-2.png",
    },
    {
      id: 4,
      img:
        "https://medzcool-images.s3.amazonaws.com/second-degree-AV-Block-type-2-labeled.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Second-degree AV blocks allow some atrial depolarizations (P waves) to
        conduct to the ventricles, while some atrial depolarizations are
        blocked, leaving lone P waves without an associated QRS complex.
      </p>
      <p>
        In second-degree heart blocks, the relationship between P:QRS complexes
        varies, where some P-waves are not followed by QRS complexes. This leads
        to the appearance of a “dropped QRS complex” representing a
        non-conducted P-wave. This ratio of how many P-waves you see for each
        QRS complex often occurs at predictable ratios (2:1, 3:2, 4:3, etc.).
      </p>
      <p>
        There are also two types of second-degree heart blocks, depending on the
        underlying pathology - Mobitz type I (Wenckebach) and Mobtiz type II.
        The difference between Mobitz type I and type II second-degree heart
        blocks on an EKG is determined by the variations in the PR interval.
      </p>
      <strong>Type I - Wenckebach</strong>
      <p>
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          Mobitz type I
        </TextMark>{" "}
        second-degree heart blocks are often normal variants and can be present
        on an EKG in patients without structural heart disease. Some causes
        include an increased vagal tone or medication-induced (beta-blocks,
        calcium channel blocks, etc.). On an EKG, you will see a{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          stepwise prolongation
        </TextMark>{" "}
        of the PR interval leading to the eventual, non-conducted atrial action
        potential, or “dropped” QRS complex, after which normal sinus rhythm
        seems to resume.
      </p>
      <strong>Type II - Mobitz</strong>
      <p>
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          Mobitz type II
        </TextMark>{" "}
        second-degree heart blocks are rarely a normal finding on the EKG and
        often suggest underlying structural abnormalities that can progress to
        third-degree heart block. On an EKG, the{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          PR interval remains constant,
        </TextMark>{" "}
        without variations to its length prior to a “dropped” QRS complex. This
        is then followed by a short pause, then normal sinus rhythm resumes, and
        this pattern of non-conducted atrial action potentials repeats itself.
      </p>
      {/* <p>
        2° blocks of the AV Node are "Wenckebach" (formerly called "Type I").
        They produce a series of cycles with progressive blocking of AV Node
        conduction until the final P wave is totally blocked in the AV Node,
        eliminating the QRS response. Each repeating Wenckebach series has a
        consistent P:QRS ratio like 3:2, 4:3, 5:4, etc. (one less QRS than P
        waves in the series).
      </p>
      <p>
        2° blocks of Purkinje fiber bundles (His Bundle or Bundle Branches)
        are"Mobitz" (formerly called "Type II"). They usually produce a series
        of cycles consisting of one normal P-QRS-T cycle preceded by a series of
        paced P waves that fail to conduct through the AV Node (no QRS
        response). Each repeating Mobitz series has a consistent P:QRS ratio,
        like 3: 1, 4: 1, 5: 1, etc.
      </p> */}
    </ContentTemplate>
  );
}
