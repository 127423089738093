import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import ClickReveal from "../../../Styles/ClickReveal";
import styled from "styled-components";

export default function Practice(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/rate-practice-tracing.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>How fast is the heart rate?</p>
      <div>
        <ClickReveal>
          <p>The rate in this tracing is about 60 per minute.</p>
        </ClickReveal>
      </div>

      <p>Where is this rhythm originating from?</p>
      <ClickReveal>
        <p>
          This is an EKG tracing from a resting patient whose heart rate is
          slightly slower than the usual rate one would see with a Sinus Rhythm.
          If you were told that this rhythm probably originated in an
          automaticity focus, by the rate alone, you would suspect the origin
          (pacemaker) to be in the AV junction.
        </p>
        <p>
          You can confirm this because of the lack of P waves on this rhythm
          strip. This patient's SA Node has failed, along with all the atrial
          automaticity foci. As a result, a junctional automaticity focus takes
          over as pacemaker next.
        </p>
      </ClickReveal>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
