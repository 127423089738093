import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function AVNode(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/AV-NODE.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/PR-segment.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/automaticity-foci-junctional.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Atrio-Ventricular (AV) node
        </TextMark>{" "}
        is a specialized conduction area within the heart named after its
        position between the Atria and Ventricles, hence the name
        Atrio-Ventricular node or AV node for short.
      </p>
      <p>
        When atrial depolarization eventually reaches the AV Node from the
        atria, conduction of depolarization slows within the AV Node. This
        slowing of electricity through the heart's conduction system is recorded
        as a{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          pause on the EKG.
        </TextMark>{" "}
        This pause is represented by the horizontal piece of baseline between
        the P wave and the QRS complex.
      </p>
      <p>
        This slowing of depolarization moving from the SA node to the atria and
        eventually to the ventricles is an inherent feature of the AV node. This
        slowing allows enough time for blood to move from the atria into the
        ventricles before ventricular depolarization and contraction. Without
        this delay, the atria would be inefficiently empty blood into the
        ventricles.
      </p>
      <p>
        Another feature of the AV node is its ability to function as a{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          backup pacemaker.
        </TextMark>{" "}
        The proximal end of the AV Node has no automaticity foci. However, the
        remainder of the AV Node, an area known as the AV Junction, does have
        automaticity foci. These foci are essential for backup pacing should
        there be a total failure of all pacemaking activity from above (SA Node
        as well as atrial foci), or if a complete conduction block of the
        proximal end of the AV node occurs, preventing all (SA node or atrial
        foci) pacing stimuli from being conducted to the ventricles.
      </p>
      <p></p>
    </ContentTemplate>
  );
}
