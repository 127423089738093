import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function TwelveLeads(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Normal-EKG.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/limb-leads-12-lead.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/precordial-leads-12-lead.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        On the standard EKG tracing, there are six precordial (chest) leads and
        six limb leads. This is the 12 lead electrocardiogram.
      </p>
      <p>
        The six{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          limb leads
        </TextMark>{" "}
        all lie in the frontal plane, which can be visualized on the patient's
        chest as leads I, II, II, aVL, aVR, aVF.
      </p>
      <p>
        The six{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          precordial leads
        </TextMark>{" "}
        lie in the horizontal plane and are arranged in progressive order from
        V1 to V6.
      </p>
    </ContentTemplate>
  );
}
