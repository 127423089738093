// Dependencies
import { Route, Switch } from "react-router-dom";

// Components
import Arrhythmias from "./Chapters/1-Arrhythmias";
import NormalSinusRhythm from "./Chapters/2-NormalSinusRhythm";
import AtrialConduction from "./Chapters/3-AtrialConduction";
import AVNode from "./Chapters/4-AVNode";
import VentricularConduction from "./Chapters/5-VentricularConduction";
import OverdriveSuppression from "./Chapters/6-OverdriveSuppression";
import IrregularRhythms from "./Chapters/7-IrregularRhythms";
import WanderingAtrialPacemaker from "./Chapters/8-WanderingAtrialPacemaker";
import MultifocalAtrialTachycardia from "./Chapters/9-MultifocalAtrialTachycardia";
import AtrialFibrillation from "./Chapters/10-AtrialFibrillation";
import Practice1 from "./Chapters/11-Practice1";
import EscapeRhythms from "./Chapters/12-EscapeRhythms";
import SinusArrestBlock from "./Chapters/13-SinusArrestBlock";
import AtrialEscapeRhythm from "./Chapters/14-AtrialEscapeRhythm";
import JunctionalEscapeRhythm from "./Chapters/15-JunctionalEscapeRhythm";
import VentricularEscapeRhythm from "./Chapters/16-VentricularEscapeRhythm";
import EscapeBeats from "./Chapters/17-EscapeBeats";
import AtrialEscapeBeat from "./Chapters/18-AtrialEscapeBeat";
import JunctionalEscapeBeat from "./Chapters/19-JunctionalEscapeBeat";
import VentricularEscapeBeat from "./Chapters/20-VentricularEscapeBeat";
import PrematureBeats from "./Chapters/21-PrematureBeats";
import PrematureAtrialBeats from "./Chapters/22-PrematureAtrialBeats";
import AtrialBigeminyTrigeminy from "./Chapters/23-AtrialBigeminyTrigeminy";
import Practice2 from "./Chapters/24-Practice2";
import PrematureJunctionalBeats from "./Chapters/25-PrematureJunctionalBeats";
import PrematureVentricularContraction from "./Chapters/26-PrematureVentricularContraction";
import Practice3 from "./Chapters/27-Practice3";
import Tachyarrhythmias from "./Chapters/28-Tachyarrhythmias";
import ParoxysmalTachycardia from "./Chapters/29-ParoxysmalTachycardia";
import ParoxysmalAtrialTachycardia from "./Chapters/30-ParoxysmalAtrialTachycardia";
import ParoxysmalJunctionalTachycardia from "./Chapters/31-ParoxysmalJunctionalTachycardia";
import SupraventricularTachycardia from "./Chapters/32-SupraventricularTachycardia";
import ParoxysmalVentricularTachycardia from "./Chapters/33-ParoxysmalVentricularTachycardia";
import TorsadedePointes from "./Chapters/34-TorsadedePointes";
import AtrialFlutter from "./Chapters/35-AtrialFlutter";
import VentricularFlutter from "./Chapters/36-VentricularFlutter";
import Practice4 from "./Chapters/37-Practice4";
import Fibrillation from "./Chapters/38-Fibrillation";
import AtrialFibrillation2 from "./Chapters/39-AtrialFibrillation";
import VentricularFibrillation from "./Chapters/40-VentricularFibrillation";
import HeartBlock from "./Chapters/41-HeartBlock";
import SinusBlock from "./Chapters/42-SinusBlock";
import SickSinusSyndrome from "./Chapters/43-SickSinusSyndrome";
import AVBlocks from "./Chapters/44-AVBlocks";
import FirstDegreeBlock from "./Chapters/45-FirstDegreeBlock";
import SecondDegreeBlock from "./Chapters/46-SecondDegreeBlock";
import Practice5 from "./Chapters/47-Practice5";
import ThirdDegreeBlock from "./Chapters/48-ThirdDegreeBlock";
import BundleBranchBlocks from "./Chapters/49-BundleBranchBlocks";
import RBBB from "./Chapters/50-RBBB";
import LBBB from "./Chapters/51-LBBB";
import WPW from "./Chapters/52-WPW";
import LGL from "./Chapters/53-LGL";

// Styles
export default function RhythmModuleA(props) {
  return (
    <Switch>
      <Route
        path="/module/rhythm/conduction-system/arrhythmias"
        render={(props) => <Arrhythmias {...props} />}
      />
      <Route
        path="/module/rhythm/conduction-system/normal-sinus-rhythm"
        render={(props) => <NormalSinusRhythm {...props} />}
      />
      <Route
        path="/module/rhythm/conduction-system/atrial-conduction"
        render={(props) => <AtrialConduction {...props} />}
      />
      <Route
        path="/module/rhythm/conduction-system/av-node"
        render={(props) => <AVNode {...props} />}
      />
      <Route
        path="/module/rhythm/conduction-system/ventricular-conduction"
        render={(props) => <VentricularConduction {...props} />}
      />
      <Route
        path="/module/rhythm/conduction-system/overdrive-suppression"
        render={(props) => <OverdriveSuppression {...props} />}
      />
      <Route
        path="/module/rhythm/irregular-arrhythmias/irregular-rhythms"
        render={(props) => <IrregularRhythms {...props} />}
      />
      <Route
        path="/module/rhythm/irregular-arrhythmias/wandering-atrial-pacemaker"
        render={(props) => <WanderingAtrialPacemaker {...props} />}
      />
      <Route
        path="/module/rhythm/irregular-arrhythmias/multifocal-atrial-tachycardia"
        render={(props) => <MultifocalAtrialTachycardia {...props} />}
      />
      <Route
        path="/module/rhythm/irregular-arrhythmias/atrial-fibrillation"
        render={(props) => <AtrialFibrillation {...props} />}
      />
      <Route
        path="/module/rhythm/irregular-arrhythmias/practice-1"
        render={(props) => <Practice1 {...props} />}
      />
      <Route
        path="/module/rhythm/escape-rhythms/escape-rhythms"
        render={(props) => <EscapeRhythms {...props} />}
      />
      <Route
        path="/module/rhythm/escape-rhythms/sinus-arrest-block"
        render={(props) => <SinusArrestBlock {...props} />}
      />
      <Route
        path="/module/rhythm/escape-rhythms/atrial-escape-rhythm"
        render={(props) => <AtrialEscapeRhythm {...props} />}
      />
      <Route
        path="/module/rhythm/escape-rhythms/junctional-escape-rhythm"
        render={(props) => <JunctionalEscapeRhythm {...props} />}
      />
      <Route
        path="/module/rhythm/escape-rhythms/ventricular-escape-rhythm"
        render={(props) => <VentricularEscapeRhythm {...props} />}
      />
      <Route
        path="/module/rhythm/escape-beats/escape-beats"
        render={(props) => <EscapeBeats {...props} />}
      />
      <Route
        path="/module/rhythm/escape-beats/atrial-escape-beat"
        render={(props) => <AtrialEscapeBeat {...props} />}
      />
      <Route
        path="/module/rhythm/escape-beats/junctional-escape-beat"
        render={(props) => <JunctionalEscapeBeat {...props} />}
      />
      <Route
        path="/module/rhythm/escape-beats/ventricular-escape-beat"
        render={(props) => <VentricularEscapeBeat {...props} />}
      />
      <Route
        path="/module/rhythm/premature-beats/premature-beats"
        render={(props) => <PrematureBeats {...props} />}
      />
      <Route
        path="/module/rhythm/premature-beats/premature-atrial-beats"
        render={(props) => <PrematureAtrialBeats {...props} />}
      />
      <Route
        path="/module/rhythm/premature-beats/atrial-bigeminy-trigeminy"
        render={(props) => <AtrialBigeminyTrigeminy {...props} />}
      />
      <Route
        path="/module/rhythm/premature-beats/practice-2"
        render={(props) => <Practice2 {...props} />}
      />
      <Route
        path="/module/rhythm/premature-beats/premature-junctional-beats"
        render={(props) => <PrematureJunctionalBeats {...props} />}
      />
      <Route
        path="/module/rhythm/premature-beats/premature-ventricular-contraction"
        render={(props) => <PrematureVentricularContraction {...props} />}
      />
      <Route
        path="/module/rhythm/premature-beats/practice-3"
        render={(props) => <Practice3 {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/tachyarrhythmias"
        render={(props) => <Tachyarrhythmias {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/paroxysmal-tachycardia"
        render={(props) => <ParoxysmalTachycardia {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/paroxysmal-atrial-tachycardia"
        render={(props) => <ParoxysmalAtrialTachycardia {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/paroxysmal-junctional-tachycardia"
        render={(props) => <ParoxysmalJunctionalTachycardia {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/supraventricular-tachycardia"
        render={(props) => <SupraventricularTachycardia {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/paroxysmal-ventricular-tachycardia"
        render={(props) => <ParoxysmalVentricularTachycardia {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/torsade-de-pointes"
        render={(props) => <TorsadedePointes {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/atrial-flutter"
        render={(props) => <AtrialFlutter {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/ventricular-flutter"
        render={(props) => <VentricularFlutter {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/practice-4"
        render={(props) => <Practice4 {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/fibrillation"
        render={(props) => <Fibrillation {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/atrial-fibrillation"
        render={(props) => <AtrialFibrillation2 {...props} />}
      />
      <Route
        path="/module/rhythm/tachyarrhythmias/ventricular-fibrillation"
        render={(props) => <VentricularFibrillation {...props} />}
      />
      <Route
        path="/module/rhythm/blocks/heart-block"
        render={(props) => <HeartBlock {...props} />}
      />
      <Route
        path="/module/rhythm/blocks/sinus-block"
        render={(props) => <SinusBlock {...props} />}
      />
      <Route
        path="/module/rhythm/blocks/sick-sinus-syndrome"
        render={(props) => <SickSinusSyndrome {...props} />}
      />
      <Route
        path="/module/rhythm/blocks/av-blocks"
        render={(props) => <AVBlocks {...props} />}
      />
      <Route
        path="/module/rhythm/blocks/first-degree-block"
        render={(props) => <FirstDegreeBlock {...props} />}
      />
      <Route
        path="/module/rhythm/blocks/second-degree-block"
        render={(props) => <SecondDegreeBlock {...props} />}
      />
      <Route
        path="/module/rhythm/blocks/practice-5"
        render={(props) => <Practice5 {...props} />}
      />
      <Route
        path="/module/rhythm/blocks/third-degree-block"
        render={(props) => <ThirdDegreeBlock {...props} />}
      />
      <Route
        path="/module/rhythm/blocks/bundle-branch-blocks"
        render={(props) => <BundleBranchBlocks {...props} />}
      />
      <Route
        path="/module/rhythm/blocks/right-bundle-branch-block"
        render={(props) => <RBBB {...props} />}
      />
      <Route
        path="/module/rhythm/blocks/left-bundle-branch-block"
        render={(props) => <LBBB {...props} />}
      />
      <Route
        path="/module/rhythm/other-arrhythmias/wolff-parkinson-white"
        render={(props) => <WPW {...props} />}
      />
      <Route
        path="/module/rhythm/other-arrhythmias/lown-ganong-levine"
        render={(props) => <LGL {...props} />}
      />
    </Switch>
  );
}
