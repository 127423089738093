import { useEffect, useState } from "react";
// DEPENDENCIES

// CONTENT COMPONENTS

// LAYOUT COMPONENTS
import ModuleStage from "./Stage/ModuleStage";
import Footer from "./Navigation/ModuleFooter";
import ModuleHeader from "./Navigation/ModuleHeader";
import { ContentMap } from "../Content/ContentMap";

// STYLES
import styled from "styled-components";

// Apollo, GraphQL
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";

const GET_CONTENTMAP = gql`
  query getContentmap($userId: Int!, $course: String!) {
    getContentmap(userId: $userId, course: $course) {
      id
      user {
        id
      }
      data
      course
    }
  }
`;

const CREATE_CONTENTMAP = gql`
  mutation CreateContentmap($userId: Int!, $course: String!, $data: String!) {
    createContentmap(userId: $userId, course: $course, data: $data) {
      contentmap {
        id
        data
        course
      }
    }
  }
`;

const CREATE_LOCATION = gql`
  mutation CreateLocation(
    $userId: Int!
    $course: String!
    $data: String!
    $module: String
    $section: String
    $chapter: String
  ) {
    createLocation(
      userId: $userId
      course: $course
      data: $data
      module: $module
      section: $section
      chapter: $chapter
    ) {
      location {
        id
        data
        course
        module
        section
        chapter
      }
    }
  }
`;

export default function Module(props) {
  const { user, history } = props;

  const moduleId = props.match.params.module;
  const sectionId = props.match.params.section;
  const chapterId = props.match.params.chapter;

  // MODULE
  const currentModule = ContentMap.data.find(
    (module) => module.moduleParam == moduleId
  );
  const currentModuleIndex = ContentMap.data
    .map((e) => e.moduleParam)
    .indexOf(moduleId);

  // SECTION
  const currentSection = currentModule.sections.find(
    (section) => section.sectionParam == sectionId
  );
  const currentSectionIndex = currentModule.sections
    .map((e) => e.sectionParam)
    .indexOf(sectionId);

  // CHAPTER
  const currentChapterIndex = currentSection.chapters
    .map((e) => e.chapterParam)
    .indexOf(chapterId);

  // MODULE NAVIGATION
  const nextChapterIndex = currentChapterIndex + 1;
  const backChapterIndex = currentChapterIndex - 1;
  function Navigate(action) {
    if (action === "next") {
      const nextChapter = currentSection.chapters[currentChapterIndex + 1];

      if (nextChapter) {
        history.push(
          `/module/${moduleId}/${sectionId}/${nextChapter.chapterParam}`
        );
      }

      if (!nextChapter) {
        let nextSection = currentModule.sections[currentSectionIndex + 1];

        if (nextSection) {
          history.push(
            `/module/${moduleId}/${nextSection.sectionParam}/${nextSection.chapters[0].chapterParam}`
          );
        }

        // If not next Section, advance to next module.
        if (!nextSection) {
          const nextModule = ContentMap.data[currentModuleIndex + 1];

          if (nextModule) {
            let nextSection = nextModule.sections[0];
            history.push(
              `/module/${nextModule.moduleParam}/${nextSection.sectionParam}/${nextSection.chapters[0].chapterParam}`
            );
          }
        }
      }

      // If no next Chapter, advance to next Section
      //NEXT CHAPTER
    }
    if (action === "back") {
      const backChapter = currentSection.chapters[currentChapterIndex - 1];
      if (backChapter) {
        history.push(
          `/module/${moduleId}/${sectionId}/${backChapter.chapterParam}`
        );
      }

      if (!backChapter) {
        let backSection = currentModule.sections[currentSectionIndex - 1];

        if (backSection) {
          history.push(
            `/module/${moduleId}/${backSection.sectionParam}/${
              backSection.chapters[backSection.chapters.length - 1].chapterParam
            }`
          );
        }

        if (!backSection) {
          const backModule = ContentMap.data[currentModuleIndex - 1];

          if (backModule) {
            let backSection =
              backModule.sections[backModule.sections.length - 1];
            history.push(
              `/module/${backModule.moduleParam}/${backSection.sectionParam}/${
                backSection.chapters[backSection.chapters.length - 1]
                  .chapterParam
              }`
            );
          }
        }
      }
    }
  }

  // FOOTER NAVIGATION
  const [activeStageMenu, setActiveStageMenu] = useState(false);
  useEffect(() => {
    setActiveStageMenu(false);
  }, [props.location.pathname]);

  const moduleData = {
    moduleTitle: ContentMap.data[currentModuleIndex],
    sectionTitle:
      ContentMap.data[currentModuleIndex].sections[currentSectionIndex],
    chapterTitle:
      ContentMap.data[currentModuleIndex].sections[currentSectionIndex]
        .chapters[currentChapterIndex],
  };

  function UpdateContentMap(contentMapData) {
    const existingContentMap = JSON.parse(contentMapData);

    new Promise((resolve, reject) => {
      let userFlatChapterArray = [];
      const flatArray = existingContentMap.map((module) => {
        module.sections.map((section) => {
          section.chapters.map((chapter) => {
            userFlatChapterArray.push(chapter);
            return chapter;
          });
        });
      });
      resolve(userFlatChapterArray);
    })
      .then((userFlatChapterArray) => {
        const comparedContentMap = ContentMap.data.map((module) => {
          const newSections = module.sections.map((section) => {
            const newChapters = section.chapters.map((chapter) => {
              const moduleTitle = module.module;
              const sectionTitle = section.section;

              const match = userFlatChapterArray.find(
                (flatChapter) => flatChapter.id == chapter.id
              );

              if (match && match.completed == true) {
                chapter = { ...chapter, completed: true };
              }
              return chapter;
            });
            section = {
              ...section,
              chapters: newChapters,
            };
            return section;
          });
          module = {
            ...module,
            sections: newSections,
          };
          return module;
        });

        return comparedContentMap;
      })
      .then((comparedContentMap) => {
        const comparedContentMap2 = comparedContentMap.map((module) => {
          const newSections = module.sections.map((section) => {
            const newChapters = section.chapters.map((chapter) => {
              if (chapter.id === moduleData.chapterTitle.id) {
                chapter = { ...chapter, completed: true };
              }
              return chapter;
            });
            section = {
              ...section,
              chapters: newChapters,
            };
            return section;
          });
          module = {
            ...module,
            sections: newSections,
          };
          return module;
        });

        return comparedContentMap2;
      })
      .then((comparedContentMap2) => {
        createContentmap({
          variables: {
            userId: user.id,
            course: "kardiograph",
            data: JSON.stringify(comparedContentMap2),
          },
        });
        setContentMap(comparedContentMap2);
      });
  }

  const [createContentmap] = useMutation(CREATE_CONTENTMAP);

  const [
    createLocation,
    { data: locationData, loading: loadingLocation, error: locationError },
  ] = useMutation(CREATE_LOCATION);

  const [
    getContentmap,
    { loading: contentLoading, error: contentError, data: contentData },
  ] = useLazyQuery(GET_CONTENTMAP, {
    onCompleted: (data) => {
      UpdateContentMap(data.getContentmap.data);
    },
  });

  const [contentMap, setContentMap] = useState();
  useEffect(() => {
    getContentmap({
      variables: {
        userId: user.id,
        course: "kardiograph",
      },
    });

    createLocation({
      variables: {
        userId: user.id,
        course: "kardiograph",
        module: moduleData.moduleTitle.module,
        section: moduleData.sectionTitle.section,
        chapter: moduleData.chapterTitle.chapter,
        data: props.match.url,
      },
    });

    if (contentData) {
      UpdateContentMap(contentData.getContentmap.data);
    }
  }, [moduleData.chapterTitle]);

  return (
    <ModuleContainer>
      <div className="module-inner-container">
        <div className="module-wrapper">
          <ModuleHeader />
          <ModuleStage
            {...props}
            moduleData={moduleData}
            contentMap={contentMap}
            setActiveStageMenu={setActiveStageMenu}
            activeStageMenu={activeStageMenu}
          />
          <Footer
            {...props}
            Navigate={Navigate}
            setActiveStageMenu={setActiveStageMenu}
            activeStageMenu={activeStageMenu}
          />
        </div>
      </div>
    </ModuleContainer>
  );
}
const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  min-height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  .module-inner-container {
    box-sizing: border-box;
  }
  .module-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  @media (max-width: 430px) {
    background: #f3f3f3;
    .module-wrapper {
      height: auto;
    }
  }
`;
