import styled from "styled-components";
import { grayscale, red } from "@bit/medzcool.cytoplasm.colors";

export const TextMark = styled.span`
  cursor: pointer;
  background-color: ${grayscale.gray};
  padding: 0 3px;
  border-radius: 3px;
  color: ${(props) => (props.activeMark ? `white` : `${grayscale.darkDark}`)};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px;
  white-space: nowrap;
  box-shadow: ${(props) =>
    props.activeMark ? `none` : `rgba(0, 0, 0, 0.1) 0px 3px 4px`};
  background-color: ${(props) =>
    props.activeMark ? `${red.bright}` : `${grayscale.gray}`};
  &:hover {
    background-color: ${(props) =>
      props.activeMark ? `${red.dark}` : `${grayscale.lightDark}`};
    box-shadow: ${(props) =>
      props.activeMark ? `none` : `rgba(0, 0, 0, 0.1) 0px 1px 1px`};
  }
`;

export const FootnoteContainer = styled.ol`
  font-size: 12px;
  line-height: 130%;
  padding-left: 15px;
  margin-top: 35px;
`;
export const Footnote = styled.li``;
