import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function VectorDepolarization(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/axis-intro.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/axis-intro-fine-vectors.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/axis-intro-mean-vector_9rC8eKW.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Axis refers to the direction of the movement of depolarization, which
        spreads throughout the heart to stimulate the myocardium to contract. We
        can demonstrate the general direction of the movement of depolarization
        by using a vector. This vector shows the direction in which
        depolarization is moving through the heart. We often refer to the axis
        of the heart as simply the mean QRS vector.
      </p>
      <p>
        Remember that the QRS complex represents the depolarization of the
        ventricular myocardium.
      </p>
      <p>
        We can illustrate ventricular depolarization in finer detail using{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          small vectors
        </TextMark>{" "}
        to demonstrate ventricular depolarization, which begins at the
        endocardium that lines both ventricles and proceeds toward the outside
        surface (epicardium) in all areas at once.
      </p>
      <p>
        If we add up all the small vectors of ventricular depolarization
        (considering both direction and magnitude), we have one large{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          "Mean QRS Vector"
        </TextMark>{" "}
        that represents the general direction of ventricular depolarization,
        with the tail of the vector always being positioned at the AV node.
      </p>
      <p>
        Because the small depolarization vectors of the thicker left ventricle
        are larger, the Mean QRS Vector points more toward the left.
      </p>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
