import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function EscapeBeats(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Escape-Beats-introduction.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Escape-Beats-introduction-1.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Escape-Beats-introduction-2.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/Escape-Beats-introduction-3.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        With a transient Sinus Block, an unhealthy SA Node{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          fails to conduct,
        </TextMark>{" "}
        and as a result, the heart misses one cardiac cycle. On an EKG, this
        would appear as a{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          pause in electrical activity.
        </TextMark>{" "}
        If this pause is long enough, an automaticity focus from below the SA
        node will{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          escape
        </TextMark>{" "}
        overdrive suppression and begin to depolarize the heart at its inherent
        rate.
      </p>
      <p>
        If the{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          SA node resumes
        </TextMark>{" "}
        after only one missed cycle, then the SA node will overdrive suppress
        all other automaticity foci below it, and NSR resumes. In this case, the
        ectopic automaticity focus only had the opportunity to produce a single
        escape beat, which was nested between two sinus depolarization cycles.
      </p>
    </ContentTemplate>
  );
}
