import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function ChestLeads(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/precordial-lead-placement.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/horizontal-plane_1O2M8Ia.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/lead-2-orientation.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/precordial-lead-progression.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/V1-V2.png",
    },
    {
      id: 5,
      img: "https://medzcool-images.s3.amazonaws.com/V5-V6_zwRM75I.png",
    },
    {
      id: 6,
      img: "https://medzcool-images.s3.amazonaws.com/V3-V4.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        There are six chest leads,{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          V1 through V6.
        </TextMark>{" "}
        Each of the chest leads, also known as precordial leads, intersect
        approximately through the AV node of the heart and lie on a{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          horizontal plane
        </TextMark>{" "}
        which cuts the body into top and bottom. Generally, the precordial leads
        are oriented so that the person's back is negative and their front is
        positive.
      </p>
      <p>
        To help orient yourself further,{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          V2
        </TextMark>{" "}
        is positioned on a line that is directed from the front to the back of
        the person. The V2 electrode is placed on the person's front, which is
        positive, and the back is considered negative.
      </p>
      <p>
        When looking at the{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          precordial leads on an EKG,
        </TextMark>{" "}
        from V1 to V6, you will see gradual changes in the waves of the cardiac
        recording in each lead. This change in the wave's morphology correlates
        with the changes in the precordial lead placement on the body.
      </p>
      <p>
        For example, notice that the V1 precordial lead has a mainly negative
        QRS complex, while the deflection of the QRS complex in V5 and V6 are
        mainly positive. This is because the wave of depolarization that occurs
        from the SA node down to the ventricles generally follows a vector of
        electricity pointing towards the left side of the chest, which is where
        the positive electrodes of V5 and V6 lie.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          Leads V1 and V2
        </TextMark>{" "}
        are sometimes referred to as right-sided precordial leads. That's
        because leads V1 and V2 are oriented over the right side of the heart.
      </p>
      <p>
        Conversely,{" "}
        <TextMark
          activeMark={activeMark == 5 && true}
          onClick={() => clickTextMark(5)}>
          V5 and V6
        </TextMark>{" "}
        are oriented over the left side of the heart, sometimes referred to as
        left-sided or lateral, precordial leads. Remember that a depolarization
        wave moving toward a positive precordial electrode causes an upward
        (positive) deflection on the EKG, as you see here in the lateral leads.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 6 && true}
          onClick={() => clickTextMark(6)}>
          Leads V3 and V4
        </TextMark>{" "}
        are generally oriented over the interventricular septum. V3 is closer to
        the right ventricle, and V4 is nearer the left ventricle.
      </p>
    </ContentTemplate>
  );
}
