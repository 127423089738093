import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function PrematureJunctionalBeats(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-beat-junctional_LAv83oH.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/pjb-1.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-beat-junctional-p-after.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/junctional-bigeminy.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/junctional-trigeminy.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Premature Junctional Beat
        </TextMark>{" "}
        (PJB) occurs when an irritable automaticity focus in the AV Junction
        suddenly fires a premature stimulus that is conducted to and depolarizes
        the ventricles (and sometimes the atria, in retrograde fashion).
      </p>
      <p>
        After heart tissue depolarizes, it immediately repolarizes, and during
        repolarization, that tissue is refractory to another stimulus. As the
        ventricles repolarize, one Bundle Branch may repolarize slower than the
        other. So the premature depolarization from a PJB may conduct through
        one Bundle Branch, but the impulse is temporarily delayed in the other,
        still refractory, Bundle Branch (usually the Right). So, instead of
        depolarizing simultaneously, one ventricle depolarizes immediately, and
        the other is delayed, producing a slightly widened QRS complex typical
        of a Premature Junctional Beat with aberrant ventricular conduction.
      </p>
      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        RETROGRADE ATRIAL DEPOLARIZATION IN PREMATURE JUNCTIONAL BEATS
      </strong>
      <p>
        Since atrial and ventricular depolarization move in opposite directions
        from the Junctional focus, the premature P' wave is{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          inverted.
        </TextMark>
      </p>
      <p>
        Sometimes an inverted wave associated with a PJB{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          follows the QRS.
        </TextMark>{" "}
        Occasionally the P' disappears within the QRS when atrial and
        ventricular depolarization occur simultaneously (not shown in
        illustration).
      </p>
      <p>
        Retrograde atrial depolarization from a PJB usually depolarizes the SA
        Node as well. So the SA Node pacing is reset in step with the retrograde
        atrial depolarization.
      </p>
      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        Junctional Bigeminy and Trigeminy
      </strong>
      <p>
        An irritable Junctional automaticity focus may fire a premature stimulus
        coupled to the end of each normal sinus cycle to produce{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          Junctional bigeminy.
        </TextMark>
      </p>
      <p>
        An irritable Junctional focus may fire a stimulus after two consecutive,
        normal Sinus-generated cycles. A repeating series of these couplets is{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          Junctional trigeminy.
        </TextMark>
      </p>
      <p>
        Don't forget that on an EKG, you may see an inverted (retrograde) P'
        wave (arrows in upper tracing) with every PJB in either Junctional
        Bigeminy or Junctional Trigeminy. Also, the SA Node will reset its
        pacing with each retrograde atrial depolarization; this can produce
        alarming (but innocent) gaps of empty baseline between couplets.
      </p>
    </ContentTemplate>
  );
}
