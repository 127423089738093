// Dependencies
import { Route, Switch } from "react-router-dom";

// Components
import NormalSinusRhythm from "./Chapters/1-NormalSinusRhythm";
import SinusBradycardia from "./Chapters/2-SinusBradycardia";
import SinusTachycardia from "./Chapters/3-SinusTachycardia";
import PotentialPacemakers from "./Chapters/4-PotentialPacemakers";
import LevelsOfAutomaticity from "./Chapters/5-LevelsOfAutomaticity";
import InherentRate from "./Chapters/6-InherentRate";
import OverdriveSuppression from "./Chapters/7-OverdriveSuppression";
import AtrialAutomaticityFoci from "./Chapters/8-AtrialAutomaticityFoci";
import JunctionalAutomaticityFoci from "./Chapters/9-JunctionalAutomaticityFoci";
import VentricularAutomaticityFoci from "./Chapters/10-VentricularAutomaticityFoci";
import PaperSpeed from "./Chapters/11-PaperSpeed";
import RateTheory from "./Chapters/12-RateTheory";
import TripletTrick from "./Chapters/13-TripletTrick";
import Practice from "./Chapters/14-Practice";
import PracticeRate from "./Chapters/15-PracticeRate";
import CardiacStrip from "./Chapters/16-CardiacStrip";

// Styles
export default function RateModule(props) {
  return (
    <Switch>
      <Route
        path="/module/rate/sinus-rhythm/normal-sinus-rate"
        render={(props) => <NormalSinusRhythm {...props} />}
      />
      <Route
        path="/module/rate/sinus-rhythm/sinus-bradycardia"
        render={(props) => <SinusBradycardia {...props} />}
      />
      <Route
        path="/module/rate/sinus-rhythm/sinus-tachycardia"
        render={(props) => <SinusTachycardia {...props} />}
      />
      <Route
        path="/module/rate/automaticity-foci/potential-pacemakers"
        render={(props) => <PotentialPacemakers {...props} />}
      />
      <Route
        path="/module/rate/automaticity-foci/levels-of-automaticity"
        render={(props) => <LevelsOfAutomaticity {...props} />}
      />
      <Route
        path="/module/rate/automaticity-foci/inherent-rate"
        render={(props) => <InherentRate {...props} />}
      />
      <Route
        path="/module/rate/automaticity-foci/overdrive-suppression"
        render={(props) => <OverdriveSuppression {...props} />}
      />
      <Route
        path="/module/rate/automaticity-foci/atrial-automaticity-foci"
        render={(props) => <AtrialAutomaticityFoci {...props} />}
      />
      <Route
        path="/module/rate/automaticity-foci/junctional-automaticity-foci"
        render={(props) => <JunctionalAutomaticityFoci {...props} />}
      />
      <Route
        path="/module/rate/automaticity-foci/ventricular-automaticity-foci"
        render={(props) => <VentricularAutomaticityFoci {...props} />}
      />
      <Route
        path="/module/rate/determining-heart-rate/paper-speed"
        render={(props) => <PaperSpeed {...props} />}
      />
      <Route
        path="/module/rate/determining-heart-rate/rate-theory"
        render={(props) => <RateTheory {...props} />}
      />
      <Route
        path="/module/rate/determining-heart-rate/triplet-trick"
        render={(props) => <TripletTrick {...props} />}
      />
      <Route
        path="/module/rate/determining-heart-rate/practice"
        render={(props) => <Practice {...props} />}
      />
      <Route
        path="/module/rate/determining-heart-rate/practice-2"
        render={(props) => <PracticeRate {...props} />}
      />
      <Route
        path="/module/rate/determining-heart-rate/cardiac-strip"
        render={(props) => <CardiacStrip {...props} />}
      />
    </Switch>
  );
}
