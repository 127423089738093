import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function AtrialEscapeBeat(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Atrial-Escape-0.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Atrial-Escape-1.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A transient Sinus Block can{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          prevent the SA node
        </TextMark>{" "}
        from discharging one pacemaking stimulus, producing a pause in cardiac
        electrical activity. This pause is enough to remove the overdrive
        suppression of an atrial automaticity focus, and the{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          atrial focus escapes
        </TextMark>{" "}
        to emit a single stimulus. This is an Atrial Escape Beat, seen here on
        the EKG as a short pause, followed by a P' wave (which differs in
        morphology from a sinus P wave). This atrial escape beat can propagate
        to the AV node and down to the ventricles resulting in a
        normal-appearing QRS complex.
      </p>
      <p>
        When the SA Node quickly resumes pacing, the atrial focus is
        overdrive-suppressed again and normal sinus rhythm resumes.
      </p>
    </ContentTemplate>
  );
}
