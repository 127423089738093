import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function Summary(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/hypertrophy-summary.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        Lead V1 provides most of the information concerning hypertrophy of the
        heart's chambers. So when assessing for hypertrophy, first check lead
        V1.
      </p>
      <p>
        Assess V1 for evidence of a biphasic P-wave, which is suggestive of
        atrial hypertrophy/enlargement.
      </p>
      <p>
        Prominent R waves in V1 suggest Right Ventricular Hypertrophy, where an
        exaggerated S wave in V1 and R wave in V5 can suggest Left Ventricular
        Hypertrophy.
      </p>
    </ContentTemplate>
  );
}
