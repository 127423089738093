import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function DeviationsFromNormal(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Deviations-from-normal.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Deviations-from-normal-left.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Deviations-from-normal-right.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/Deviations-from-normal-normal.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        In the frontal plane, we can determine if there is any axis deviation
        from normal. This is how you should visualize the four-axis quadrants in
        a large circle (AV Node is the center) drawn on the patient's chest in
        the frontal plane.
      </p>
      <p>
        If the Vector points upward (from the AV Node) and to the patient's
        left, this is{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          Left Axis Deviation.
        </TextMark>
      </p>
      <p>
        If the Vector points to the patient's right side, this is{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          Right Axis Deviation.
        </TextMark>
      </p>
      <p>
        And if the Vector points downward to the patient's left, it is in the{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          normal range.
        </TextMark>
      </p>
    </ContentTemplate>
  );
}
