import { mixpanelToken } from "../settings";

// Dependencies
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";

//Components
// Styles
import styled from "styled-components";
import { fontFamily, fontWeight } from "@bit/medzcool.cytoplasm.typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/pro-regular-svg-icons";

export default function ModuleHeader(props) {
  return (
    <Header>
      <div
        className="header-item"
        onClick={() => {
          mixpanel.track("Navigation", {
            Action: "Syllabus",
            Location: "Homepage",
            App: "Kardiograph",
          });
        }}>
        <Link to="/">SYLLABUS</Link>
      </div>
      <div
        className="header-item"
        onClick={() => {
          mixpanel.track("Navigation", {
            Action: "Checklist",
            Location: "Homepage",
            App: "Kardiograph",
          });
        }}>
        <Link to="/checklist">CHECKLIST</Link>
      </div>
      <div
        className="header-item exit"
        onClick={() => {
          mixpanel.track("Navigation", {
            Action: "Exit",
            Location: "Homepage",
            App: "Kardiograph",
          });
          window.location.href = "https://medzcool.com";
        }}>
        <FontAwesomeIcon icon={faSignOut} size="lg" />
      </div>
    </Header>
  );
}

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 14;
  background-color: rgb(21, 20, 31);
  font-family: ${fontFamily.sansSerif};
  display: grid;
  align-items: center;
  padding: 0 25px;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: min-content min-content auto;
  box-sizing: border-box;
  .header-item {
    display: inline-block;
    a {
      text-decoration: none;
      color: white;
    }
  }
  .exit {
    cursor: pointer;
    color: white;
    justify-self: right;
  }
  span {
    margin-right: 15px;
  }
  @media (max-width: 430px) {
    padding: 0 15px;
    font-size: 14px;
  }
`;
