import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function PotentialPacemakers(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/automaticity-foci.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        There are focal areas of automaticity in the heart known as automaticity
        foci. They are potential pacemakers that are capable of pacing in
        emergency situations.
      </p>
      <p>
        Under normal circumstances, these foci are electrically silent (that's
        why they are referred to as "potential" pacemakers).
      </p>
      <p>
        If the SA Node ceases to function, one of the potential pacemakers,
        known as an automaticity focus (also called "ectopic" foci), will assume
        pacemaking activity at its inherent rate.
      </p>
    </ContentTemplate>
  );
}
