import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function AtrialBigeminyTrigeminy(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/12-leads.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A premature beat (premature stimulus) originates in an irritable
        automaticity focus that fires spontaneously, producing a beat (on EKG we
        see evidence of a depolarization) earlier than expected in the rhythm.
      </p>
      <p>
        If an atrial or Junctional automaticity focus become irritable it may
        fire a spontaneous impulse that depolarizes the surrounding tissue, so
        we can recognize it on EKG as a premature beat.
      </p>
      <p>
        These irritable foci can be isolated and occur only once, after which
        the SA node takes over and resumes normal sinus rhythm. But these
        irritable foci can also produce a series of rapid impulses which
        overdrive-suppress all other automaticity foci, becoming the dominant
        pacemaker.
      </p>
      <p>
        These irritable foci are often the result of sympathetic stimulation,
        which can be medication or drug induced, or the result of increased
        endogenous stimulation, like in cases of hyperthyroidism or increased
        production of epinephrine from the adrenal glands.
      </p>
    </ContentTemplate>
  );
}
