import React from "react";
import { mixpanelToken } from "../../settings";
// Dependencies
import mixpanel from "mixpanel-browser";
//Components
// Styles
import styled from "styled-components";
import { colors } from "../../Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faList } from "@fortawesome/pro-regular-svg-icons";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";

export default function ModuleFooter(props) {
  const { Navigate, setActiveStageMenu, activeStageMenu } = props;

  function NavAction(action) {
    mixpanel.track("Navigation", {
      Action: action,
      Location: "Module Stage Footer",
      App: "Kardiograph",
    });
    Navigate(action);
  }

  return (
    <BottomNavContainer>
      <div className="nav-button-container-desktop">
        <button className="back" onClick={() => NavAction("back")}>
          Back
        </button>
        <button className="next" onClick={() => NavAction("next")}>
          Next
        </button>
      </div>

      <div className="nav-button-container-mobile">
        <div
          className="mobile-footer-btn"
          onClick={() => {
            window.scrollTo(0, 0);
            props.history.push("/");
          }}>
          <FontAwesomeIcon icon={faHome} size="lg" />
        </div>
        <div
          className="mobile-footer-btn"
          onClick={() => setActiveStageMenu(!activeStageMenu)}>
          <FontAwesomeIcon icon={faList} size="lg" />
        </div>
        <div className="mobile-footer-btn arrow">
          <FontAwesomeIcon
            icon={faArrowLeft}
            size="lg"
            onClick={() => Navigate("back")}
          />
        </div>
        <div
          className="mobile-footer-btn arrow"
          onClick={() => Navigate("next")}>
          <FontAwesomeIcon icon={faArrowRight} size="lg" />
        </div>
      </div>
    </BottomNavContainer>
  );
}

const BottomNavContainer = styled.footer`
  box-sizing: border-box;
  background-color: lightgray;
  width: 100%;
  padding: 10px;
  .nav-button-container-desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    float: right;
    button {
      border: none;
      padding: 15px 25px;
      border-radius: 5px;
      cursor: pointer;
      &.back {
        background-color: transparent;
        padding: 10px 25px;
        border-radius: 5px;
        color: ${colors.red.base};
        border: 4px solid ${colors.red.base};
        font-weight: 700;
        &:hover {
          border-color: red;
          color: red;
        }
      }
      &.next {
        background-color: ${colors.red.base};
        padding: 15px 25px;
        border-radius: 5px;
        color: white;
        font-weight: 700;
        &:hover {
          background-color: red;
        }
      }
    }
  }
  .nav-button-container-mobile {
    display: none;
  }
  @media (max-width: 430px) {
    position: fixed;
    bottom: 0;
    padding-top: 0px;
    padding-bottom: 25px;
    border-top: 1px solid #999;
    .nav-button-container-desktop {
      display: none;
    }
    .nav-button-container-mobile {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 15px;
      justify-items: center;
      .mobile-footer-btn {
        cursor: pointer;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
        color: #848484;
        &.arrow {
          color: ${colors.red.base};
        }
      }
    }
  }
`;
