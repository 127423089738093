import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function Practice(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Normal-EKG.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        A young lady has a Mean QRS Vector in the normal range. If the QRS in
        lead III is isoelectric, then she has an electrical axis of +30°
      </p>
      <p>
        Consider a patient with R.A.D. You find that the QRS is isoelectric in
        lead II, so the Axis is +150°
      </p>
    </ContentTemplate>
  );
}
