import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function VentricularHypertrophy(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Ventricular-Hypertrophy-intro.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        The QRS complex represents ventricular depolarization, so this will be
        where we will see findings reflecting the presence of ventricular
        hypertrophy.
      </p>
      <p>
        Let's focus on V1 again, but this time the QRS complex. Usually, the S
        wave is much larger than the R wave in this lead. In other words, the
        QRS complex is mainly negative.
      </p>
      <p>
        Remember that the V1 electrode is positive. Ventricular depolarization
        moves downward to the patient's left side and posteriorly towards the
        thicker left ventricle. This depolarization vector moves away from the
        positive V1 electrode, resulting in a mainly negative QRS complex in V1.
      </p>
      <p>
        Remember that the positive wave of depolarization moving toward a
        positive electrode records a positive deflection on EKG, and
        depolarization moving away from a positive electrode records negatively.
      </p>
    </ContentTemplate>
  );
}
