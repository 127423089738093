import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function OverdriveSuppression(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/overdrive-suppression_HEknBh2.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        There is a hierarchy for what foci will pace the heart where faster
        sites of automaticity will suppress slower sites of potential
        automaticity. This is called overdrive suppression.
      </p>
      <p>
        Overdrive suppression is a characteristic of all automaticity foci,
        including the SA Node. When the SA Node depolarizes, it overdrive
        suppresses the slower pacemaking activity of all the automaticity foci
        below it. This includes overdrive suppressing all slower atrial,
        junction, and ventricular foci below the SA node.
      </p>
      <p>
        If the SA node fails to pace, then a backup pacemaker (i.e., an
        automaticity focus from a lower level) will emerge to pace at its
        inherent rate, and it conveniently overdrive-suppresses potential
        pacemaking activity at all levels that are below it. Therefore, an
        automaticity focus only functions as a pacemaker when it is no longer
        overdrive-suppressed.
      </p>
      <p>
        Think of this as the heart's failsafe pacing mechanism, providing three
        different levels of backup pacing.
      </p>
    </ContentTemplate>
  );
}
