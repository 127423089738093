import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function Pacemaker(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/SA-Node.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The Sino-Atrial Node (SA Node) is the heart's dominant pacemaker. It is
        located in the upper-posterior wall of the right atrium, initiating a
        depolarization wave at regular intervals - we call this Sinus Rhythm.
      </p>
      <p>
        The ability of the SA Node to generate pacemaking stimuli is known as
        automaticity (more on this in a later module).
      </p>
      <p>
        Each depolarization wave of positive charges proceeds outward from the
        SA Node and stimulates both atria to contract.
      </p>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
