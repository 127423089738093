import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function AtrialAxis(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Atrial-Axis.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        The P wave vector, which represents normal biatrial depolarization,
        points downward to the left side of the patient.
      </p>
      <p>
        The P wave vector points generally downward toward the positive
        electrode on the patient's left foot (for inferior leads II, III, and
        aVF), so the P waves are usually upright in those leads. The P wave
        vector also points leftward toward the positive electrode on the
        patient's left arm (for leads I, and aVL), producing upright P waves in
        those leads. So, suppose we see an inverted "P wave" in any of those
        leads. In that case, it is probably a P' depolarizing upwards from a low
        atrial focus or retrograde atrial depolarization moving upward from the
        AV Node.
      </p>
    </ContentTemplate>
  );
}
