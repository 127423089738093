import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import ClickReveal from "../../../Styles/ClickReveal";

export default function Practice3(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/practice-3.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Can you determine the location of the irritable focus that produced the
        premature beat seen in this rhythm strip?
      </p>
      <ClickReveal>
        <p>
          The last QRS complex should stand out because it occurs prematurely,
          and a P wave does not precede it.
        </p>
        <p>
          The last QRS complex looks the same as the other QRSs, so we know
          that, although premature, the last QRS resulted from depolarization
          that passed (in a normal fashion) down the ventricular conduction
          system. Therefore, it is not from a ventricular focus.
        </p>
        <p>
          Carefully examining the EKG strip, we don't see a P' wave before the
          premature QRS, so we know that the QRS did not originate from an
          atrial focus. Therefore the irritable automaticity focus that produced
          the premature QRS must be in the AV junction.
        </p>
      </ClickReveal>
    </ContentTemplate>
  );
}
