import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function AugmentedLeads(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/aVF_wZm4clW.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/aVR.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/aVL.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/Augmented-Leads-lines.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/combined-limb-leads.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        There are other standard leads that utilize limb electrodes. These are
        aVF, aVL, aVR.
      </p>
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          aVF lead
        </TextMark>{" "}
        uses the left foot electrode as positive. Both the right and left arm
        electrodes flow into a common ground that is negative.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          aVR lead
        </TextMark>{" "}
        uses the right arm as the positive electrode, while the left foot and
        left arm share a common ground, which will be negative.
      </p>
      <p>
        Similarly, the{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          aVL lead
        </TextMark>{" "}
        uses the left arm as the positive electrode, while the left foot and
        right arm electrodes will be negative.
      </p>
      <p>
        To record a lead from these electrodes, the electricity obtained from
        these electrodes needs to be amplified. In other words, the voltage
        needs to be augmented in the EKG machine to match the wave magnitude of
        leads I, II, and Ill. Hence the naming convention of these leads,
        Augmented (A), voltage (V) leads: aVL, aVF, aVR.
      </p>

      <p>In summary,</p>
      <ul>
        <li>aVR - Right arm positive</li>
        <li>aVL - Left arm positive</li>
        <li>aVF - Foot (left foot) positive</li>
      </ul>
      <p>
        The augmented limb leads, aVR, aVL, and aVF,{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          intersect
        </TextMark>{" "}
        at different angles than those produced by the bipolar limb leads, and
        they produce three other intersecting lines of reference.
      </p>
      <p>
        If you{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          combine
        </TextMark>{" "}
        the augmented leads' lines of reference with that of the bipolar leads,
        you'll notice that the augmented leads split the angles formed by leads
        I, II, and III. These lines of reference will make more sense in the
        next section.
      </p>
    </ContentTemplate>
  );
}
