import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function VentricularAutomaticityFoci(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/overdrive-suppression-ventricular_KbpFPzR.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Ventricular automaticity foci are composed of specialized Purkinje
        fibers. These pacemaking foci are in the Bundle of His, the Bundle
        Branches, and all their subdivisions since they are all composed of
        Purkinje fibers.
      </p>
      <p>
        Without overdrive suppression from above, a ventricular automaticity
        focus emerges to actively pace in its inherent rate range of 20 to 40
        per minute. This is called an idioventricular rhythm.
      </p>
      <p>
        A ventricular focus emerges as the active ventricular pacemaker only if
        it is no longer overdrive suppressed by regular, paced stimuli from
        above. This occurs:
      </p>
      <ul>
        <li>if all pacemaking centers above it have failed, or</li>
        <li>
          if there is a complete block of conduction below the AV Node
          (including the AV Junction) that prevents any pacing stimulus above it
          from reaching the ventricles.
        </li>
      </ul>
    </ContentTemplate>
  );
}
