import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function WhatIsHypertrophy(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Hypertrophy-intro.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        Hypertrophy usually pertains to an increase in size, but when relating
        to muscle as in myocardium, this term refers to increase in muscle mass.
      </p>
      <p>
        Hypertrophy of a chamber of the heart means that the muscular wall of
        that chamber has dilated and thickened beyond normal thickness. This
        increase in the muscular thickness of the wall of a hypertrophic chamber
        and dilation of a chamber of the heart can be seen on an EKG.
      </p>
    </ContentTemplate>
  );
}
