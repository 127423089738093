// DEPENDENCIES
import { Route, Switch } from "react-router-dom";
// COMPONENTS
import Electrocardiogram from "./Chapters/1-Electrocardiogram";
import Depolarization from "./Chapters/2-Depolarization";
import Electrodes from "./Chapters/3-Electrodes";
import Pacemaker from "./Chapters/4-Pacemaker";
import AtrialDepolarization from "./Chapters/5-AtrialDepolarization";
import AVNodeConduction from "./Chapters/6-AVNodeConduction";
import VentricularDepolarization from "./Chapters/7-VentricularDepolarization";
import VentricularRepolarization from "./Chapters/8-VentricularRepolarization";
import CardiacCycle from "./Chapters/9-CardiacCycle";

// STYLES

export default function PhysiologyModule(props) {
  return (
    <Switch>
      <Route
        path="/module/physiology/electrophysiology/electrocardiogram"
        render={(props) => <Electrocardiogram {...props} />}
      />
      <Route
        path="/module/physiology/electrophysiology/depolarization"
        render={(props) => <Depolarization {...props} />}
      />
      <Route
        path="/module/physiology/electrophysiology/electrodes"
        render={(props) => <Electrodes {...props} />}
      />
      <Route
        path="/module/physiology/waves-complexes-intervals/pacemaker"
        render={(props) => <Pacemaker {...props} />}
      />
      <Route
        path="/module/physiology/waves-complexes-intervals/atrial-depolarization"
        render={(props) => <AtrialDepolarization {...props} />}
      />
      <Route
        path="/module/physiology/waves-complexes-intervals/av-node-conduction"
        render={(props) => <AVNodeConduction {...props} />}
      />
      <Route
        path="/module/physiology/waves-complexes-intervals/ventricular-depolarization"
        render={(props) => <VentricularDepolarization {...props} />}
      />
      <Route
        path="/module/physiology/waves-complexes-intervals/ventricular-repolarization"
        render={(props) => <VentricularRepolarization {...props} />}
      />
      <Route
        path="/module/physiology/waves-complexes-intervals/cardiac-cycle"
        render={(props) => <CardiacCycle {...props} />}
      />
    </Switch>
  );
}
