import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function Tachyarrhythmias(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/paroxysmal-flutter-fibrillation.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A tachyarrhythmia originates in a very irritable focus that paces
        rapidly. Sometimes more than one active focus is generating pacing
        stimuli at once.
      </p>
      <p>
        Tachyarrhythmias can be easily recognized by their fast rate, but the
        specific diagnosis requires that we identify the origin of the
        arrhythmia (atrial, junctional, or ventricular).
      </p>
      <p>The rate ranges of the tachyarrhythmias are:</p>
      <ul>
        <li>Paroxysmal Tachycardia: 150 to 250 bpm</li>
        <li>Flutter: 250 to 350 bpm</li>
        <li>Fibrillation: 350 to 450 bpm</li>
      </ul>
    </ContentTemplate>
  );
}
