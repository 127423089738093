import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function FirstDegreeBlock(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/first-degree-av-block.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        In first-degree AV blocks, SA node conduction to the AV node is slowed,
        prolonging the PR interval.
      </p>
      <p>
        The normal PR interval is less than 200ms or less than one large square
        on the EKG graph paper.
      </p>
      <p>
        In first-degree AV blocks, the delay in conduction results in a
        prolonged PR interval. By definition, this is a PR interval &gt;200ms or
        more than one large square.
      </p>
      <p>
        This prolonged PR interval remains consistently lengthened to the same
        length every cycle. The P-QRS-T sequence is also normal in every cycle,
        with regular ventricular rates.
      </p>
    </ContentTemplate>
  );
}
