import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function StrainPatterns(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/strain-patterns.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        Ventricular hypertrophy may be associated with a strain pattern.
        Ventricular strain is characterized by depression of the ST segment,
        which generally "humps" upward in the middle of the segment.
      </p>
      <p>
        Strain is usually associated with ventricular hypertrophy since a
        ventricle straining against some resistance (e.g., increased resistance
        from a narrowed valve or hypertension) will become hypertrophied in its
        attempt to compensate.
      </p>
    </ContentTemplate>
  );
}
