import { useEffect, useState, useRef } from "react";
// DEPENDENCIES
// COMPONENTS
// STYLES
import { fontFamily, fontWeight } from "@bit/medzcool.cytoplasm.typography";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faTimes } from "@fortawesome/pro-solid-svg-icons";

export default function LeftStage(props) {
  const {
    setActiveStageMenu,
    activeStageMenu,
    moduleData,
    moduleProgress,
  } = props;

  const stageRef = useRef();

  function executeScroll() {
    stageRef.current.scrollTo({
      top: 0,
    });
    window.scrollTo({ top: stageRef.current.offsetTop });
  }

  const flattenObject = (obj) => {
    const flattened = {};

    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        Object.assign(flattened, flattenObject(value));
      } else {
        flattened[key] = value;
      }
    });

    return flattened;
  };

  // const [moduleProgress, setModuleProgress] = useState();
  useEffect(() => {
    executeScroll();
    // let flatChapters = [];
    // const moduleLength = moduleData.moduleTitle.sections.map(
    //   (section, index) => {
    //     section.chapters.map((chapter) => {
    //       flatChapters.push(chapter.chapterParam);
    //       return;
    //     });
    //     return;
    //   }
    // );

    // const currentPosition = flatChapters.indexOf(
    //   moduleData.chapterTitle.chapterParam
    // );
    // setModuleProgress((currentPosition + 1) / flatChapters.length);
  }, [props.location.pathname]);

  return (
    <LeftStageContainer>
      <div className="left-stage-scroll" ref={stageRef}>
        <div className="left-stage-inner">
          <div className="module-title title">
            <span className="title-uppercase">
              <ProgressBar percent={moduleProgress * 100} />
              <span className="module-label">MODULE:</span>{" "}
              {moduleData.moduleTitle.module}
            </span>
            <div
              className="menu-icon"
              onClick={() => setActiveStageMenu(!activeStageMenu)}>
              <FontAwesomeIcon
                icon={activeStageMenu ? faTimes : faCaretDown}
                size="lg"
              />
            </div>
          </div>
          <div className="section-title title">
            {moduleData.sectionTitle.section}
          </div>
          <h3 className="chapter-title title">
            {moduleData.chapterTitle.chapter}
          </h3>

          <div className="stage-body">{props.children}</div>
        </div>
      </div>
    </LeftStageContainer>
  );
}
const ProgressBar = styled.div`
  background: black;
  position: absolute;
  z-index: 10;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 15%;
  width: ${(props) => props.percent}%;
`;
const LeftStageContainer = styled.div`
  background: #f3f3f3;
  height: 100%;
  box-sizing: border-box;
  .title {
    font-family: ${fontFamily.sansSerif};
    &.module-title {
      z-index: 10;
      font-weight: ${fontWeight.heavy};
      position: absolute;
      top: 0;
      padding: 15px 25px;
      background-color: lightgray;
      width: 100%;
      left: 0;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 1fr auto;
      .menu-icon {
        position: relative;
        z-index: 11;
        color: gray;
        cursor: pointer;
      }
    }
    .title-uppercase {
      text-transform: uppercase;
      .module-label {
        font-weight: ${fontWeight.light};
      }
    }
    &.section-title {
      color: gray;
      text-transform: uppercase;
      margin-top: 50px;
      font-size: 0.75em;
    }
    &.chapter-title {
      font-size: 2em;
      margin-top: 0px;
    }
  }
  .stage-body {
    font-family: ${fontFamily.sansSerif};
    font-size: 1.3em;
    line-height: 130%;
  }
  .left-stage-scroll {
    height: 100%;
    overflow: hidden;
    overflow-y: hidden;
    overflow-y: scroll;
    .left-stage-inner {
      padding: 25px;
    }
  }
  @media (max-width: 430px) {
    .title {
      &.section-title {
        margin-top: 15px;
        font-size: 0.7em;
      }

      &.chapter-title {
        margin-bottom: 15px;
        font-size: 1.7em;
      }
    }
    .left-stage-scroll {
      .left-stage-inner {
        padding: 15px;
        padding-bottom: 125px;
        .module-title.title {
          display: none;
        }
      }
    }
    .stage-body {
      font-size: 1.2em;
    }
  }
`;
