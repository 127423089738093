import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function VentricularDepolarization(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/ventricular-conduction-system.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/QRS-complex.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/Q-wave.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/R-wave.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/S-wave.png",
    },
    {
      id: 5,
      img: "https://medzcool-images.s3.amazonaws.com/RS-complex.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        After depolarization conducts slowly through the AV node, it continues
        to the ventricular conduction system where depolarization propagates
        rapidly through Purkinje fibers, starting at the Bundle of His.
      </p>
      <p>
        After the Bundle of His, the Purkinje fibers bifurcate, leading
        depolarization to continue down the Left and Right Bundle Branches of
        the ventricles. The Purkinje fibers terminate in tiny filaments that lie
        just beneath the endocardial lining of both ventricles. Therefore
        ventricular depolarization begins at the endocardial lining and proceeds
        toward the outside surface (epicardium) of the ventricles.
      </p>
      <p>
        The passage of depolarization down the ventricular conducting system is
        too weak to record on the EKG; however, depolarization of the
        ventricular myocardium records as a{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          QRS complex,
        </TextMark>{" "}
        which leads to ventricular contraction.
      </p>
      <p>
        Remember that myocardial depolarization and contraction are not
        synonymous, even though some people will use the terms interchangeably.
        For example, the physical event of ventricular contraction begins at the
        start of the QRS complex and continues slightly beyond the end of the
        QRS complex. However, we still consider the QRS complex as generally
        representing the occurrence of ventricular contraction.
      </p>
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          Q wave
        </TextMark>{" "}
        is the first downward deflection of the QRS complex. It is not always
        present on an EKG. In fact, in most cases, you may not see an initial
        downward deflection and so no Q wave.
      </p>
      <p>
        When a Q wave is present, is it followed by an upward deflection, the{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          R wave.
        </TextMark>
      </p>
      <p>
        Any downward deflection after an upward wave is an{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          S wave.
        </TextMark>
      </p>
      <p>
        When a QRS complex appears with any upward deflection before a downward
        deflection, there is no Q wave, and this complex is technically an{" "}
        <TextMark
          activeMark={activeMark == 5 && true}
          onClick={() => clickTextMark(5)}>
          RS complex.
        </TextMark>{" "}
        In other words, any upward deflection within the QRS complex is always
        called the R wave.
      </p>
      <p>
        Remember, the QRS complex represents ventricular depolarization and the
        start of ventricular contraction.
      </p>
      <p>Name the numbered waves in each QRS complex</p>
      <ol>
        <li>Q wave</li>
        <li>R wave</li>
        <li>S wave</li>
      </ol>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
