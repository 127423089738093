import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark, FootnoteContainer, Footnote } from "../../../Styles/index";

export default function Arrhythmias(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/arrhythmia-intro.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        An arrhythmia is a term used to describe abnormal cardiac rhythms
        <sup>1</sup>. People with arrhythmias may complain of chest pain,
        palpations, or even be asymptomatic, which is why clinicians need other
        ways of screening for these abnormal heart rhythms other than a
        patient's signs and symptoms.
      </p>
      <p>
        The EKG can record the heart's electrical activity and is a sensitive
        tool helping clinicians identify and diagnose various cardiac
        arrhythmias.
      </p>
      <p>
        To understand these arrhythmias, you must first become family with the
        normal electrophysiology of the heart, including the normal conduction
        pathways.
      </p>
      <FootnoteContainer>
        <Footnote>
          The term arrhythmia and dysrhythmia are often used interchangeably.
          “Dys” is a Greek prefix that means bad, ill, or difficult. “A” is
          another Greek prefix that generally means not or without. Therefore,
          dysrhythmia essentially means “bad rhythm,” and arrhythmia basically
          means “without rhythm.” For simplicity, the term arrhythmia will be
          used in place of dysrhythmia for any abnormal cardiac rhythm.
        </Footnote>
      </FootnoteContainer>
    </ContentTemplate>
  );
}
