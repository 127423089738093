import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function PosteriorInfarction(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Posterior-Infarction-0.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Posterior-Infarction-1.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Posterior-Infarction-2.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/Posterior-Infarction-3.png",
    },
    {
      id: 4,
      img:
        "https://medzcool-images.s3.amazonaws.com/Posterior-Infarction-4.png",
    },
    {
      id: 5,
      img:
        "https://medzcool-images.s3.amazonaws.com/Posterior-Infarction-5.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        Acute{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          posterior infarction
        </TextMark>{" "}
        of the left ventricle would produce the exact opposite pattern seen in
        an acute anterior infarction because the anterior and posterior walls of
        the left ventricle depolarize in{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          opposite directions.
        </TextMark>
      </p>
      <p>
        In acute Posterior Infarction, there is a{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          large R wave
        </TextMark>{" "}
        (the opposite of a Q wave) in V1 and V2. Therefore, suspect a true
        posterior infarction when you see a large R wave in V1 or V2. Remember
        that Right Ventricular Hypertrophy can also produce a large R in V1.
      </p>
      {/* <p>
        [also show a comparison of what a normal morphology in V1 and V2 would
        look like compared to how posterior infarctions look like - R waves in
        lead V 1 are normally very tiny]
      </p> */}
      {/* <p>[show comparison between RVH and posterior infarction in V1]</p> */}
      <p>
        In acute Posterior Infarction, you may also see{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          ST depression
        </TextMark>{" "}
        in V1 or V2. This is in contrast to acute anterior infarcts,
        characterized by Q waves and ST elevations in these chest leads.
      </p>
      <p>
        Although posterior infarctions are severe, they are easily overlooked.
        Therefore, always be suspicious of ST segment depression in the
        right-sided chest leads -
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          V1, V2.
        </TextMark>{" "}
        Remember that ST depressions could also represent subendocardial
        infarctions. However, you should maintain a high level of suspicion,
        especially when ST depressions are present in leads V1 and V2 since this
        could mean an anterior subendocardial infarction is present, but it
        could also represent an acute posterior infarction.
      </p>
      <p>
        It can be challenging to localize infarctions in the right-sided chest
        leads (V1 and V2). So, to simplify this, remember that ST changes in V1
        and V2 are almost always significant, regardless of whether the change
        is a depression or elevation.
      </p>

      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        AFFECTED CORONARY ARTERY
      </strong>

      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 5 && true}
          onClick={() => clickTextMark(5)}>
          Right Coronary Artery
        </TextMark>{" "}
        wraps around the right ventricle posteriorly to supply the posterior
        portion of the left ventricle. So, a posterior infarction usually is
        caused by an occlusion of a branch of the Right Coronary Artery.
      </p>
      <p>
        In summary, Acute Posterior Infarction is characterized by a large R
        wave and ST depression in VI or V2 (sometimes even in V3).
      </p>
    </ContentTemplate>
  );
}
