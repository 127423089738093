import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function VentricularFlutter(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/ventricular-flutter.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Ventricular Flutter is produced by a single ventricular automaticity
        focus conductive impulses at a rate of 250 to 350 per minute. This can
        be classically recognized on an EKG as a rapid series of smooth sine
        waves of similar amplitude.
      </p>
      <p>
        Physiologically, this rapid ventricular rate is so fast that the
        ventricles hardly have enough time to fill with blood, which can lead to
        serious signs and symptoms but can also deteriorate into a deadly
        arrhythmia such as ventricular fibrillation, which is a non-perfusing
        rhythm leading to cardiac arrest and eventual death if left untreated.
      </p>
      <p>
        Although this section is labeled as Ventricular Flutter, many will
        interchangeably use the term Ventricular Tachycardia. And because this
        arrhythmia originates from a single ventricular automaticity focus, you
        can further describe this as a monomorphic ventricular tachycardia
        (mono-, meaning one or single and -morphic meaning shape or morphology).
      </p>
    </ContentTemplate>
  );
}
