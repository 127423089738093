import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function InherentRate(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/automaticity-foci.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/automaticity-foci-atria.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/automaticity-foci-junctional.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/automaticity-foci-ventricular_P41ZsR2.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Each automaticity focus of the atria has a specific inherent rate at
        which it paces, but its inherent rate falls within the broad range of{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          60 to 80 per minute.
        </TextMark>
      </p>
      <p>
        The automaticity foci of the AV Junction all pace in the range of{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          40 to 60 per minute,
        </TextMark>{" "}
        but any single Junctional focus paces at its individual rate.
      </p>
      <p>
        Ventricular automaticity foci all pace in the{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          20 to 40 per minute range,
        </TextMark>{" "}
        but any specific ventricular focus has a distinct inherent pacing rate.
      </p>
      <p>
        Keep in mind that only one automaticity focus paces the heart at any one
        time. For example, if the SA node is the primary automaticity focus or
        pacemaker for the heart, all other automaticity foci are suppressed.
      </p>
    </ContentTemplate>
  );
}
