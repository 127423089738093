import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function LBBB(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/LBBB.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        In Left Bundle Branch Blocks, left ventricular depolarization is
        delayed, so the right ventricle depolarizes punctually (R), and the R'
        represents delayed left ventricular depolarization.
      </p>
      <p>
        A left bundle branch block produces an R, R' in the left chest leads, V5
        or V6. Occasionally, the R, R' in V5 or V6 will appear only as a
        flattened peak or sometimes a rounded peak with two tiny points of the
        R, R'.
      </p>
      <p>
        Remember that in LBBBs, the right ventricle depolarizes before the left
        ventricle, so the first portion of the wide QRS represents the right
        ventricular depolarization and the R' wave represents the delayed
        depolarization of the left ventricle.
      </p>
      {/* <p>
        The Left Bundle Branch has two subdivisions ("fascicles"); blocks of
        these fascicles are called Hemiblocks (see p 295 - 305)
      </p> */}
    </ContentTemplate>
  );
}
