import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function HorizontalPlane(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/horizontal-plane_1O2M8Ia.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Horizontal-Axis.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          chest leads
        </TextMark>{" "}
        form the horizontal plane, which divides the body into top and bottom
        halves. Therefore, you can use the chest leads to determine the rotation
        of the Mean QRS vector in the horizontal plane.
      </p>
      <p>
        When we "cut" the person in the horizontal plane, the chest electrode
        used for recording lead V2 is always positive. This makes our person's
        front, or anterior, positive and the back, or posterior, negative.
      </p>
      <p>
        Normally, the QRS complex in V2 is downward, which is a negative
        deflection. This means that the{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          Mean QRS Vector
        </TextMark>{" "}
        points backward into the negative half of our person. Anatomically this
        makes sense since most of the ventricular depolarization moves away from
        the positive V2 electrode toward the thicker and more posteriorly
        positioned left ventricle.
      </p>
    </ContentTemplate>
  );
}
