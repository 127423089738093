import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function AtrialFibrillation(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Atrial-Fibrillation.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Atrial fibrillation (AF) occurs when many irritable atrial foci fire
        rapidly, but since they are parasystolic, none of them can be
        overdrive-suppressed. As a result, these foci can appear to pace
        simultaneously, producing a series of rapid and tiny erratic spikes on
        an EKG. In addition, because so many atrial foci are rapidly firing, no
        single depolarization spreads very far. As a result, only a tiny portion
        of the atria is depolarized by any one discharge from an atrial focus.
      </p>
      <p>
        Depolarizations from foci near the AV Node conduct to the ventricles,
        producing a very irregular ventricular rhythm.
      </p>
      <p>
        With a Normal Sinus Rhythm, each pacing impulse that the SA Node
        generates spreads through the atria like an enlarging, circular wave,
        much like a pebble dropped into a pool of water. However, the multiple
        erratic depolarizations of atrial fibrillation are analogous to a rain
        shower striking the same pool - each individual ripple will collide with
        other ripples failing to coordinate a larger circular wave.
      </p>
      <p>
        Atrial Fibrillation often appears as a wavy or erratic baseline without
        identifiable P or P' waves. Only discharging foci near the AV Node can
        (occasionally) stimulate it, allowing an impulse through to the
        ventricular conduction system.
      </p>
      <p>
        The AV Node is irregularly stimulated during atrial fibrillation, so the
        ventricular response is irregular. On EKG, classically, you will see
        randomly conducted QRS complexes, and so the complete cardiac cycle and
        ventricular contractions are also irregular. This is why many people
        often refer to Atrial Fibrillation as having an irregularly irregular
        rhythm
      </p>
      <p>
        The ventricular rate depends on the AV Node's duration of refractoriness
        after it is depolarized. During Atrial Fibrillation, the AV Node usually
        allows a relatively normal range of ventricular rate, albeit always
        irregular. Sometimes the AV Node permits an increased number of
        depolarization stimuli to pass through, producing a rapid ventricular
        rate.
      </p>
    </ContentTemplate>
  );
}
