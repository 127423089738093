import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function RBBB(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/RBBB.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        In Right Bundle Branch Blocks, the left ventricle depolarizes promptly,
        so the R represents left ventricular depolarization, and the R'
        represents delayed right ventricular depolarization.
      </p>
      <p>
        Right Bundle Branch Blocks produce an R, R' in the right chest leads, V1
        or V2. Remember that in Right Bundle Branch Blocks, the right ventricle
        is depolarizing slightly later than the left ventricle, so the R' in
        this case represents the delayed depolarization to the right ventricle.
      </p>
    </ContentTemplate>
  );
}
