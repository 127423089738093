import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function NormalAxis(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Normal-Axis.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        Remember that the normal Mean QRS Vector should point downward and to
        the patient's left. Since the ventricles angle downward to the left, and
        ventricular depolarization moves downward and leftward, it should not
        surprise you that this is the normal range of the Mean QRS Vector.
      </p>
      <p>
        Using our sphere and leads I and aVF, a normal axis would have a mainly
        upward QRS deflection in lead I and an upward QRS deflection in aVF.
      </p>
      <p>
        An upwards QRS deflection in Lead aVF is positive: a downward pointing
        vector.
      </p>
      <p>
        An upward QRS deflection in Lead I is positive: a left-pointing vector.
      </p>
    </ContentTemplate>
  );
}
