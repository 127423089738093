import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function PrematureBeats(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-beat-intro-0.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-beat-intro-1.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A premature beat originates in an irritable{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          automaticity focus
        </TextMark>{" "}
        that fires spontaneously, producing an electrical stimulus earlier than
        expected in the rhythm.
      </p>
      <p>
        Suppose an atrial or Junctional automaticity focus becomes irritable. In
        that case, it may fire a spontaneous impulse that depolarizes the
        surrounding tissue, so we can recognize it on EKG as a{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          premature beat.
        </TextMark>
      </p>
      <p>
        These irritable foci can be isolated and occur only once, after which
        the SA node takes over and resumes normal sinus rhythm. But these
        irritable foci can also produce a series of rapid impulses which
        overdrive-suppress all other automaticity foci, becoming the dominant
        pacemaker.
      </p>
      <p>
        These irritable foci are often the result of sympathetic stimulation,
        which can be medication or drug-induced, or the result of increased
        endogenous stimulation, like in cases of hyperthyroidism or increased
        production of epinephrine from the adrenal glands.
      </p>
    </ContentTemplate>
  );
}
