import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function AxisRotation(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/precordial-lead-progression.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Axis-rotation-left-right.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        The Mean QRS Vector can rotate horizontally, with its tail anchored to
        the AV Node.
      </p>
      <p>
        Typically, the QRS complex deflection through the chest leads has a{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          gradual transition,
        </TextMark>{" "}
        with a negative deflection in V1 to a positive deflection in V6. We also
        notice that the QRS usually becomes isoelectric in leads V3 and V4,
        typically referred to as the transitional zone.
      </p>
      <p>
        To measure rotation in the horizontal plane, you can use this
        transitional zone as a reference point. Remember that an isoelectric QRS
        is 90° away from the Mean QRS Vector. So a{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          rotation of the Vector
        </TextMark>{" "}
        in the horizontal plane is reflected as a similar change in position of
        the transitional (isoelectric) QRS in the chest leads.
      </p>
      <p>
        For example, when the isoelectric ("transitional") QRS has rotated to
        the patient's right (into leads V1 or V2), this is rightward rotation.
        But if the transitional QRS is found in the patient's left chest leads,
        V5 or V6, this is leftward rotation.
      </p>
      <p>
        Anatomically speaking, the heart is not capable of much rotation in the
        horizontal plane. As a result, we often consider other factors which can
        lead to these rotations, such as ventricular hypertrophy or myocardial
        infarction.
      </p>
      <p>
        Axis deviation is in the frontal plane. Axis rotation is in the
        horizontal plane.
      </p>
    </ContentTemplate>
  );
}
