import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function CardiacCycle(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/EKG-waves-bumps.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A cardiac cycle is represented by the P wave, the QRS complex, the T
        wave, and the baseline that follows until another P wave appears. This
        cycle is repeated continuously in coordination with SA node
        depolarization.
      </p>
      <p>
        In summary, the P wave represents atrial depolarization and contraction,
        while the QRS complex represents ventricular depolarization and
        contraction. During this time, the atria undergo and complete
        repolarization. Still, you don't see atrial repolarization represented
        on the EKG because it occurs at approximately the same time as the QRS
        complex. As a result, atrial repolarization is "buried" within the QRS
        complex.
      </p>
      <p>
        Unlike atrial repolarization, ventricular repolarization is visualized
        on the EKG as the ST segment, ending with the T wave.
      </p>
      <p>This P-QRS-T represents one complete cardiac cycle.</p>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
