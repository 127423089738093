import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function Ischemia(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Ischemia.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/T-wave-inversion-v2-v3.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        Ischemia means reduced blood supply. In this case, that would be reduced
        blood supply to the myocardium from the coronary arteries.
      </p>
      <p>
        The characteristic sign of ischemia on an EKG is the{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          inverted T wave.
        </TextMark>{" "}
        It may vary from a slightly inverted to a deeply inverted T wave.
      </p>
      <p>
        It may vary from a slightly inverted to a deeply inverted T wave. These
        inverted T waves suggest ischemia that can occur without resulting in
        myocardial infarction regardless of its shape. In other words, coronary
        blood flow can decrease, leading to ischemia without resulting in
        infarction. Clinically, cardiac ischemia can cause chest pain known as
        angina, usually associated with transient T wave inversion.
      </p>
      <p>
        The T wave of ischemia is both inverted and symmetrical on an EKG.
        Therefore, the right and left sides of the inverted T wave should appear
        as mirror images of one another.
      </p>
      <p>
        Since the chest leads are typically nearest to the ventricles, T wave
        changes may be more pronounced in these leads. For example, flat T waves
        or minimal T wave inversions may be a normal variant in any limb leads
        in adults. However, they are usually pathological in the V2 through V6{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          chest leads.
        </TextMark>{" "}
      </p>
      {/* <p>
        Marked T wave inversion in leads V2 and V3, the hallmark of Wellens
        syndrome, alerts us to stenosis of the anterior descending coronary.
      </p> */}
    </ContentTemplate>
  );
}
