import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function Introduction(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/infarction-triad-intro.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        The myocardial infarction triad of ischemia, injury, and necrosis is the
        basis for recognizing and diagnosing infarction on an EKG. Although
        ischemia, injury, and necrosis may sound synonymous right now, they mean
        different things. Because of these differences in their definition,
        neither ischemia, injury, nor necrosis needs to all be present at the
        same time to make the diagnosis of infarction.
      </p>
      <p>
        We'll define these terms of the infarction triad in the coming chapters.
      </p>
    </ContentTemplate>
  );
}
