// Dependencies
import { Route, Switch } from "react-router-dom";

// Components
import WhatIsInfarction from "./Chapters/1-WhatIsInfarction";
import CoronaryArteries from "./Chapters/2-CoronaryArteries";
import Introduction from "./Chapters/3-Introduction";
import Ischemia from "./Chapters/4-Ischemia";
import Injury from "./Chapters/5-Injury";
import Necrosis from "./Chapters/6-Necrosis";
import IdentifyingInfarcts from "./Chapters/7-IdentifyingInfarcts";
import LocalizingIntroduction from "./Chapters/8-Introduction";
import AnteriorInfarction from "./Chapters/9-AnteriorInfarction";
import LateralInfarction from "./Chapters/10-LateralInfarction";
import InferiorInfarction from "./Chapters/11-InferiorInfarction";
import PosteriorInfarction from "./Chapters/12-PosteriorInfarction";
import InfarctionLBBB from "./Chapters/13-InfarctionLBBB";
import LocalizationSummary from "./Chapters/14-LocalizationSummary";

// Styles
export default function InfarctionModule(props) {
  return (
    <Switch>
      <Route
        path="/module/infarction/pathophysiology/what-is-an-infarction"
        render={(props) => <WhatIsInfarction {...props} />}
      />
      <Route
        path="/module/infarction/pathophysiology/coronary-arteries"
        render={(props) => <CoronaryArteries {...props} />}
      />
      <Route
        path="/module/infarction/infarction-triad/introduction"
        render={(props) => <Introduction {...props} />}
      />
      <Route
        path="/module/infarction/infarction-triad/ischemia"
        render={(props) => <Ischemia {...props} />}
      />
      <Route
        path="/module/infarction/infarction-triad/injury"
        render={(props) => <Injury {...props} />}
      />
      <Route
        path="/module/infarction/infarction-triad/necrosis"
        render={(props) => <Necrosis {...props} />}
      />
      <Route
        path="/module/infarction/infarction-triad/identifying-infarcts"
        render={(props) => <IdentifyingInfarcts {...props} />}
      />
      <Route
        path="/module/infarction/localizing-infarctions/introduction"
        render={(props) => <LocalizingIntroduction {...props} />}
      />
      <Route
        path="/module/infarction/localizing-infarctions/anterior-infarction"
        render={(props) => <AnteriorInfarction {...props} />}
      />
      <Route
        path="/module/infarction/localizing-infarctions/lateral-infarction"
        render={(props) => <LateralInfarction {...props} />}
      />
      <Route
        path="/module/infarction/localizing-infarctions/inferior-infarction"
        render={(props) => <InferiorInfarction {...props} />}
      />
      <Route
        path="/module/infarction/localizing-infarctions/posterior-infarction"
        render={(props) => <PosteriorInfarction {...props} />}
      />
      <Route
        path="/module/infarction/localizing-infarctions/infarction-with-lbbb"
        render={(props) => <InfarctionLBBB {...props} />}
      />
      <Route
        path="/module/infarction/localizing-infarctions/infarction-localization-summary"
        render={(props) => <LocalizationSummary {...props} />}
      />
    </Switch>
  );
}
