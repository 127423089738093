import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function InferiorInfarction(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Inferior-Infarction-0.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Inferior-Infarction-1.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/Inferior-Infarction-2.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        An inferior infarction is identified by significant{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Q waves
        </TextMark>{" "}
        in leads II, III, and aVF.
      </p>
      <p>
        In this case, the infarcted area can no longer depolarize and, as a
        result, fails to produce an opposing vector of depolarization to the
        ventricular wall opposite to it. This records on the EKG as Q waves in
        II, III, and aVF, produced by the initial "away" vectors recorded by the
        positive left foot{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          electrode
        </TextMark>{" "}
        through the void of the inferior necrotic infarct.
      </p>
      <p>
        Given the proximity of the right ventricle to these inferior leads,
        about one-third of all inferior infarctions also include portions of the
        right ventricle.
      </p>

      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        AFFECTED CORONARY ARTERY
      </strong>

      <p>
        The base of the left ventricle and a portion of the right ventricle
        receives their{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          blood supply
        </TextMark>{" "}
        from branches of either the Right or the Left Coronary Artery, depending
        on which artery is "dominant."
      </p>
      <p>
        So the diagnosis of inferior infarction does not necessarily identify
        the arterial branch that is occluded unless you have previous coronary
        anatomical studies available or prior knowledge of a patient's coronary
        anatomy. However, right coronary dominance is more common.
      </p>
      <p>
        So, inferior infarctions can be caused by an occluded terminal branch of
        either the Right or the Left Coronary Artery, depending on the patient's
        coronary anatomy.
      </p>
    </ContentTemplate>
  );
}
