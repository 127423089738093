import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function SickSinusSyndrome(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/sick-sinus-syndrome.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/tachy-brady-syndrome.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Sick Sinus Syndrome
        </TextMark>{" "}
        (SSS) is an umbrella term for arrhythmias caused by SA Node dysfunction
        associated with unresponsive supraventricular (atrial and Junctional)
        automaticity foci which can't employ their normal escape mechanism to
        assume pacing responsibility.
      </p>
      <p>
        SSS often occurs in elderly individuals with underlying heart disease.
        It is usually characterized by marked Sinus Bradycardia but without the
        normal escape mechanisms of atrial and Junctional foci.
      </p>

      <p>
        Patients with SSS may develop{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          intermittent episodes of SVT
        </TextMark>{" "}
        (sometimes even Atrial Flutter or Atrial Fibrillation) mingled with the
        Sinus Bradycardia. This is called Bradycardia-Tachycardia Syndrome.
      </p>
    </ContentTemplate>
  );
}
