import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function VentricularEscapeRhythm(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Ventricular-Escape.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A Ventricular Escape Rhythm occurs when a ventricular automaticity focus
        is not regularly stimulated by paced depolarizations from above, so it
        escapes overdrive suppression to emerge as the ventricular pacemaker
        with an inherent rate in the range of 20 to 40 per minute.
      </p>
      <p>
        Ventricular Escape Rhythm usually results from one of two mechanisms:
      </p>
      <ul>
        <li>
          With complete conduction block high in the ventricular conduction
          system (but below the AV Node), the ventricular foci are not
          stimulated by atrial depolarizations from above (see P waves in
          illustration), so a ventricular focus escapes to pace the ventricles
          at its inherent rate.
        </li>
        <li>
          Total failure of the SA Node and all automaticity foci above the
          ventricles. This is rare and ultimately represents the heart's last
          attempt to sustain a perfusing rhythm. However, pacing from a sole
          ventricular focus is often so slow that blood flow to the brain is
          inadequate, leading to syncope and unconsciousness.
        </li>
        {/* [just like in Stokes-Adams Syndrome - maybe given a brief description of what this is.] */}
      </ul>
    </ContentTemplate>
  );
}
