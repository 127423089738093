import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function TripletTrick(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Triplet-trick-1.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Triplet-trick-2.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/Triplet-trick-3.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        This "triplet trick" is an easier and faster way to estimate the heart
        rate on the EKG.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          First,
        </TextMark>{" "}
        look for an R wave that peaks on a heavy black line. This will be your
        "start" line.
      </p>
      <p>
        The next heavy black line that follows your start line is "300". The
        following heavy black line after 300 is 150, followed by 100. This is
        the first "triplet."
      </p>
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          next three lines
        </TextMark>{" "}
        after "300, 150, 100" are labeled "75, 60, 50".
      </p>
      <p>
        By knowing the triplets "300, 150, 100" then "75, 60, 50," you can
        merely look at an EKG and tell the approximate rate immediately.
      </p>
      <p>
        Where the{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          next R wave falls
        </TextMark>{" "}
        determines the rate.
      </p>
      <p>If the next R wave falls on "75", the rate is 75 per minute.</p>
      <p>
        You may have noticed that this EKG rhythm strip shows the rate range to
        be between 75 to 100. In this case you can determine quickly that the
        heart rate to be between 75 to 100.
      </p>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
