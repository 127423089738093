import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function SupraventricularTachycardia(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/SVT-umbrella-terms.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/AVNRT-AV-node-normal.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/AVNRT-AV-node-slow.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/AVNRT-AV-node-fast.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/AVNRT-AV-node-NSR.png",
    },
    {
      id: 5,
      img:
        "https://medzcool-images.s3.amazonaws.com/AVNRT-AV-node-NSR-full.png",
    },
    {
      id: 6,
      img: "https://medzcool-images.s3.amazonaws.com/AVNRT-premature.png",
    },
    {
      id: 7,
      img:
        "https://medzcool-images.s3.amazonaws.com/AVNRT-retrograde-short-refractory.png",
    },
    {
      id: 8,
      img: "https://medzcool-images.s3.amazonaws.com/AVNRT-start-cycle.png",
    },
    {
      id: 9,
      img: "https://medzcool-images.s3.amazonaws.com/AVNRT-rapid-reentry.png",
    },
    {
      id: 10,
      img:
        "https://medzcool-images.s3.amazonaws.com/AVRT-accessory-pathway.png",
    },
    {
      id: 11,
      img: "https://medzcool-images.s3.amazonaws.com/AVRT-normal-pathway.png",
    },
    {
      id: 12,
      img:
        "https://medzcool-images.s3.amazonaws.com/AVRT-simultaneous-conduction.png",
    },
    {
      id: 13,
      img: "https://medzcool-images.s3.amazonaws.com/AVRT-premature-beat.png",
    },
    {
      id: 14,
      img:
        "https://medzcool-images.s3.amazonaws.com/AVRT-accessory-no-refractory.png",
    },
    {
      id: 15,
      img: "https://medzcool-images.s3.amazonaws.com/AVRT-reentry-circuit.png",
    },
    {
      id: 16,
      img: "https://medzcool-images.s3.amazonaws.com/AVNRT-EKG.png",
    },
    {
      id: 17,
      img: "https://medzcool-images.s3.amazonaws.com/AVRT-v1.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        A{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Supraventricular Tachycardia
        </TextMark>{" "}
        (<strong>SVT</strong>) is a type of tachyarrhythmia that originates from
        an irritable automaticity focus above the ventricles. This includes
        regular or irregular atrial tachycardias and regular or irregular
        atrioventricular tachycardias. In other words, it is often used as an
        umbrella term that includes both Atrial Tachycardias and Junctional
        Tachycardias.
      </p>
      <p>
        The term "SVT" is often used synonymously with AV nodal re-entry
        tachycardia (<strong>AVNRT</strong>), which is a form of SVT, but can
        also include cases of ​​Atrioventricular re-entrant tachycardia (
        <strong>AVRT</strong>) as well. Each differs in the mechanism of how the
        arrhythmia originates.
      </p>

      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        Atrioventricular Nodal Re-entry Tachycardia (AVNRT)
      </strong>
      <p>
        AVNRT is the more common cause of SVT, when compared to AVRT. In AVNRT,
        there is a functional re-entry circuit involving the AV node.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          Normally,
        </TextMark>{" "}
        there are two pathways within the AV node:
      </p>
      <ul>
        <li>
          The{" "}
          <TextMark
            activeMark={activeMark == 2 && true}
            onClick={() => clickTextMark(2)}>
            Slow Pathway:
          </TextMark>{" "}
          a slowly-conducting pathway with a short refractory period.
        </li>
        <li>
          The{" "}
          <TextMark
            activeMark={activeMark == 3 && true}
            onClick={() => clickTextMark(3)}>
            Fast Pathway:
          </TextMark>{" "}
          a rapidly-conducting pathway with a long refractory period.
        </li>
      </ul>
      <p>
        With{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          Normal Sinus Rhythm,
        </TextMark>{" "}
        depolarization travels down both pathways simultaneously. The fast
        pathway conducts its electrical impulse down the AV node and towards a
        common pathway, then down the Bundle of His and ventricles. Meanwhile,
        the action potentials are still making their way down the slow pathway
        until they meet with impulses from the fast pathway in the distal of the
        AV node to{" "}
        <TextMark
          activeMark={activeMark == 5 && true}
          onClick={() => clickTextMark(5)}>
          cancel
        </TextMark>{" "}
        each other out.
      </p>
      <p>
        It’s important to understand that the slow pathway has a shorter
        refractory period, while the fast pathway has a longer refractory
        period. So if a{" "}
        <TextMark
          activeMark={activeMark == 6 && true}
          onClick={() => clickTextMark(6)}>
          premature atrial beat
        </TextMark>{" "}
        reaches the AV node while the fast pathway is still refractory, the
        electrical impulse will only be conducted down the slow pathway.
      </p>
      <p>
        By the time the premature impulse reaches the end of the slow pathway,
        the fast pathway is no longer refractory, and the impulse conducts in a{" "}
        <TextMark
          activeMark={activeMark == 7 && true}
          onClick={() => clickTextMark(7)}>
          retrograde direction
        </TextMark>{" "}
        up the fast pathway.
      </p>
      <p>
        Remember that the slow pathway has a short refractory period, so by the
        time the conduction of the retrograde impulse reaches the start of the
        slow pathway, it is also no longer refractory and so the impulse is{" "}
        <TextMark
          activeMark={activeMark == 8 && true}
          onClick={() => clickTextMark(8)}>
          again conducted
        </TextMark>{" "}
        down the slow pathway. This impulse conducts rapidly around and around
        this re-entry circuit and with each cycle around the AV node, an impulse
        is{" "}
        <TextMark
          activeMark={activeMark == 9 && true}
          onClick={() => clickTextMark(9)}>
          conducted down
        </TextMark>{" "}
        to the ventricles at a rapid rate.
      </p>
      <p>This Slow-Fast AVNRT is the most common type of AVNRT.</p>
      <p>
        On an{" "}
        <TextMark
          activeMark={activeMark == 16 && true}
          onClick={() => clickTextMark(16)}>
          EKG,
        </TextMark>{" "}
        you will typically see a narrow-complex tachycardia with a heart rate
        ranging between 140-280 bpm. P waves are often hidden, being embedded in
        the QRS complexes, resulting in a typical SVT EKG appearance with absent
        P waves and tachycardia.
      </p>
      <ul>
        <li>Rapid Tachycardia usually 140 - 180 bpm</li>
        <li>Narrow QRS Complex (&lt; 120ms)</li>
        <li>P waves are often hidden, embedded in QRS complexes</li>
        <li>Retrograde P waves may be seen in V1 or V2</li>
      </ul>

      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        Atrioventricular Re-entry Tachycardia (AVRT)
      </strong>
      <p>
        Atrioventricular Re-entry Tachycardia (AVRT) is a form of
        supraventricular tachycardia that occurs in patients with{" "}
        <TextMark
          activeMark={activeMark == 10 && true}
          onClick={() => clickTextMark(10)}>
          accessory pathways,
        </TextMark>{" "}
        usually due to the formation of a re-entry circuit between the AV node
        and accessory pathway.
      </p>
      <p>
        It is often triggered by premature atrial or premature ventricular beats
        which initiate a re-entry circuit resulting in the tachyarrhythmia.
      </p>
      <p>
        In hearts with an accessory pathway, depolarization conducts{" "}
        <TextMark
          activeMark={activeMark == 11 && true}
          onClick={() => clickTextMark(11)}>
          normally
        </TextMark>{" "}
        from the SA node to the AV node, then down to the ventricles, while also{" "}
        <TextMark
          activeMark={activeMark == 12 && true}
          onClick={() => clickTextMark(12)}>
          simultaneously passing
        </TextMark>{" "}
        through the accessory pathway to depolarize the ventricles. After
        completing depolarization, the cardiac cells enter a refractory period
        and any premature beats will fail to depolarize these cells. But if a{" "}
        <TextMark
          activeMark={activeMark == 13 && true}
          onClick={() => clickTextMark(13)}>
          premature atrial beat
        </TextMark>{" "}
        fires at the right time, it may catch the AV node after it recovers
        while the accessory pathway is still in its refractory period. In this
        case, depolarization travels down the ventricular conduction system and
        eventually up towards the accessory pathway.
      </p>
      <p>
        By this time, the accessory pathway would have{" "}
        <TextMark
          activeMark={activeMark == 14 && true}
          onClick={() => clickTextMark(14)}>
          recovered
        </TextMark>{" "}
        from its refractory period, allowing electricity to pass through it and
        then back down a recovered AV node. This{" "}
        <TextMark
          activeMark={activeMark == 15 && true}
          onClick={() => clickTextMark(15)}>
          re-entry circuit
        </TextMark>{" "}
        continues on and on, sending rapidly conducting impulses towards the
        ventricles creating the characteristic tachyarrhythmia seen on{" "}
        <TextMark
          activeMark={activeMark == 17 && true}
          onClick={() => clickTextMark(17)}>
          EKG.
        </TextMark>
        <ul>
          <li>Narrow complex tachycardia at 180-300 bpm</li>
          <li>Retrograde P waves occur later (compared to AVNRT)</li>
        </ul>
      </p>
      {/* <p>
        The direction of conduction through this re-entry circuit can appear
        differently on EKG and can occur in two different directions:
        orthodromic and antidromic.
      </p>
      <p>Orthodromic: QRS &lt; 120ms unless pre-existing bundle branch block</p>
      <p>
        Antidromic: Wide QRS complexes due to abnormal ventricular
        depolarization via accessory pathway.
      </p> */}
    </ContentTemplate>
  );
}
