import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function PrematureVentricularContraction(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-ventricular-contraction.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-ventricular-contraction-origins_aEAyN0H.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-ventricular-contraction-burried-p.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-ventricular-contraction-comp-pause.png",
    },
    {
      id: 4,
      img:
        "https://medzcool-images.s3.amazonaws.com/Premature-ventricular-contraction-numerous.png",
    },
    {
      id: 5,
      img: "https://medzcool-images.s3.amazonaws.com/ventricular-bigeminy.png",
    },
    {
      id: 6,
      img: "https://medzcool-images.s3.amazonaws.com/ventricular-trigeminy.png",
    },
    {
      id: 7,
      img:
        "https://medzcool-images.s3.amazonaws.com/ventricular-parasystole.png",
    },
    {
      id: 8,
      img:
        "https://medzcool-images.s3.amazonaws.com/ventricular-parasystole-focus.png",
    },
    {
      id: 9,
      img: "https://medzcool-images.s3.amazonaws.com/pvc-vtach.png",
    },
    {
      id: 10,
      img: "https://medzcool-images.s3.amazonaws.com/multifocal-PVC.png",
    },
    {
      id: 11,
      img: "https://medzcool-images.s3.amazonaws.com/R-on-T.png",
    },
    {
      id: 12,
      img: "https://medzcool-images.s3.amazonaws.com/PVC-normal-QRS.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Hypoxia can make a ventricular automaticity focus become irritable and{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          spontaneously conduct
        </TextMark>{" "}
        an impulse, producing a premature ventricular beat on EKG, aka a
        Premature Ventricular Complex (PVC). Alternatively, a very irritable
        ventricular focus may also conduct a series of rapid impulses,
        overdrive-suppressing Normal Sinus rhythm, thus becoming the heart's
        dominant pacemaker.
      </p>
      <p>
        PVC's occur early in the cycle. Easily recognized by their wide QRS
        complex and large amplitude, they are usually opposite the polarity of
        the normal QRS's (e.g., if QRS's are upward, PVC's are mainly downward).
      </p>
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          PVC originates
        </TextMark>{" "}
        in an automaticity focus within the ventricular conduction system,
        usually in a ventricular wall. Thus, one area of the ventricular wall
        begins to depolarize before the rest of the ventricle, long before the
        other ventricle depolarizes.
      </p>
      <p>
        This is in contrast to Normal Sinus rhythm, where a sinus-generated
        stimulus passes through the AV Node and is quickly transmitted to the
        entire endocardial lining of both ventricles at once. This simultaneous
        depolarization of both ventricles produces a{" "}
        <TextMark
          activeMark={activeMark == 12 && true}
          onClick={() => clickTextMark(12)}>
          slender QRS complex
        </TextMark>{" "}
        (less than 120ms).
      </p>
      {/* <p>
        When an irritable ventricular automaticity focus suddenly fires an
        impulse, that ventricular region depolarizes before the rest of the
        ventricle, and then the depolarization wave creeps to the other
        ventricle, which then depolarizes, producing an enormously wide
        ventricular complex.
      </p>
      <p>
        Normally, ventricular depolarization passes through the entire thickness
        of both ventricles at once. Left ventricular depolarization in the
        leftward direction tends to be counterbalanced by the simultaneous right
        ventricular depolarization in the opposite direction. This minimizes the
        QRS amplitude. But depolarization originating in a remote ventricular
        focus (as with a PVC), gradually spreads without simultaneous opposition
        from the other side, and in its slow course, produces (unopposed)
        deflections of immense amplitude.
      </p> */}
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          PVC
        </TextMark>{" "}
        is an enormous ventricular complex that is much wider, taller, and
        deeper than a normal QRS complex. In contrast to premature atrial or
        junctional beats, which depolarize the SA node causing it to reset,
        these PVCs depolarize only the ventricles, so the SA Node discharges on
        schedule. In fact, by measuring P-P cycles, you can often locate the
        punctual{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          P wave
        </TextMark>{" "}
        within a PVC. These P waves appear "buried" within the PVC and occur
        while the ventricles are still refractory from the PVC. As a result,
        this P wave cannot depolarize the ventricles in this refractory period,
        so there is a{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          compensatory pause
        </TextMark>{" "}
        as the ventricles finish repolarizing, making them receptive to the next
        Sinus-generated cycle.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          Numerous PVCs
        </TextMark>{" "}
        may emanate from the same ventricular focus, which can indicate
        increased irritability at a specific ventricular focus, suggesting more
        significant pathology such as worsening oxygen delivery to the cardiac
        myocardium.
      </p>
      {/* <p>
        In this rhythm strip, you'll notice that these PVCs have a similar
        morphology suggesting they all originated in the same irritable
        ventricular focus.
      </p> */}
      <p>
        A very irritable ventricular automaticity focus may fire a stimulus that
        couples to one or more normal cycles to produce Ventricular Bigeminy, or
        Ventricular Trigeminy, etc.
      </p>
      <p>
        When a PVC becomes coupled to a normal cycle, and the pattern continues
        with every cycle in succession, we identify this as{" "}
        <TextMark
          activeMark={activeMark == 5 && true}
          onClick={() => clickTextMark(5)}>
          Ventricular bigeminy.
        </TextMark>
      </p>
      <p>
        If you see a repetitive pattern of a PVC coupling with every two normal
        cycles, this is a run of{" "}
        <TextMark
          activeMark={activeMark == 6 && true}
          onClick={() => clickTextMark(6)}>
          Ventricular trigeminy.
        </TextMark>
      </p>
      <p>
        Remember, ventricular automaticity foci are the heart's hypoxia early
        warning system; this should increase your clinical suspicion for some
        underlying cardiac pathology.
      </p>
      <strong style={{ paddingTop: 25, display: "block" }}>
        VENTRICULAR PARASYSTOLE
      </strong>
      <p>
        <TextMark
          activeMark={activeMark == 7 && true}
          onClick={() => clickTextMark(7)}>
          Ventricular Parasystole
        </TextMark>{" "}
        is produced by a ventricular automaticity focus that suffers from
        entrance block. The parasystolic focus is not vulnerable to overdrive
        suppression, so it paces at its inherent rate, and the ventricular
        complexes that it generates poke through the dominant Sinus Rhythm.
      </p>
      <p>
        A solitary ventricular focus suffering from{" "}
        <TextMark
          activeMark={activeMark == 8 && true}
          onClick={() => clickTextMark(8)}>
          entrance block
        </TextMark>{" "}
        is "parasystolic," that is, it cannot be overdrive-suppressed, yet it
        can deliver pacing stimuli at its inherent rate.
      </p>
      <p>
        A parasystolic ventricular focus suffers from entrance block, insulating
        it from depolarization by outside sources. As a result, these foci
        cannot be overdrive-suppressed and pace at their inherent rate. The
        result is a dual rhythm with pacing from two sources, the SA Node, and
        the ventricular focus.
      </p>
      <strong style={{ paddingTop: 25, display: "block" }}>
        VENTRICULAR TACHYCARDIA
      </strong>
      <p>
        A single irritable ventricular focus may fire one spontaneous impulse.
        Still, if its oxygen supply decreases further, the focus may be provoked
        into firing a series of discharges in rapid succession. A run of three
        or more PVC' s in rapid succession is really a run of{" "}
        <TextMark
          activeMark={activeMark == 9 && true}
          onClick={() => clickTextMark(9)}>
          Ventricular Tachycardia (VT).
        </TextMark>{" "}
        If a run of VT lasts longer than 30 seconds, it is called "sustained"
        VT.
      </p>

      <strong style={{ paddingTop: 25, display: "block" }}>
        MULTIFOCAL PVCs
      </strong>
      <p>
        PVCs originating in a specific ventricular focus all appear the same.
        However, severe cardiac hypoxia can cause{" "}
        <TextMark
          activeMark={activeMark == 10 && true}
          onClick={() => clickTextMark(10)}>
          Multifocal PVCs,
        </TextMark>{" "}
        where each focus produces its own unique, identifiable PVC every time it
        depolarizes.
      </p>
      <p>
        Remember that even a single irritable ventricular focus can suddenly
        fire a series of rapid impulses, which can lead to a dangerous
        ventricular tachycardia. With multifocal PVCs, each of these foci can
        potentially discharge rapidly, all at once, increasing the chance of
        triggering a fatal arrhythmia such as Ventricular Fibrillation.
      </p>
      <strong style={{ paddingTop: 25, display: "block" }}>
        R ON T PHENOMENON
      </strong>
      <p>
        If a PVC falls on a T wave ("R on T" phenomenon), dangerous arrhythmias
        may result.
      </p>
      <p>
        PVCs are, of course, premature and usually occur just{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          after the T wave
        </TextMark>{" "}
        of a normal cycle. When a PVC falls on the{" "}
        <TextMark
          activeMark={activeMark == 11 && true}
          onClick={() => clickTextMark(11)}>
          peak of a T wave,
        </TextMark>{" "}
        it catches the ventricles during a vulnerable period.
      </p>
      <p>
        Repolarization of Purkinje fibers of the ventricular conduction system
        extends beyond the T wave on an EKG, so a PVC falling just after the
        peak of the T wave or during the downslope of the T-wave may also catch
        the the ventricles during a vulnerable period leading to a dangerous
        type of dysrhythmia.
      </p>
    </ContentTemplate>
  );
}
