import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function NormalSinusRhythm(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/SA-Node_OdJkK0m.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        On an EKG, heart rate is read as cycles per minute. Similarly, on
        physical exam, we refer to this as beats per minute.
      </p>
      <p>
        The Sino-Atrial Node (SA node), the heart's pacemaker, paces the heart
        in the normal rate range of 60 to 100 beats per minute. The normal
        regular rhythm that the SA node generates is called normal sinus rhythm.
      </p>
      <p>
        The SA node is located within the upper-posterior wall of the right
        atrium and generates a continuous series of regular pacemaking stimuli
        or electrical activity known as depolarization. This continuous
        regularity gives the SA node its automaticity.
      </p>
    </ContentTemplate>
  );
}
