import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function VentricularRepolarization(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/ST-segment.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/T-wave.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/QT-interval.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/normal-QT_pLwCeu5.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Following the QRS complex, is a segment of horizontal baseline known as
        the{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          ST segment.
        </TextMark>{" "}
        The ST segment represents the initial phase of ventricular
        repolarization. Remember that repolarization occurs so that the
        ventricular myocytes can recover their interior, resting negative charge
        to be depolarized again during the next cardiac cycle.
      </p>
      <p>
        Repolarization of the ventricular myocytes begins immediately after the
        QRS and persists until the end of the{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          T wave,
        </TextMark>{" "}
        which is an upward wave following the ST segment.
      </p>
      <p>
        In relation to ventricular contraction, remember that it begins at the
        start of the QRS complex and persists until the end of the T wave. This
        means that ventricular contraction spans depolarization and
        repolarization of the ventricles. This can be visualized on the EKG as
        the QT interval.
      </p>
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          QT interval
        </TextMark>{" "}
        represents the duration of ventricular systole (contraction) and is
        measured from the beginning of the QRS complex until the end of the T
        wave. Although the QT interval represents both depolarization and
        repolarization, most of this time comprises ventricular repolarization
        (remember, ventricular depolarization is very fast). Because of this
        relationship, many clinicians use the QT interval as a measure of
        ventricular repolarization.
      </p>
      <p>
        With rapid heart rates, both depolarization and repolarization occur
        faster, so the QT interval varies with heart rate. Therefore, precise QT
        interval measurements are corrected for rate, which is called a QTc
        value. As a simple rule of thumb, the QT interval is considered normal
        when it is less than half of the{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          R-to-R interval
        </TextMark>{" "}
        at normal heart rates.
      </p>
      <p>
        As a simple rule of thumb, the QT interval is considered normal when it
        is less than half of the R-to-R interval at normal heart rates.
        Typically, a normal QTc is less than 450 ms in males, and in females, a
        normal QTc is less than 475ms. When the QTc gets longer, there is an
        increased risk of fatal arrhythmias, especially once the QTc lengthens
        to greater than 500 ms.
      </p>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
