import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function CoronaryArteries(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/coronary-arteries.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        Two coronary arteries provide the heart with a continuous supply of
        oxygenated blood - the Left and Right Coronary Arteries.
      </p>
      <p>The Right Coronary Artery curves around the right ventricle.</p>
      <p>
        The Left Coronary Artery has two major branches: the Circumflex branch
        and the Anterior Descending branch.
      </p>
      <p>
        When a coronary artery or one of its major branches is occluded, an area
        of the myocardium is without blood supply resulting in myocardial
        infarction. The infarcted area of myocardium becomes necrotic or dead,
        so it can't depolarize or contract. These infarctions can be seen on an
        EKG.
      </p>
    </ContentTemplate>
  );
}
