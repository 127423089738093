import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function Depolarization(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/cardiac-conduction-sequence.gif",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/cardiac-myocyte-resting-membrane-potential.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/cardiac-myocyte-depolarization_m7zR7ws.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/EKG-waves-bumps.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        The electricity that moves through the heart to cause myocardial
        contraction is called depolarization.
      </p>
      <p>
        In its resting state, myocytes (cardiac cells), relative to the outside
        of the cells, are{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          negatively charged.
        </TextMark>{" "}
        In other words, the cell has a negatively charged interior and a
        positively charged outside surface.
      </p>
      <p>
        When electricity moves through a cardiac myocyte during depolarization,{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          positive ions move
        </TextMark>{" "}
        into the cells making it more positive. This electrical charge moves or
        is propagated to adjacent cells until electricity has made its way
        throughout the entirety of the heart. This depolarization stimulates
        myocytes causing them to contract.
      </p>
      <p>
        After depolarization, myocytes undergo repolarization, which allows
        these cells to regain their negative resting charge. These alternating
        depolarization phases continue through each{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          cardiac cycle
        </TextMark>{" "}
        and can be visualized on an EKG.
      </p>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
