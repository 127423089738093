import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function LateralInfarction(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Lateral-Infarction-0.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Lateral-Infarction-1.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/Lateral-Infarction-2.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        A lateral infarction involves the left ventricle's lateral portion,
        typically represented on the EKG by leads{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          I and aVL.
        </TextMark>
      </p>
      <p>
        In lateral infarctions, the positive left arm{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          electrode
        </TextMark>{" "}
        senses only the initial "away" vectors from the opposite (septal) wall,
        so it records a Q wave in the lateral leads l and AVL - remember
        depolarization moving away from a positive electrode records a negative
        wave (in this case a Q wave) on EKG.
      </p>
      <p>
        When a lateral infarction occurs,{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Q waves
        </TextMark>{" "}
        appear in the lateral leads (I and aVL). Q waves are produced by the
        initial "away" vectors recorded by the positive left arm electrode
        through the void of the necrotic lateral infarct.
      </p>

      <strong
        style={{
          paddingTop: 25,
          display: "block",
          textTransform: "uppercase",
        }}>
        AFFECTED CORONARY ARTERY
      </strong>

      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          Circumflex
        </TextMark>{" "}
        branch of the Left Coronary Artery distributes blood to the lateral
        portion of the left ventricle. So a lateral infarction would be caused
        by an occlusion of the Circumflex branch of the Left Coronary Artery.
      </p>
    </ContentTemplate>
  );
}
