import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function Necrosis(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Necrosis1.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Necrosis2.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/Necrosis3.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/Necrosis4.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/Necrosis-12-lead.png",
    },
    {
      id: 5,
      img:
        "https://medzcool-images.s3.amazonaws.com/Septal-Depolarization-vector.png",
    },
    {
      id: 6,
      img:
        "https://medzcool-images.s3.amazonaws.com/Septal-Depolarization-purkinje.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        Necrosis of a ventricular region results in dead myocardial cells that
        cannot depolarize. On an EKG, necrosis is identified by the presence of
        significant{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Q waves.
        </TextMark>{" "}
      </p>
      <p>
        Remember that the Q wave is the first downward stroke of the QRS
        complex. On normal EKGs, without infarction, significant Q waves are
        absent.
      </p>
      <p>
        Typically, ventricular depolarization begins midway down the
        interventricular septum and has an overall{" "}
        <TextMark
          activeMark={activeMark == 5 && true}
          onClick={() => clickTextMark(5)}>
          left-to-right
        </TextMark>{" "}
        depolarization vector. That's because the Right Bundle Branch traverses
        the septum vertically without branching, while the Left Bundle Branch
        gives off{" "}
        <TextMark
          activeMark={activeMark == 6 && true}
          onClick={() => clickTextMark(6)}>
          terminal Purkinje filaments
        </TextMark>{" "}
        at mid-septum.
      </p>
      <p>
        So this initial mid-septal depolarization moves left-to-right,{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          away from:
        </TextMark>
      </p>
      <ul>
        <li>
          the positive left arm electrode of the lateral leads I and aVL, and
        </li>
        <li>
          the positive left foot electrode of the inferior leads II, III, and
          aVF, and
        </li>
        <li>
          the positive chest electrode of the left chest leads V5 and V6...
        </li>
      </ul>
      <p>
        ...resulting in the occasional, small and insignificant q waves in those
        respective leads, specifically those where the QRS is usually upright.
      </p>
      <p>
        This mid-septal depolarization is brief since the ventricular conduction
        system quickly transmits depolarization to the endocardial surface of
        both ventricles. Therefore, this septal depolarization is reflected on
        the EKG as a tiny q wave of less than 0.04 seconds in duration. These
        tiny q waves are what we'll call{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          insignificant q waves,
        </TextMark>{" "}
        which are, by definition, less than one millimeter (0.04 seconds) in
        duration.
      </p>
      <p>
        To delineate between insignificant q waves and significant Q waves,
        we'll use a capital "Q" to designate a significant Q wave and a small
        "q" when these waves are insignificant.
      </p>
      <p>
        Significant Q waves are indicative of the necrosis of a myocardial
        infarction. A significant Q wave is{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          one small square,
        </TextMark>{" "}
        or at least 0.04 seconds or more in duration. A significant Q wave can
        also be{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          one-third
        </TextMark>{" "}
        the amplitude (height and depth) of the entire QRS complex.
      </p>
    </ContentTemplate>
  );
}
