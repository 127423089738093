// Dependencies
import { Route, Switch } from "react-router-dom";

// Components
import WhatIsHypertrophy from "./Chapters/1-WhatIsHypertrophy";
import AtrialHypertrophy from "./Chapters/2-AtrialHypertrophy";
import RightAtrialEnlargement from "./Chapters/3-RightAtrialEnlargement";
import LeftAtrialEnlargement from "./Chapters/4-LeftAtrialEnlargement";
import VentricularHypertrophy from "./Chapters/5-VentricularHypertrophy";
import RightVentricularHypertrophy from "./Chapters/6-RightVentricularHypertrophy";
import LeftVentricularHypertrophy from "./Chapters/7-LeftVentricularHypertrophy";
import StrainPatterns from "./Chapters/8-StrainPatterns";
import Summary from "./Chapters/9-Summary";

// Styles
export default function HypertrophyModule(props) {
  return (
    <Switch>
      <Route
        path="/module/hypertrophy/atrial-hypertrophy/what-is-hypertrophy"
        render={(props) => <WhatIsHypertrophy {...props} />}
      />
      <Route
        path="/module/hypertrophy/atrial-hypertrophy/atrial-hypertrophy"
        render={(props) => <AtrialHypertrophy {...props} />}
      />
      <Route
        path="/module/hypertrophy/atrial-hypertrophy/right-atrial-enlargement"
        render={(props) => <RightAtrialEnlargement {...props} />}
      />
      <Route
        path="/module/hypertrophy/atrial-hypertrophy/left-atrial-enlargement"
        render={(props) => <LeftAtrialEnlargement {...props} />}
      />
      <Route
        path="/module/hypertrophy/ventricular-hypertrophy/ventricular-hypertrophy"
        render={(props) => <VentricularHypertrophy {...props} />}
      />
      <Route
        path="/module/hypertrophy/ventricular-hypertrophy/right-ventricular-hypertrophy"
        render={(props) => <RightVentricularHypertrophy {...props} />}
      />
      <Route
        path="/module/hypertrophy/ventricular-hypertrophy/left-ventricular-hypertrophy"
        render={(props) => <LeftVentricularHypertrophy {...props} />}
      />
      <Route
        path="/module/hypertrophy/ventricular-hypertrophy/strain-patterns"
        render={(props) => <StrainPatterns {...props} />}
      />
      <Route
        path="/module/hypertrophy/ventricular-hypertrophy/summary"
        render={(props) => <Summary {...props} />}
      />
    </Switch>
  );
}
