import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function AtrialFibrillation(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Atrial-Fibrillation-rhythm-strip.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Atrial-Fibrillation-multi-foci.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Atrial-Fibrillation-erratic-baseline.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/Atrial-Fibrillation-QRS.png",
    },
    {
      id: 4,
      img:
        "https://medzcool-images.s3.amazonaws.com/Atrial-Fibrillation-irregular.png",
    },
    // {
    //   id: 0,
    //   img: "https://medzcool-images.s3.amazonaws.com/Atrial-Fibrillation.png",
    // },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Atrial Fibrillation is caused by the continuous rapid-firing of multiple
        atrial{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          automaticity foci.
        </TextMark>{" "}
        No single impulse depolarizes the atria completely, and only an
        occasional random atrial depolarization reaches the AV Node to be
        conducted to the ventricles. This results from multiple "irritable"
        atrial foci suffering from entrance blocks. In other words, these atrial
        foci are parasystolic, so they're all insensitive to overdrive
        suppression and attempt to pace all at once.
      </p>
      <p>
        This erratic depolarization of various atrial ectopic foci is
        represented by this{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          erratic baseline
        </TextMark>{" "}
        on an EKG. Because of this chaotic atrial activity, no single impulse
        completely depolarizes both atria, so there are no discrete P waves.
      </p>
      <p>
        Many of these atrial impulses do not conduct through the AV node, but on
        occasion, one will pass through the AV node and down to the ventricles,
        represented as a{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          QRS complex.
        </TextMark>
      </p>
      <p>
        It is this variability in atrial conduction through the AV node and down
        to the ventricles which leads to characteristic{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          irregular rhythm
        </TextMark>{" "}
        seen in atrial fibrillation. And depending on how often an atrial
        impulse propagates down to the ventricle, this can result in a slow or
        rapid ventricular rate, but it is always irregular.
      </p>
    </ContentTemplate>
  );
}
