import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function AVNodeConduction(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/pr-interval.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/AV-NODE.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        When depolarization reaches the AV node from the atria, electrical
        conduction slows before propagating down to the ventricles.
      </p>
      <p>
        This slowing of electrical conduction is represented on the EKG as the{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          PR interval,
        </TextMark>{" "}
        which is measured from the beginning of the P wave to the start of the
        QRS complex. It reflects the conduction through the{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          AV node.
        </TextMark>
      </p>
      <p>This is also the time when the atrial myocytes begin to repolarize.</p>
      <p>
        This pause in electrical activity allows enough time for blood to empty
        from the atria into the ventricles, just before ventricular
        depolarization and contraction.
      </p>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
