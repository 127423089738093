import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function IrregularRhythms(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/irregular-rhythm-intro.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Irregular rhythms lack a constant duration between paced cycles. This
        can occur because of many reasons and is suggestive of underlying
        pathology. For example, in some hearts with structural pathology or
        hypoxia, malfunctioning automaticity foci may suffer from entrance
        block, which occurs when incoming depolarization is blocked from passive
        depolarization by any other source. By being insensitive to passive
        depolarization, these affected cells cannot be overdrive-suppressed,
        while their own automaticity is still conducted to surrounding tissue.
      </p>
      <p>
        When an automaticity focus has entrance block, it is said to be
        parasystolic, where the focus paces, but can't be overdrive-suppressed.
      </p>
    </ContentTemplate>
  );
}
