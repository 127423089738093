import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function RightVentricularHypertrophy(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Ventricular-Hypertrophy-right.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Ventricular-Hypertrophy-right-trend.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        In Right Ventricular Hypertrophy, there is a{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          large R wave
        </TextMark>{" "}
        in lead V1. In these cases, the right ventricle wall is very thick, so
        there is much more (positive) depolarization (and more vectors) toward
        the positive V1 electrode. Therefore we would expect the QRS complex in
        V1 to be more positive than usual. Intuitively, the S wave will be
        smaller than the R wave.
      </p>
      <p>
        With Right Ventricular Hypertrophy, the large R wave of V1 gets
        progressively smaller from V2 to V3 to V4, etc. The{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          progressive decrease
        </TextMark>{" "}
        in the height of the R wave is gradual, proceeding from the right chest
        leads to the left chest leads.
      </p>
      <p>
        The enlarged right ventricle adds more vectors toward the right side, so
        there is Right Axis Deviation (in the frontal plane), and in the
        horizontal plane there is rightward rotation of the (Mean QRS) Vector.
      </p>
    </ContentTemplate>
  );
}
