import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function EscapeRhythms(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Escape-rhythm-intro_yt3bfjr.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        "Escape" describes the response of an automaticity focus to a pause in
        the pacemaking activity.
      </p>
      <p>
        The SA Node's regular pacing overdrive-suppresses all automaticity foci,
        but a brief pause in SA Node pacing permits an automaticity focus to
        escape overdrive suppression.
      </p>
      <p>
        If SA Node pacing ceases entirely, an automaticity focus will escape
        pacing at its inherent rate, producing an Escape Rhythm.
      </p>
      <p>
        If the pause in pacing is brief, an automaticity focus may escape
        emitting a single Escape Beat before the normal Sinus rhythm returns.
      </p>
    </ContentTemplate>
  );
}
