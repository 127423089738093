import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function LeftVentricularHypertrophy(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Left-Ventricular-Hypertrophy.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Left-Ventricular-Hypertrophy-sokolov-lyon.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        The left ventricular myocardium usually is thicker than the right. So
        hypertrophy of the left ventricle will produce an exaggerated amplitude
        of the QRS complexes, both in height and in depth. This is most
        prominently seen in the chest leads.
      </p>
      <p>
        With{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Left Ventricular Hypertrophy,
        </TextMark>{" "}
        there is a very tall R wave in lead V5. Lead V5 is over the left
        ventricle, so the increased depolarization is going toward the electrode
        of V5 when there is LVH. This results in more (positive) depolarization
        going toward the (positive) electrode of V5, which produces a very tall
        R wave in that lead.
      </p>
      <p>
        Conversely, this excessive depolarization moving away from the V1
        electrode produces a deep S wave in lead V1. Usually, the S wave in V1
        is deep. But with Left Ventricular Hypertrophy (LVH), even more
        depolarization is going down to the patient's left - away from the
        positive V1 electrode. Therefore, the S wave is even deeper in V1.
        Additionally, with LVH, there is Left Axis Deviation, and often the
        Vector is displaced in a leftward direction in the horizontal plane.
      </p>
      <p>
        We can calculate a number to determine LVH based on these EKG findings.
        There are numerous calculations, but a common calculation is the{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          Sokolov-Lyon
        </TextMark>{" "}
        criteria which states that if the sum of the depth (in millimeters) of
        the S wave in V1 and R in V5 is greater than 35 mm, then LVH is present.
      </p>
    </ContentTemplate>
  );
}
