import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function BipolarLeads(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Limb-Leads-anatomy.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Limb-Leads-triangle.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/lead-I.png",
    },
    {
      id: 3,
      img: "https://medzcool-images.s3.amazonaws.com/lead-II.png",
    },
    {
      id: 4,
      img: "https://medzcool-images.s3.amazonaws.com/lead-III.png",
    },
    {
      id: 5,
      img: "https://medzcool-images.s3.amazonaws.com/Limb-Leads-intersect.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        Bipolar leads are also known as{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          limb leads.
        </TextMark>
      </p>
      <p>
        The limb leads we classically know as I, II, and III, use two electrodes
        to record a single lead, where a different pair is used for each lead.
      </p>
      <p>
        These are called bipolar limb leads because they use a pair of
        electrodes to record a single lead, one is a positive electrode, and one
        is negative.
      </p>
      <p>
        By selecting a different pair of electrodes for each lead, we create
        three separate bipolar limb leads{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          (lead I, lead II, and lead Ill).
        </TextMark>
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          Lead I
        </TextMark>{" "}
        is horizontal, and its left arm electrode is positive while its right
        arm electrode is negative.
      </p>
      <p>
        In{" "}
        <TextMark
          activeMark={activeMark == 3 && true}
          onClick={() => clickTextMark(3)}>
          Lead II,
        </TextMark>{" "}
        the right arm electrode is negative, and the left leg electrode is
        positive.
      </p>
      <p>
        In{" "}
        <TextMark
          activeMark={activeMark == 4 && true}
          onClick={() => clickTextMark(4)}>
          Lead III,
        </TextMark>{" "}
        the left arm electrode is negative, and the left leg electrode is
        positive.
      </p>
      <p>
        You can see how these leads form a triage, often referred to as
        Einthoven's triangle.
      </p>
      <p>
        By pushing the three (bipolar) limb leads to the center of the triangle,
        we produce three{" "}
        <TextMark
          activeMark={activeMark == 5 && true}
          onClick={() => clickTextMark(5)}>
          intersecting lines
        </TextMark>{" "}
        of reference. These lines of reference will be important shortly.
      </p>
    </ContentTemplate>
  );
}
