import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import ClickReveal from "../../../Styles/ClickReveal";
import styled from "styled-components";

export default function PracticeRate(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/rate-practice-tracing-2.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>Now, let' s determine the approximate rates of these EKG tracings.</p>
      <div style={{ marginBottom: 15 }}>
        <span>A: </span>
        <ClickReveal>
          <span>100</span>
        </ClickReveal>
      </div>
      <div style={{ marginBottom: 15 }}>
        <span>B: </span>
        <ClickReveal>
          <span>150 or so</span>
        </ClickReveal>
      </div>
      <div style={{ marginBottom: 15 }}>
        <span>C: </span>
        <ClickReveal>
          <span>60</span>
        </ClickReveal>
      </div>
      <div style={{ marginBottom: 15 }}>
        <span>D: </span>
        <ClickReveal>
          <span>75</span>
        </ClickReveal>
      </div>
    </ContentTemplate>
  );
}

const RateBlock = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  ul {
    margin-top: 10px;
  }
`;
