import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function AtrialEscapeRhythm(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Atrial-Escape.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        With a Sinus Arrest, an automaticity focus in the highest level of foci,
        the atria, escapes overdrive suppression to become an active pacemaker
        in its inherent rate range of 60 to 80 per minute.
      </p>
      <p>
        An Atrial Escape Rhythm originates in an atrial automaticity focus, so
        the P' waves are not identical to the previous P waves that were
        produced by the SA node.
      </p>
      <p>
        The active atrial automaticity focus overdrive-suppresses all lower and
        slower foci to become the dominant pacemaker. It also paces at its
        inherent rate, which differs from (i.e., is slower than) the previous
        Sinus rate.
      </p>
      <p>
        When an atrial focus assumes pacing responsibility in the absence of a
        Sinus Rhythm, this is an Atrial escape rhythm.
      </p>
    </ContentTemplate>
  );
}
