import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function JunctionalAutomaticityFoci(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/overdrive-suppression-junctional.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/proximal-av-node.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        An automaticity focus in the Atrio-Ventricular (AV) Junction emerges
        when no pacing stimuli are coming down from the atria.
      </p>
      <p>
        When the AV junction is no longer overdrive-suppressed, it emerges to
        actively pace in its inherent rate range of 40 to 60 per minute, and it
        overdrive-suppresses all lower automaticity foci becoming the dominant
        pacemaker.
      </p>
      <p>
        The{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          proximal end
        </TextMark>{" "}
        of the AV node has no foci. Instead, the AV Junction is the portion of
        the AV Node that has sites of automaticity. These are called "Junctional
        foci."
      </p>
      <p>
        When a Junctional focus actively paces at its inherent rate, it produces
        what is called an idiojunctional rhythm (the prefix "idio" is of Greek
        origin, and it means "one's own").
      </p>
      <p>
        These junctional rhythms can occur with a complete conduction block in
        the AV Node above the AV Junction. Remember that the AV Node is the only
        conduction link between the atria and the ventricular conduction system
        below. With a complete conduction block in the AV Node, an automaticity
        focus in the AV Junction just below receives no pacing stimuli from
        above so, no longer overdrive-suppressed, the Junctional focus escapes
        to become the active pacemaker for the ventricles. And that Junctional
        focus paces the ventricles at its inherent rate of 40 - 60 per minute
        while overdrive-suppressing lower, (slower) ventricular foci.
      </p>
      <p>
        It is possible for the AV Junction (together with all its automaticity
        foci) to suffer a complete block. In that case, only an automaticity
        focus in the Purkinje fibers of the ventricles can resume pacemaking
        activity for the heart.
      </p>
    </ContentTemplate>
  );
}
