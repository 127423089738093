import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import ClickReveal from "../../../Styles/ClickReveal";

export default function Practice1(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/practice-1.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>What is the likely rhythm?</p>
      <ClickReveal>
        <p>
          This practice tracing has an irregular rhythm in which we see
          discernible P' waves, so we know that it is not Atrial Fibrillation.
        </p>
        <p>
          The "P" waves are not identical, and the rate does not gradually
          increase and gradually decrease, so we immediately know that this is
          not sinus.
        </p>
        <p>
          The rate is less than 100 (which rules out MAT), the rhythm is
          irregular, and the P' waves are of different shapes. This is most
          likely a wandering atrial pacemaker.
        </p>
      </ClickReveal>
    </ContentTemplate>
  );
}
