import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function LocalizationSummary(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Localizing-Infarctions-Summary-0.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Localizing-Infarctions-Summary-anterior.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Localizing-Infarctions-Summary-lateral.png",
    },
    {
      id: 3,
      img:
        "https://medzcool-images.s3.amazonaws.com/Localizing-Infarctions-Summary-inferior.png",
    },
    {
      id: 4,
      img:
        "https://medzcool-images.s3.amazonaws.com/Localizing-Infarctions-Summary-posterior.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        There are{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          four
        </TextMark>{" "}
        general locations where infarctions commonly occur, each with its
        classic morphological features on an EKG:
      </p>
      <ul>
        <li>
          <TextMark
            activeMark={activeMark == 1 && true}
            onClick={() => clickTextMark(1)}>
            Anterior
          </TextMark>
        </li>
        <li>
          <TextMark
            activeMark={activeMark == 2 && true}
            onClick={() => clickTextMark(2)}>
            Lateral
          </TextMark>
        </li>
        <li>
          <TextMark
            activeMark={activeMark == 3 && true}
            onClick={() => clickTextMark(3)}>
            Inferior
          </TextMark>
        </li>
        <li>
          <TextMark
            activeMark={activeMark == 4 && true}
            onClick={() => clickTextMark(4)}>
            Posterior
          </TextMark>
        </li>
      </ul>
      <p>
        Remember that the presence of ST elevations (or depressions for
        posterior infarcts) suggest acute infarction, which can be seen
        alongside Q-waves. This is in contrast to when you see Q-waves alone,
        which implies an older infarct.
      </p>
      {/* <p>
        [show picture comparing STEMI versus old, Q-wave only infarct on EKG]
      </p> */}
    </ContentTemplate>
  );
}
