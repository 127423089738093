import React, { useState } from "react";
// DEPENDENCIES
// COMPONENTS
// STYLES
import styled from "styled-components";
import { grayscale } from "@bit/medzcool.cytoplasm.colors";

export default function ClickReveal(props) {
  const [isVisible, setIsVisible] = useState(false);

  function handleClick() {
    setIsVisible(true);
  }

  if (!isVisible) {
    return (
      <ClickRevealContainer onClick={handleClick}>
        Click for answer
      </ClickRevealContainer>
    );
  }

  return <span>{props.children}</span>;
}

export const ClickRevealContainer = styled.span`
  cursor: pointer;
  background-color: ${grayscale.gray};
  padding: 0 3px;
  border-radius: 3px;
  color: ${grayscale.darkDark};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px;
  white-space: nowrap;
  box-shadow: "rgba(0, 0, 0, 0.1) 0px 3px 4px";
  background-color: ${grayscale.gray};
  &:hover {
    background-color: ${grayscale.lightDark};
    box-shadow: "rgba(0, 0, 0, 0.1) 0px 1px 1px";
  }
`;
