import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function Injury(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/ST-segment.png",
    },
    {
      id: 1,
      img: "https://medzcool-images.s3.amazonaws.com/Injury-STE.png",
    },
    {
      id: 2,
      img: "https://medzcool-images.s3.amazonaws.com/Injury-ST-segment.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        Remember that the{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          ST segment
        </TextMark>{" "}
        is that section of baseline between the QRS complex and the T wave.
      </p>
      <p>
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          ST segment elevation
        </TextMark>{" "}
        tells us that a myocardial infarction is acute or recent. It is the
        earliest consistent sign of infarction to record on an EKG. With time,
        the ST segment returns to the baseline.
      </p>
      <p>
        These ST segment elevations are important to identify because once you
        have diagnosed infarction, it is crucial to know whether the infarction
        is old or if the infarction just occurred and needs immediate treatment.
      </p>
      <p>
        Classically, myocardial infarctions are transmural, meaning that the
        full thickness of the ventricular wall is damaged in the infarcted area.
      </p>
      <p>
        A subendocardial infarction is an infarct that does not extend through
        the full thickness of the ventricular wall. Typically, this involves
        only a small area of the myocardium just beneath the endocardial lining.
        On the EKG, this can lead to{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          depression
        </TextMark>{" "}
        of the ST segment, which may be only temporary before returning to
        baseline. For example, When a patient with narrowed coronaries
        exercises, the myocardium demands more blood flow than its arteries can
        deliver. In this case, during a stress ( or "exercise") test, the EKG
        will record depression of the ST segment suggesting subendocardial
        injury. Subendocardial infarction can be identified by flat ST segment
        depressions, either horizontal or down-sloping.
      </p>
      <p>
        Even though subendocardial infarction involves only a small area of the
        myocardium, it should still be considered a true myocardial infarction.
        That's because a subendocardial myocardial infarction may enlarge or
        extend and become more life-threatening.
      </p>
    </ContentTemplate>
  );
}
