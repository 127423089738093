import { useEffect, useContext } from "react";
// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";
import styled from "styled-components";

export default function UpDown(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;
  const loadMarkData = [
    {
      id: 0,
      img:
        "https://medzcool-images.s3.amazonaws.com/Determining-Axis-up-down.png",
    },
    {
      id: 1,
      img:
        "https://medzcool-images.s3.amazonaws.com/Determining-Axis-up-down-positive.png",
    },
    {
      id: 2,
      img:
        "https://medzcool-images.s3.amazonaws.com/Determining-Axis-up-down-negative.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);
  return (
    <ContentTemplate>
      <p>
        Let's consider a blank sphere again. But this time, let us orient our
        positive-negative regions using a different lead.
      </p>
      <p>
        Remember that{" "}
        <TextMark
          activeMark={activeMark == 0 && true}
          onClick={() => clickTextMark(0)}>
          Lead aVF
        </TextMark>{" "}
        measures depolarization from the left foot, where the electrodes on the
        left foot are positive. So, translating this onto our sphere, the bottom
        half of our sphere would be positive and the top half negative in
        relation to aVF.
      </p>
      <p>
        And so, if we looked at an EKG that had an{" "}
        <TextMark
          activeMark={activeMark == 1 && true}
          onClick={() => clickTextMark(1)}>
          upward QRS
        </TextMark>{" "}
        deflection in aVF, this suggests a positive wave of depolarization
        moving towards aVF. This translates onto our sphere as a Mean QRS Vector
        pointing downward.
      </p>
      <p>
        Alternatively, if the QRS in aVF is mostly a{" "}
        <TextMark
          activeMark={activeMark == 2 && true}
          onClick={() => clickTextMark(2)}>
          downward
        </TextMark>{" "}
        deflection, this suggests the wave of depolarization is moving away from
        aVF. On our sphere, negative represents the top half, which indicates
        that the Mean QRS Vector points upward into the negative half of the
        sphere.
      </p>
      <p>
        Using the Vector from aVF and Lead I together can help you determine the
        heart's axis - let's learn more about how we can do this in the next
        module.
      </p>
    </ContentTemplate>
  );
}
