import { useEffect, useContext } from "react";

// Dependencies
// Components
import ContentTemplate from "../../ContentTemplate";
// Context
import { StageContext } from "../../../Module/Stage/ModuleStage";
// Styles
import { TextMark } from "../../../Styles/index";

export default function Fibrillation(props) {
  const contextValue = useContext(StageContext);
  const {
    setActiveMark,
    setActiveMarkData,
    activeMark,
    clickTextMark,
  } = contextValue;

  const loadMarkData = [
    {
      id: 0,
      img: "https://medzcool-images.s3.amazonaws.com/Fibrillation.png",
    },
  ];
  useEffect(() => {
    setActiveMark(0);
    setActiveMarkData(loadMarkData);
  }, []);

  return (
    <ContentTemplate>
      <p>
        "Fibrillation" is an erratic rhythm caused by continuous, rapid rate
        discharges from numerous automaticity foci. These foci can be either
        atrial or ventricular in their location.
      </p>
      <p>
        When the rapid and numerous discharges from automaticity foci are
        located in the atria, we call this atrial fibrillation. Similarly, when
        they are located in the ventricles, we call this ventricular
        fibrillation. Both of these arrhythmias are pathological; they are not
        normal and result from some underlying disease.
      </p>
      <p>
        These irritable foci all suffer from entrance block, so they are
        parasystolic. Since they cannot be overdrive suppressed, they all pace
        rapidly at once. The resulting rhythm is so erratic and uncoordinated
        that distinct, complete waves are not distinguishable and the involved
        cardiac chambers merely twitch rapidly.
      </p>
      <p>
        Because there is no coordinated myocardial contraction, and the cardiac
        chambers do not effectively pump blood at all, the fibrillation rate of
        350 - 450 per minute is almost an arbitrary number used for convenience
        instead of a literal heart rate.
      </p>
      <p>
        It might be more helpful to think of this number as 350 - 450 electrical
        impulses (as opposed to beats) per minute occurring at many irritable
        foci.
      </p>
    </ContentTemplate>
  );
}
